import React from 'react'
import TransactionDetailsTextTable from '../../../TransactionDetailsTextTable'
import UserAvatar from '../../../../ui/UserAvatar'
import { Button } from '../../../../ui/Button'
import SuccessGif from '../../../../../assets/images/success.gif'
import PendingGif from '../../../../../assets/images/pending.gif'
import CancelGif from '../../../../../assets/images/Cancel.gif'
import { useNavigate } from 'react-router'
import { withTranslation } from 'react-i18next';

const SendModalSuccessStep = ({ setStep, setSendModalOpen, transactionDetail, paymentStatus,t }) => {
    const navigate = useNavigate();

    const handleClose = () => {
        navigate('/dashboard')
        setSendModalOpen(false)
        setTimeout(() => {
            setStep(1)
        }, 500);
    }
    return (
        <div className='success-content'>
            <div className='icon'>
                <img src={paymentStatus === 1 ? SuccessGif : paymentStatus === 2 ? CancelGif : PendingGif} alt="" className='max-w-[200px] mx-auto' />
            </div>
            {
                paymentStatus === 1 ?
                    <>
                        <h5 className='mb-4 after:text-20 font-600 text-dark-950 dark:text-white text-center '>
                        {t('label.labelSendSuccessful')}
                        </h5>
                        <p className='text-center text-14 font-400 text-dark-500 dark:text-dark-300 mb-7'>{t('success.successYourMoneyHasBeenSendSuccessfully')}</p>
                    </>
                    : paymentStatus === 2 ?
                        <>
                            <h5 className='mb-4 after:text-20 font-600 text-dark-950 dark:text-white text-center '>
                            {t('label.labelSendFailed')}
                            </h5>
                            <p className='text-center text-14 font-400 text-dark-500 dark:text-dark-300 mb-7'>{t('error.errorYourMoneyHasBeenSendFailed')}</p>
                        </> :
                        <>
                            <h5 className='mb-4 after:text-20 font-600 text-dark-950 dark:text-white text-center '>
                                {t('label.labelPending')}
                            </h5>
                            <p className='text-center text-14 font-400 text-dark-500 dark:text-dark-300 mb-7'>{t('error.errorPleaseWaitYourAmountWillSendAfterAdminApproval')}</p>
                        </>
            }
            <div className='py-2 px-4 border border-dark-300 dark:border-dark-500 rounded-sm'>
                <UserAvatar text={transactionDetail?.receiver_user?.name} img={transactionDetail?.receiver_user?.profile} />
            </div>
            <TransactionDetailsTextTable paymentStatus={paymentStatus} transactionDetail={transactionDetail} />
            <Button className="w-full" onClick={handleClose}>
            {t('done')}
            </Button>
        </div>
    )
}

export default withTranslation()(SendModalSuccessStep)
