import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import IconClose from '../../../svg/IconClose'
import { Button } from '../../../ui/Button'
import Logo from '../../../../assets/images/Logo.svg'
import LogoLight from '../../../../assets/images/Logo-light.svg'
import LogoFav from '../../../../assets/images/logo-fav.svg'
import moment from 'moment'
import { AUTH_API_URL, TRANSACTION_URL } from '../../../../api/Path'
import { Toaster } from './Toaster'
import axios from 'axios'
import useStore from '../../../../contexts/AuthProvider'
import DataLoader from '../../../common/DataLoader'
// import { transactiondata } from '../../../../data/staticData'
import { withTranslation } from 'react-i18next';


const TransactionsHistoryModal = ({
  setTransactionHistoryModalOpen,
  transactionHistoryModalOpen,
  Transactiondata,
  t
}) => {
  const [pageloading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const state = useStore((state) => state?.user_data);
  const handleClose = () => {
    setTransactionHistoryModalOpen(false);
  };

  const pdfDownload = async () => {
    // var Transaction = Transactiondata;
    let data = Transactiondata;

    // Transaction.sender_user = Transactiondata?.senderuser ? Transactiondata?.senderuser : Transactiondata?.from
    // Transaction.receiver_user = Transactiondata?.receiveruser ? Transactiondata?.receiveruser : Transactiondata?.to
    // Transaction.card_name = Transactiondata?.userCard
    // Transaction.final_date = moment(Transactiondata?.createdAt).format("DD MMM YYYY, hh:mm A")
    // Transaction.fees = Transactiondata?.admin_fees
    // Transaction.note = Transactiondata?.note ? Transactiondata?.note : '-'
    // Transaction.final_amount = Transactiondata.type == 3 ? Transactiondata?.total_send_amount : Transactiondata?.send_amount ? Transactiondata?.send_amount : Transactiondata?.amount
    // Transaction.bank_name = Transactiondata?.bank_name ? Transactiondata?.bank_name : Transactiondata?.client_bank_name ?? '-'
    // Transaction.status_name = Transactiondata?.status === 0 ? "Pending" : Transactiondata?.status === 1 ? "Success" :
    //     Transaction?.status === 2 ? "Fail" : Transactiondata?.status === 3 ? "Admin For Review" : Transactiondata?.status === 4 ?
    //         "Admin Reject" : Transactiondata?.status === 5 ? "Rejected" : Transactiondata?.status === 6 ? "Cancelled" : "Payment Pending"

    data.type = Transactiondata?.type;
    data.transaction_id = Transactiondata?.transaction_id;
    data.req_status = Transactiondata?.req_status
    data.receiveruser = Transactiondata?.receiveruser
    data.from_user_id = Transactiondata?.from_user_id ? Transactiondata?.from_user_id : Transactiondata?.sender
    data.from = Transactiondata?.from
    data.to = (Transactiondata?.req_status == 0 || Transactiondata?.req_status == 1 || Transactiondata?.req_status == 2) ? Transactiondata?.from : Transactiondata?.receiveruser
    data.symbol = Transactiondata?.symbol
    data.amount = Transactiondata?.send_amount ? Transactiondata?.send_amount : Transactiondata?.amount
    data.admin_fees = Transactiondata?.admin_fees
    data.total_send_amount = Transactiondata?.total_send_amount
    data.bank_name = Transactiondata?.bank_name
    data.client_bank_name = Transactiondata?.client_bank_name
    data.receive_amount = Transactiondata?.receive_amount
    data.user_bank = Transactiondata?.user_bank
    data.final_date = moment(Transactiondata?.createdAt).format("DD MMM YYYY, hh:mm A")
    data.flag = 'web'
    data.note = Transactiondata?.note
    data.status_name = Transactiondata?.status === 0 ? t('pending') : Transactiondata?.status === 1 ? t('label.labelSuccess') : Transactiondata?.status === 2 ? t('label.labelFailed') : Transactiondata?.status === 3 ? t('label.labelAdminForReview') : Transactiondata?.status === 4 ? "Admin Reject" : Transactiondata?.status === 5 ? t('label.labelReject') : Transactiondata?.status === 6 ? t('label.labelCancelled') : t('label.labelPaymentPending')
    try {
      setLoading(true);
      setPageLoading(true);
      const response = await axios.post(`${AUTH_API_URL.pdfdownload}`, data, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          'env': 'test'
        },
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'report.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setLoading(false);
      Toaster(t('success.labelInvoiceDownloadSuccessfully'), "success");
      setPageLoading(false)
      setTransactionHistoryModalOpen(false)
    } catch (error) {
      console.error("Error fetching PDF:", error);
      setLoading(false);
      setPageLoading(false)
      Toaster(error.message, "error");
    }
  };

  const sendEmail = async () => {
    // const amount =
    //     Transactiondata.sender_user = Transactiondata?.senderuser ? Transactiondata?.senderuser : Transactiondata?.from
    // Transactiondata.receiver_user = Transactiondata?.receiveruser ? Transactiondata?.receiveruser : Transactiondata?.to
    // Transactiondata.card_name = Transactiondata?.userCard
    // Transactiondata.fees = Transactiondata?.admin_fees
    // Transactiondata.final_amount = Transactiondata.type == 3 ? Transactiondata?.total_send_amount : Transactiondata?.send_amount ? Transactiondata?.send_amount : Transactiondata?.amount
    // Transactiondata.bank_name = Transactiondata?.userBank?.bank_name ? Transactiondata?.userBank?.bank_name : Transactiondata?.user_bank
    // Transactiondata.status_name = Transactiondata?.status == 0 ? "Pending" : Transactiondata?.status == 1 ? "Success" :
    //     Transactiondata?.status == 2 ? "Fail" : Transactiondata?.status == 3 ? "Admin Pending" : Transactiondata?.status == 4 ?
    //         "Admin Reject" : ''
    let data = Transactiondata;

    data.type = Transactiondata?.type;
    data.transaction_id = Transactiondata?.transaction_id;
    data.req_status = Transactiondata?.req_status
    data.receiveruser = Transactiondata?.receiveruser
    data.from_user_id = Transactiondata?.from_user_id ? Transactiondata?.from_user_id : Transactiondata?.sender
    data.from = Transactiondata?.from
    data.to = (Transactiondata?.req_status == 0 || Transactiondata?.req_status == 1 || Transactiondata?.req_status == 2) ? Transactiondata?.from : Transactiondata?.receiveruser
    data.symbol = Transactiondata?.symbol
    data.amount = Transactiondata?.send_amount ? Transactiondata?.send_amount : Transactiondata?.amount
    data.admin_fees = Transactiondata?.admin_fees
    data.total_send_amount = Transactiondata?.total_send_amount
    data.bank_name = Transactiondata.type == 3 ? Transactiondata?.bankData?.bank_name : Transactiondata?.bank_name
    data.client_bank_name = Transactiondata?.client_bank_name
    data.receive_amount = Transactiondata?.receive_amount
    data.user_bank = Transactiondata?.user_bank
    data.final_date = moment(Transactiondata?.createdAt).format("DD MMM YYYY, hh:mm A")
    data.flag = 'web'
    data.note = Transactiondata?.note
    data.status_name = Transactiondata?.status === 0 ? t('pending') : Transactiondata?.status === 1 ? t('label.labelSuccess') : Transactiondata?.status === 2 ? t('label.labelFailed') : Transactiondata?.status === 3 ? t('label.labelAdminForReview') : Transactiondata?.status === 4 ? "Admin Reject" : Transactiondata?.status === 5 ? t('label.labelReject') : Transactiondata?.status === 6 ? t('label.labelCancelled') : t('label.labelPaymentPending')
    data.user_id = state?._id
    try {
      setLoading(true);
      setPageLoading(true)
      const response = await axios.post(`${TRANSACTION_URL.sendEmail}`, data,
        {
          headers: {
            "Content-Type": "application/json",
            env: "test",
          },
        }
      );
      if (response.status == 200) {
        setLoading(false);
        setPageLoading(false)
        Toaster(t('success.labelInvoiceSendEmailSuccessfully'), "success");
      } else {
        console.log({ response });
      }
    } catch (error) {
      setLoading(false);
      setPageLoading(false)
      console.error("Error fetching PDF:", error);
      Toaster(error.message, "error");
    }
  };

  return (
    <>
      <Transition appear show={transactionHistoryModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setTransactionHistoryModalOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform rounded-sm sm:rounded-md bg-white dark:bg-dark-800  shadow-xl transition-all overflow-hidden">
                  <div className="dialog-content">
                    <div className="dialog-header relative py-5 px-5 md:px-[30px] md:py-6 text-center border-b border-dark-300 dark:border-dark-600">
                      <h5 className=" text-16 sm:text-18 dark:text-white">
                        {t('transactionDetails')}
                      </h5>
                      <span
                        className="close absolute top-1/2 transform -translate-y-1/2 right-[30px] cursor-pointer"
                        onClick={handleClose}
                      >
                        <IconClose className="w-5 sm:w-6 h-5 sm:h-6 dark:text-white text-dark-950" />
                      </span>
                    </div>
                    <DataLoader isLoading={pageloading} />

                    {Transactiondata != "" ? (
                      <div className="dialog-body py-6 px-5 md:px-[30px] md:py-6">
                        <div className="mb-6">
                          <ul>
                            <li className="border-b border-dark-100/20 py-3.5 last:border-0">
                              <div className="flex items-center justify-between">
                                <div className="logo-part flex-shrink-0 xsm:block hidden">
                                  <img src={Logo} alt="" className="dark:block hidden" width={"139.84px"} height={"38px"} />
                                  <img src={LogoLight} alt="" className="dark:hidden block" width={"139.84px"} height={"38px"} />
                                </div>
                                <div className="logo-part flex-shrink-0 block xsm:hidden">
                                  <img src={LogoFav} alt="" className="dark:block hidden" width={"38"} height={"38px"} />
                                </div>
                                {Transactiondata?.status == 0 ?
                                  <h5 className=' text-24 font-600' style={{ color: '#fb8c00' }}>
                                    {Transactiondata?.symbol}{Transactiondata?.amount}
                                  </h5> : Transactiondata?.status == 1 ?
                                    <h5 className='text-theme text-24 font-600' style={{ color: "#0df69e" }}>
                                      {Transactiondata?.symbol}{Transactiondata?.amount}
                                    </h5> : Transactiondata?.status == 2 ?
                                      <h5 className='text-24 font-600' style={{ color: 'red' }}>
                                        {Transactiondata?.symbol}{Transactiondata?.amount}
                                      </h5> : (Transactiondata?.status == 3 || Transactiondata?.status == 7) ?
                                        <h5 className='text-24 font-600' style={{ color: '#fb8c00' }}>
                                          {Transactiondata?.symbol}{Transactiondata?.amount}
                                        </h5> : <h5 className='text-24 font-600 text-red-600' style={{ color: 'red' }}>
                                          {Transactiondata?.symbol}{Transactiondata?.amount}
                                        </h5>
                                }
                              </div>
                            </li>
                            <li className="border-b border-dark-100/20 py-3.5 last:border-0">
                              <div className="">
                                <h6 className="text-14 font-400 dark:text-dark-300 text-dark-500 mb-2">
                                {t('label.labelTransactionId')}
                                </h6>
                                <h5 className="text-16 font-500 text-dark-950 dark:text-white">
                                  {Transactiondata?.transaction_id}
                                </h5>
                              </div>
                            </li>
                            {!((Transactiondata?.req_status == 0) || (Transactiondata?.req_status == 1) || (Transactiondata?.req_status == 2))
                              &&
                              <li className="border-b border-dark-100/20 py-3.5 last:border-0">
                                <div className="">
                                  <h6 className="text-14 font-400 dark:text-dark-300 text-dark-500 mb-2">
                                    {t('from')}
                                  </h6>
                                  <h5 className="text-16 font-500 text-dark-950 dark:text-white">
                                    {Transactiondata?.senderuser
                                      ? Transactiondata?.senderuser
                                      : Transactiondata?.from}
                                  </h5>
                                </div>
                              </li>
                            }
                            {((Transactiondata?.req_status == 0) || (Transactiondata?.req_status == 1) || (Transactiondata?.req_status == 2))
                              &&
                              <li className="border-b border-dark-100/20 py-3.5 last:border-0">
                                <div className="">
                                  <h6 className="text-14 font-400 dark:text-dark-300 text-dark-500 mb-2">
                                    {t('label.labelRequester')}
                                  </h6>
                                  <h5 className="text-16 font-500 text-dark-950 dark:text-white">
                                    {Transactiondata?.receiveruser}
                                  </h5>
                                </div>
                              </li>
                            }
                            {((Transactiondata?.req_status == 0) || (Transactiondata?.req_status == 1) || (Transactiondata?.req_status == 2))
                              &&
                              <li className="border-b border-dark-100/20 py-3.5 last:border-0">
                                <div className="">
                                  <h6 className="text-14 font-400 dark:text-dark-300 text-dark-500 mb-2">
                                    {t('to')}
                                  </h6>
                                  <h5 className="text-16 font-500 text-dark-950 dark:text-white">
                                    {Transactiondata?.from}
                                  </h5>
                                </div>
                              </li>
                            }
                            {!((Transactiondata?.req_status == 0) || (Transactiondata?.req_status == 1) || (Transactiondata?.req_status == 2)) && (Transactiondata?.type != 2 && Transactiondata?.type != 3) &&
                              <li className="border-b border-dark-100/20 py-3.5 last:border-0">
                                <div className="">
                                  <h6 className="text-14 font-400 dark:text-dark-300 text-dark-500 mb-2">
                                  {t('to')}
                                  </h6>
                                  <h5 className="text-16 font-500 text-dark-950 dark:text-white">
                                    {Transactiondata?.receiveruser
                                      ? Transactiondata?.receiveruser
                                      : Transactiondata?.to}
                                  </h5>
                                </div>
                              </li>
                            }
                            {(Transactiondata?.type == 1 || Transactiondata?.type == 0) && state?._id == (Transactiondata?.receiver || Transactiondata?.to_user_id) &&
                              <li className="border-b border-dark-100/20 py-3.5 last:border-0">
                                <div className="">
                                  <h6 className="text-14 font-400 dark:text-dark-300 text-dark-500 mb-2">
                                    {Transactiondata?.type == 1 ? t('label.labelReceiveAmount') : t('label.labelRequestedAmount')}
                                  </h6>
                                  <h5 className="text-16 font-500 text-dark-950 dark:text-white">
                                    {Transactiondata?.status == 0 ?
                                      <h5 className=' text-16 font-600' style={{ color: '#fb8c00' }}>
                                        {Transactiondata?.symbol}{Transactiondata?.amount}
                                      </h5> : Transactiondata?.status == 1 ?
                                        <h5 className='text-theme text-16 font-600' style={{ color: "#0df69e" }}>
                                          {Transactiondata?.symbol}{Transactiondata?.amount}
                                        </h5> : Transactiondata?.status == 2 ?
                                          <h5 className='text-16 font-600' style={{ color: 'red' }}>
                                            {Transactiondata?.symbol}{Transactiondata?.amount}
                                          </h5> : (Transactiondata?.status == 3 || Transactiondata?.status == 7) ?
                                            <h5 className='text-16 font-600' style={{ color: '#fb8c00' }}>
                                              {Transactiondata?.symbol}{Transactiondata?.amount}
                                            </h5> : <h5 className='text-16 font-600 text-red-600' style={{ color: 'red' }}>
                                              {Transactiondata?.symbol}{Transactiondata?.amount}
                                            </h5>
                                    }
                                  </h5>
                                </div>
                              </li>
                            }
                            {(Transactiondata?.type == 1 || Transactiondata?.type == 0) && state?._id == (Transactiondata?.sender || Transactiondata?.from_user_id) &&
                              <li className="border-b border-dark-100/20 py-3.5 last:border-0">
                                <div className="">
                                  <h6 className="text-14 font-400 dark:text-dark-300 text-dark-500 mb-2">
                                  {t('label.labelSendAmount')}
                                  </h6>
                                  <h5 className="text-16 font-500 text-dark-950 dark:text-white">
                                    {Transactiondata?.status == 0 ?
                                      <h5 className=' text-16 font-600' style={{ color: '#fb8c00' }}>
                                        {Transactiondata?.symbol}{Transactiondata?.send_amount}
                                      </h5> : Transactiondata?.status == 1 ?
                                        <h5 className='text-theme text-16 font-600' style={{ color: "#0df69e" }}>
                                          {Transactiondata?.symbol}{Transactiondata?.send_amount}
                                        </h5> : Transactiondata?.status == 2 ?
                                          <h5 className='text-16 font-600' style={{ color: 'red' }}>
                                            {Transactiondata?.symbol}{Transactiondata?.send_amount}
                                          </h5> : (Transactiondata?.status == 3 || Transactiondata?.status == 7) ?
                                            <h5 className='text-16 font-600' style={{ color: '#fb8c00' }}>
                                              {Transactiondata?.symbol}{Transactiondata?.send_amount}
                                            </h5> : <h5 className='text-16 font-600 text-red-600' style={{ color: 'red' }}>
                                              {Transactiondata?.symbol}{Transactiondata?.send_amount}
                                            </h5>
                                    }
                                  </h5>
                                </div>
                              </li>
                            }
                            {(Transactiondata?.type == 1 || Transactiondata?.type == 0) && state?._id == Transactiondata?.sender && Transactiondata?.admin_fees > 0 &&
                              <li className="border-b border-dark-100/20 py-3.5 last:border-0">
                                <div className="">
                                  <h6 className="text-14 font-400 dark:text-dark-300 text-dark-500 mb-2">
                                  {t('label.labelAdminFees')}
                                  </h6>
                                  <h5 className="text-16 font-500 text-dark-950 dark:text-white">
                                    {Transactiondata?.symbol}
                                    {Transactiondata?.admin_fees}
                                  </h5>
                                </div>
                              </li>
                            }
                            {Transactiondata?.type != 1 && Transactiondata?.type != 3 && Transactiondata?.type != 0 &&
                              <li className="border-b border-dark-100/20 py-3.5 last:border-0">
                                <div className="">
                                  <h6 className="text-14 font-400 dark:text-dark-300 text-dark-500 mb-2">
                                  {t('label.labelBankName')}
                                  </h6>
                                  <h5 className="text-16 font-500 text-dark-950 dark:text-white">
                                    {Transactiondata?.bank_name ? Transactiondata?.bank_name : Transactiondata?.client_bank_name}
                                  </h5>
                                </div>
                              </li>
                            }
                            {Transactiondata?.type == 2 &&
                              <li className="border-b border-dark-100/20 py-3.5 last:border-0">
                                <div className="">
                                  <h6 className="text-14 font-400 dark:text-dark-300 text-dark-500 mb-2">
                                  {t('label.labelDepositAmount')}
                                  </h6>
                                  <h5 className="text-16 font-500 text-dark-950 dark:text-white">
                                    {Transactiondata?.status == 0 ?
                                      <h5 className=' text-16 font-600' style={{ color: '#fb8c00' }}>
                                        {Transactiondata?.symbol}{Transactiondata?.amount}
                                      </h5> : Transactiondata?.status == 1 ?
                                        <h5 className='text-theme text-16 font-600' style={{ color: "#0df69e" }}>
                                          {Transactiondata?.symbol}{Transactiondata?.amount}
                                        </h5> : Transactiondata?.status == 2 ?
                                          <h5 className='text-16 font-600' style={{ color: 'red' }}>
                                            {Transactiondata?.symbol}{Transactiondata?.amount}
                                          </h5> : (Transactiondata?.status == 3 || Transactiondata?.status == 7) ?
                                            <h5 className='text-16 font-600' style={{ color: '#fb8c00' }}>
                                              {Transactiondata?.symbol}{Transactiondata?.amount}
                                            </h5> : <h5 className='text-16 font-600 text-red-600' style={{ color: 'red' }}>
                                              {Transactiondata?.symbol}{Transactiondata?.amount}
                                            </h5>
                                    }
                                  </h5>
                                </div>
                              </li>
                            }
                            {Transactiondata?.type == 2 &&
                              <li className="border-b border-dark-100/20 py-3.5 last:border-0">
                                <div className="">
                                  <h6 className="text-14 font-400 dark:text-dark-300 text-dark-500 mb-2">
                                    {t('depositAmountByBank')}
                                  </h6>
                                  <h5 className="text-16 font-500 text-dark-950 dark:text-white">
                                    {Transactiondata?.symbol}
                                    {Transactiondata?.total_send_amount}
                                  </h5>
                                </div>
                              </li>
                            }
                            {Transactiondata?.type == 3 && Transactiondata?.type != 0 &&
                              <li className='border-b border-dark-100/20 py-3.5 last:border-0'>
                                <div className="">
                                  <h6 className='text-14 font-400 dark:text-dark-300 text-dark-500 mb-2'>
                                  {t('label.labelBankName')}
                                  </h6>
                                  <h5 className='text-16 font-500 text-dark-950 dark:text-white'>
                                    {Transactiondata?.bankData ? Transactiondata?.bankData?.bank_name : Transactiondata?.client_bank_name}
                                  </h5>
                                </div>
                              </li>
                            }
                            {Transactiondata?.type == 3 && Transactiondata?.type != 0 &&
                              <li className='border-b border-dark-100/20 py-3.5 last:border-0'>
                                <div className="">
                                  <h6 className='text-14 font-400 dark:text-dark-300 text-dark-500 mb-2'>
                                  {t('label.labelWithdrawAmount')}
                                  </h6>
                                  <h5 className='text-16 font-500 text-dark-950 dark:text-white'>
                                    {Transactiondata?.status == 0 ?
                                      <h5 className=' text-16 font-600' style={{ color: '#fb8c00' }}>
                                        {Transactiondata?.symbol}{Transactiondata?.send_amount}
                                      </h5> : Transactiondata?.status == 1 ?
                                        <h5 className='text-theme text-16 font-600' style={{ color: "#0df69e" }}>
                                          {Transactiondata?.symbol}{Transactiondata?.send_amount}
                                        </h5> : Transactiondata?.status == 2 ?
                                          <h5 className='text-16 font-600' style={{ color: 'red' }}>
                                            {Transactiondata?.symbol}{Transactiondata?.send_amount}
                                          </h5> : (Transactiondata?.status == 3 || Transactiondata?.status == 7) ?
                                            <h5 className='text-16 font-600' style={{ color: '#fb8c00' }}>
                                              {Transactiondata?.symbol}{Transactiondata?.send_amount}
                                            </h5> : <h5 className='text-16 font-600 text-red-600' style={{ color: 'red' }}>
                                              {Transactiondata?.symbol}{Transactiondata?.send_amount}
                                            </h5>
                                    }
                                  </h5>
                                </div>
                              </li>
                            }
                            {(Transactiondata?.type == 2 || Transactiondata?.type == 3) && Transactiondata?.admin_fees > 0 &&
                              <li className="border-b border-dark-100/20 py-3.5 last:border-0">
                                <div className="">
                                  <h6 className="text-14 font-400 dark:text-dark-300 text-dark-500 mb-2">
                                  {t('label.labelAdminFees')}
                                  </h6>
                                  <h5 className="text-16 font-500 text-dark-950 dark:text-white">
                                    {Transactiondata?.symbol}
                                    {Transactiondata?.admin_fees}
                                  </h5>
                                </div>
                              </li>
                            }
                            {(Transactiondata?.type == 1 || Transactiondata?.type == 0) && state?._id == Transactiondata?.sender &&
                              <li className="border-b border-dark-100/20 py-3.5 last:border-0">
                                <div className="">
                                  <h6 className="text-14 font-400 dark:text-dark-300 text-dark-500 mb-2">
                                  {t('label.labelTotalPayable')}
                                  </h6>
                                  {((Transactiondata?.status == 0) || (Transactiondata?.status == 7))  ? (
                                    <h5
                                      className=" text-16 font-600"
                                      style={{ color: "#fb8c00" }}
                                    >
                                      {Transactiondata?.symbol}
                                      {Transactiondata?.total_send_amount}
                                    </h5>
                                  ) : Transactiondata?.status == 1 ? (
                                    <h5
                                      className="text-theme text-16 font-600"
                                      style={{ color: "#0df69e" }}
                                    >
                                      {Transactiondata?.symbol}
                                      {Transactiondata?.total_send_amount}
                                    </h5>
                                  ) : Transactiondata?.status == 2 ? (
                                    <h5
                                      className="text-16 font-600"
                                      style={{ color: "red" }}
                                    >
                                      {Transactiondata?.symbol}
                                      {Transactiondata?.total_send_amount}
                                    </h5>
                                  ) : Transactiondata?.status == 3 ? (
                                    <h5
                                      className="text-16 font-600"
                                      style={{ color: "#fb8c00" }}
                                    >
                                      {Transactiondata?.symbol}
                                      {Transactiondata?.total_send_amount}
                                    </h5>
                                  ) : (
                                    <h5
                                      className="text-16 font-600 text-red-600"
                                      style={{ color: "red" }}
                                    >
                                      {Transactiondata?.symbol}
                                      {Transactiondata?.total_send_amount}
                                    </h5>
                                  )}
                                </div>
                              </li>
                            }
                            {Transactiondata?.type == 3 &&
                              <li className="border-b border-dark-100/20 py-3.5 last:border-0">
                                <div className="">
                                  <h6 className="text-14 font-400 dark:text-dark-300 text-dark-500 mb-2">
                                    {t('label.labelTotalPayable')}
                                  </h6>
                                  {((Transactiondata?.status == 0) || (Transactiondata?.status == 7))  ? (
                                    <h5
                                      className=" text-16 font-600"
                                      style={{ color: "#fb8c00" }}
                                    >
                                      {Transactiondata?.symbol}
                                      {Transactiondata?.total_send_amount}
                                    </h5>
                                  ) : Transactiondata?.status == 1 ? (
                                    <h5
                                      className="text-theme text-16 font-600"
                                      style={{ color: "#0df69e" }}
                                    >
                                      {Transactiondata?.symbol}
                                      {Transactiondata?.total_send_amount}
                                    </h5>
                                  ) : Transactiondata?.status == 2 ? (
                                    <h5
                                      className="text-16 font-600"
                                      style={{ color: "red" }}
                                    >
                                      {Transactiondata?.symbol}
                                      {Transactiondata?.total_send_amount}
                                    </h5>
                                  ) : Transactiondata?.status == 3 ? (
                                    <h5
                                      className="text-16 font-600"
                                      style={{ color: "#fb8c00" }}
                                    >
                                      {Transactiondata?.symbol}
                                      {Transactiondata?.total_send_amount}
                                    </h5>
                                  ) : (
                                    <h5
                                      className="text-16 font-600 text-red-600"
                                      style={{ color: "red" }}
                                    >
                                      {Transactiondata?.symbol}
                                      {Transactiondata?.total_send_amount}
                                    </h5>
                                  )}
                                </div>
                              </li>
                            }
                            <li className="border-b border-dark-100/20 py-3.5 last:border-0">
                              <div className="">
                                <h6 className="text-14 font-400 dark:text-dark-300 text-dark-500 mb-2">
                                  {t('dateTime')}
                                </h6>
                                <h5 className="text-16 font-500 text-dark-950 dark:text-white">
                                  {moment(Transactiondata?.createdAt).format(
                                    "DD MMM hh:mm:A"
                                  )}
                                </h5>
                              </div>
                            </li>
                            {Transactiondata?.type != 2 && Transactiondata?.type != 3 && Transactiondata?.note != "" &&
                              <li className="border-b border-dark-100/20 py-3.5 last:border-0">
                                <div className="">
                                  <h6 className="text-14 font-400 dark:text-dark-300 text-dark-500 mb-2">
                                  {t('note')}
                                  </h6>
                                  <h5 className="text-16 font-500 text-dark-950 dark:text-white">
                                    {Transactiondata?.note != ""
                                      ? Transactiondata?.note.split('\n').map((line, index) => (
                                        <span key={index}>
                                          {line}
                                          <br />
                                        </span>
                                      ))
                                      : "-"}
                                  </h5>
                                </div>
                              </li>
                            }
                            <li className="border-b border-dark-100/20 py-3.5 last:border-0">
                              <div className="">
                                <h6 className="text-14 font-400 dark:text-dark-300 text-dark-500 mb-2">
                                  {t('label.labelStatus')}
                                </h6>
                                {Transactiondata?.status == 0 ? (
                                  <h5
                                    className="text-16 font-50"
                                    style={{ color: "#fb8c00" }}
                                  >
                                    {t('pending')}
                                  </h5>
                                ) : Transactiondata?.status == 1 ? (
                                  <h5 className="text-theme text-16 font-50" style={{ color: "#0df69e" }}>
                                    {t('label.labelSuccess')}
                                  </h5>
                                ) : Transactiondata?.status == 2 ? (
                                  <h5
                                    className="text-16 font-50"
                                    style={{ color: "red" }}
                                  >
                                    {t('label.labelFailed')}
                                  </h5>
                                ) : Transactiondata?.status == 3 ? (
                                  <h5
                                    className="text-16 font-50"
                                    style={{ color: "#fb8c00" }}
                                  >
                                    {t('label.labelAdminForReview')}
                                  </h5>
                                ) : Transactiondata?.status == 4 ? (
                                  <h5 className="text-16 font-50 text-red-600" style={{ color: "red" }}>
                                    {t('label.labelAdminRejected')}
                                  </h5>
                                ) : Transactiondata?.status == 5 ? (
                                  <h5 className="text-16 font-50 text-red-600" style={{ color: "red" }}>
                                    {t('label.labelReject')}
                                  </h5>
                                ) : Transactiondata?.status == 6 ? (
                                  <h5 className="text-16 font-50 text-red-600" style={{ color: "red" }}>
                                    {t('label.labelCancelled')}
                                  </h5>
                                ) : (
                                  <h5
                                    className="text-16 font-50"
                                    style={{ color: "#FF8C00" }}
                                  >
                                    {t('label.labelPaymentPending')}
                                  </h5>
                                )}
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="grid grid-cols-1 xsm:grid-cols-2 gap-sm">
                          <Button
                            onClick={sendEmail}
                            disabled={loading}
                            className="md:min-w-[150px] md:ms-auto"
                            variant="outline"
                          >
                            {t('label.labelSendEmail')}
                          </Button>
                          <Button
                            onClick={pdfDownload}
                            disabled={loading}
                            className="md:min-w-[150px] md:me-auto"
                          >
                            {t('btn.btnDownload')}
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="dialog-body py-6 px-5 md:px-[30px] md:py-6">
                        No data found
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default withTranslation()(TransactionsHistoryModal);
