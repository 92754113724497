import React from 'react'

const IconTransfer = (props) => {
    return (
        <svg {...props} viewBox="0 0 29 28" fill="none">
            <g clipPath="url(#clip0_789_11140)">
                <path d="M27.4277 9.62519L25.863 10.1395C26.271 11.381 26.4779 12.6799 26.4779 14C26.4779 20.8114 20.9364 26.3529 14.125 26.3529C10.9603 26.3529 7.99168 25.1715 5.72379 23.0588H8.08578V21.4118H2.8701V26.3529H4.51716V24.1789C5.51 25.1151 6.63736 25.9094 7.85152 26.519C9.80905 27.5017 11.9198 28 14.125 28C17.8645 28 21.3802 26.5438 24.0245 23.8995C26.6688 21.2552 28.125 17.7395 28.125 14C28.125 12.5049 27.8904 11.033 27.4277 9.62519ZM14.125 1.64706C17.2692 1.64706 20.2512 2.83168 22.5232 4.94118H20.1642V6.58824H25.3799V1.64706H23.7328V3.81799C21.1472 1.37447 17.7304 0 14.125 0C10.3855 0 6.86976 1.45622 4.22552 4.10052C1.58127 6.74482 0.125 10.2605 0.125 14C0.125 15.6005 0.393086 17.1706 0.921847 18.6666L2.47475 18.1178C2.00852 16.7985 1.77206 15.4131 1.77206 14C1.77206 7.18859 7.31359 1.64706 14.125 1.64706Z" fill="currentcolor" />
                <path d="M14.1523 5.74219C9.58391 5.74219 5.86719 9.45891 5.86719 14.0273C5.86719 18.5958 9.58391 22.3125 14.1523 22.3125C18.7208 22.3125 22.4375 18.5958 22.4375 14.0273C22.4375 9.45891 18.7208 5.74219 14.1523 5.74219ZM20.7789 11.2656H17.812C17.5515 9.58719 17.0562 8.18355 16.3994 7.21211C18.3786 7.86636 19.9801 9.35617 20.7789 11.2656ZM16.9141 14.0273C16.9141 14.6061 16.8846 15.158 16.8344 15.6844H11.4703C11.42 15.158 11.3906 14.6061 11.3906 14.0273C11.3906 13.4486 11.42 12.8967 11.4703 12.3703H16.8342C16.8846 12.8967 16.9141 13.4486 16.9141 14.0273ZM14.1523 21.2078C13.1551 21.2078 12.0729 19.4855 11.6158 16.7891H16.6888C16.2318 19.4855 15.1496 21.2078 14.1523 21.2078ZM11.6158 11.2656C12.0729 8.56922 13.1551 6.84687 14.1523 6.84687C15.1496 6.84687 16.2318 8.56922 16.6888 11.2656H11.6158ZM11.9053 7.21211C11.2485 8.18368 10.7531 9.58719 10.4927 11.2656H7.52574C8.32456 9.35617 9.92609 7.86636 11.9053 7.21211ZM7.17251 12.3703H10.3597C10.313 12.9035 10.2859 13.4555 10.2859 14.0273C10.2859 14.5992 10.313 15.1512 10.3598 15.6844H7.17251C7.04603 15.1514 6.97187 14.5985 6.97187 14.0273C6.97187 13.4562 7.04603 12.9033 7.17251 12.3703ZM7.52574 16.7891H10.4925C10.7531 18.4675 11.2484 19.8711 11.9051 20.8426C9.92595 20.1883 8.32456 18.6985 7.52574 16.7891ZM16.3994 20.8426C17.0562 19.871 17.5516 18.4675 17.812 16.7891H20.7788C19.9801 18.6985 18.3787 20.1883 16.3994 20.8426ZM21.1322 15.6844H17.9449C17.9917 15.1512 18.0187 14.5992 18.0187 14.0273C18.0187 13.4555 17.9917 12.9035 17.9449 12.3703H21.132C21.2587 12.9033 21.3327 13.4562 21.3327 14.0273C21.3327 14.5985 21.2587 15.1514 21.1322 15.6844Z" fill="#0DF69E" />
            </g>
            <defs>
                <clipPath id="clip0_789_11140">
                    <rect width="28" height="28" fill="white" transform="translate(0.125)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconTransfer