import React from 'react'

const IconUSDT = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none">
            <path d="M14.0626 9.33398V6.98836H19.3989V3.28711H4.58637V6.98836H9.93762V9.33398C5.45449 9.54961 2.08887 10.4496 2.08887 11.5277C2.08887 12.6059 5.45074 13.5002 9.93762 13.7215V21.4634H14.0626V13.7215C18.5457 13.5077 21.9114 12.6077 21.9114 11.5277C21.9114 10.4477 18.5495 9.54961 14.0626 9.33398ZM12.0001 13.0202C7.14574 13.0202 3.21012 12.2702 3.21012 11.3665C3.21012 10.5884 6.07699 9.93586 9.93387 9.76148V12.4109C11.3082 12.4731 12.6846 12.4731 14.0589 12.4109V9.76148C17.9157 9.93586 20.7826 10.5884 20.7826 11.3665C20.7901 12.2796 16.8545 13.0202 12.0001 13.0202Z" fill="currentcolor" />
        </svg>
    )
}

export default IconUSDT
