import React, { createContext, useContext, useEffect, useState } from "react";
import Loader from "../components/common/Loader";
import fontFamily from "../assets/font/PlusJakartaSans-Regular.woff"

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("light"); // Default theme is light
  const [fontsLoaded, setFontsLoaded] = useState(false);
  useEffect(() => {
    const font = new FontFace('PlusJakartaSans', `url(${fontFamily})`, {
      style: 'normal',
      weight: '400'
    });

    font.load().then(() => {
      document.fonts.add(font);
      setFontsLoaded(true);
    }).catch((error) => {
      console.log('Font loading error:', error);
      setFontsLoaded(true);
    });

  }, []);
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {fontsLoaded ? children : <Loader />}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
