import React from 'react'
import { cn } from '../../lib/utils'

const TD = ({ children, className, ...props }) => {
    return (
        <td {...props} className={cn("dark:bg-dark-800 bg-white align-middle p-3 last:pr-5", className)}>
            {children}
        </td>
    )
}

export default TD