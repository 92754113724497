import React from 'react'

const IconWithdraw = (props) => {
    return (
        <svg {...props} viewBox="0 0 29 28" fill="none" >
            <path fillRule="evenodd" clipRule="evenodd" d="M1.7583 9.91667C1.7583 9.53008 2.0717 9.21667 2.4583 9.21667H17.0416C17.4282 9.21667 17.7416 9.53008 17.7416 9.91667C17.7416 10.3033 17.4282 10.6167 17.0416 10.6167H2.4583C2.0717 10.6167 1.7583 10.3033 1.7583 9.91667ZM6.42497 19.25C6.42497 18.8634 6.73837 18.55 7.12497 18.55H9.4583C9.8449 18.55 10.1583 18.8634 10.1583 19.25C10.1583 19.6366 9.8449 19.95 9.4583 19.95H7.12497C6.73837 19.95 6.42497 19.6366 6.42497 19.25ZM11.675 19.25C11.675 18.8634 11.9884 18.55 12.375 18.55H17.0416C17.4282 18.55 17.7416 18.8634 17.7416 19.25C17.7416 19.6366 17.4282 19.95 17.0416 19.95H12.375C11.9884 19.95 11.675 19.6366 11.675 19.25Z" fill="currentcolor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M3.98734 5.60628C3.43516 6.15225 3.1583 7.1539 3.1583 9.20497V18.795C3.1583 20.846 3.43516 21.8477 3.98734 22.3937C4.54228 22.9424 5.56187 23.2166 7.6383 23.2166H20.6116C22.6881 23.2166 23.7077 22.9424 24.2626 22.3937C24.8148 21.8477 25.0916 20.846 25.0916 18.795V16.3683C25.0916 15.9817 25.405 15.6683 25.7916 15.6683C26.1782 15.6683 26.4916 15.9817 26.4916 16.3683V18.795C26.4916 20.8389 26.2493 22.3981 25.2469 23.3892C24.2473 24.3776 22.6769 24.6166 20.6116 24.6166H7.6383C5.57306 24.6166 4.00265 24.3776 3.00301 23.3892C2.00061 22.3981 1.7583 20.8389 1.7583 18.795V9.20497C1.7583 7.16104 2.00061 5.60186 3.00301 4.61074C4.00265 3.62235 5.57306 3.3833 7.6383 3.3833H17.0416C17.4282 3.3833 17.7416 3.6967 17.7416 4.0833C17.7416 4.4699 17.4282 4.7833 17.0416 4.7833H7.6383C5.56187 4.7833 4.54228 5.05758 3.98734 5.60628Z" fill="currentcolor" />
            <path d="M23.4583 11.7833C23.8449 11.7833 24.1583 11.4699 24.1583 11.0833V5.77327L25.2966 6.91163C25.57 7.185 26.0132 7.185 26.2866 6.91163C26.5599 6.63827 26.5599 6.19505 26.2866 5.92168L23.9532 3.58835C23.822 3.45707 23.6439 3.38332 23.4583 3.38332C23.2726 3.38332 23.0946 3.45707 22.9633 3.58835L20.63 5.92168C20.3566 6.19505 20.3566 6.63827 20.63 6.91163C20.9033 7.185 21.3465 7.185 21.6199 6.91163L22.7583 5.77327V11.0833C22.7583 11.4699 23.0717 11.7833 23.4583 11.7833Z" fill="#0DF69E" />
        </svg>
    )
}

export default IconWithdraw