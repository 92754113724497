import React from 'react'
import { cn } from '../../lib/utils'

const Table = ({ children, className, ...props }) => {
    return (
        <table {...props} className={cn('w-full border-separate border-spacing-y-[14px]', className)}>
            {children}
        </table>
    )
}

export default Table