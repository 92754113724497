import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { cn } from '../../lib/utils';
import { withTranslation } from 'react-i18next';

const CustomRadio = ({ data, lable, className, lableClass, plan, setPage, setLoading, setPlan, symbol, classNamepera, disabled, t, type, ...props }) => {
  const handleRadioClick = () => {
    if (setLoading) {
      setLoading(true);
      setPage(1);
    }
  };

  return (
    <div className='form-group flex flex-col flex-wrap gap-sm'>
      {lable && <label htmlFor="" className={cn('className,lableClass')}>{lable}</label>}
      <RadioGroup disabled={disabled} value={plan} onChange={setPlan} className={cn(`flex flex-wrap gap-sm `, classNamepera)}>
        {data && data?.map((time, index) => (
          <RadioGroup.Option key={index} value={type === 'sortingdata' ? time.value : time} className={"cursor-pointer"}>
            {({ checked }) => (
              <span className={cn(`py-2 block px-5 rounded-full text-[14px] font-500 border ${checked ? 'bg-theme text-dark-950 border-theme' : 'dark:text-white text-dark-500 border-dark-300 dark:border-dark-600'}`, className)} onClick={() => handleRadioClick(type === 'sortingdata' ? time.value : time)}>{symbol ?? symbol}{type === 'sortingdata' ? t(time.name) : time}</span>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
    </div>
  )
}

export default withTranslation()(CustomRadio)