import React from "react";
import { Button } from "../../../components/ui/Button";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

const DownloadApp = ({ setStep,t }) => {
  return (
    <>
      <div className="flex flex-col justify-center items-center h-full sm:m-auto pt-20 sm:mt-[140px] sm:mb-[80px] my-[40px] mb-0">
        <img
          src={require("../../../assets/images/GoogleAuth.svg").default}
          className="w-[120px]"
          alt=""
        />
        <p className="text-dark-100  font-400 max-w-[300px] text-center mt-6">
          {t('instructionGoogleAuth')}
        </p>
        <div className="flex mt-16 gap-6">
          <Link to={"https://play.google.com/store/search?q=google+authentication+code+app&c=apps&hl=en-IN"} target="_blank">
            <img
              src={require("../../../assets/images/GooglePlay.png")}
              className="w-full cursor-pointer"
              alt=""
            />
          </Link>
          <Link to={"https://apps.apple.com/us/app/google-authenticator/id388497605"} target="_blank">
            <img
              src={require("../../../assets/images/AppStore2.svg").default}
              className="w-full cursor-pointer"
              alt=""
            />
          </Link>
        </div>
      <div className="mt-20">
        <Button className="sm:min-w-[350px] md:min-w-[350px] min-w-[100%]" size="sm" onClick={() => setStep(2)}>
        {t('next')}
        </Button>
      </div>
      </div>
    </>
  );
};

export default withTranslation()(DownloadApp);
