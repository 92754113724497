import { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useStore from '../contexts/AuthProvider';
// import Login from '../pages/dash/authentication/Login';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const state = useStore()
  const isAuthenticated = state.isAuthenticated;
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const navigate = useNavigate();

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }

    // return navigate("/login");
    return <Navigate to="/" />;
    // return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
