import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import successImage from "../../../assets/images/Successful.json"
import Lottie from 'react-lottie'
import { cn } from '../../../lib/utils'
import { Button } from '../../ui/Button'
import { useNavigate } from 'react-router'
import { withTranslation } from 'react-i18next';

const SuccessMessageModal = ({ open, handleClose, message, message2, messageClass, setFlag, type,t }) => {

  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: successImage,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleDone = () => {
    navigate("/dashboard/setting");
    handleClose();
    setFlag(1);
  }

  const handleDoneForAuthentication = () => {
    // handleClose();
    navigate("/dashboard");
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={() => { return }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform rounded-[30px] bg-white dark:bg-dark-800 overflow-hidden border-none shadow-xl transition-all">
                < div className='dialog-body p-8' >
                  <Lottie
                    options={defaultOptions}
                    height={190}
                    width={190}
                  />
                  <p className={cn('w-[60%] text-center dark:text-white text-dark-950 mx-auto text-20 font-600 mb-12', messageClass)}>{message}</p>
                  <p className={cn('w-[60%] text-center dark:text-white text-dark-950 mx-auto text-14 font-400 !mb-12', messageClass)}>{message2}</p>
                  <Button size={"sm"} className="w-full" onClick={type === 'email-mobile-authentication' ? handleDoneForAuthentication : handleDone} /* onClick={handleClose} */>{t('done')}</Button>
                </div >
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )


}

export default withTranslation()(SuccessMessageModal)
