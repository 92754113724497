import React, { useEffect, useState } from 'react'
import { CMS_API_URL } from "../api/Path";
import Http from '../api/Http';
import { withTranslation } from 'react-i18next';
const PrivacyPolicy = ({t}) => {
    const [data, setData] = useState("");

    async function getData() {
        const res = await Http.get(CMS_API_URL.getPrivacyPolicy);
        setData(res?.data);
    }
    useEffect(() => {
        getData();
    }, []);
    return (
        <>
            <div className='m-4'>
                <h4 className='text-50 text-white relative font-1000 mb-4'>
                {t('privacyPolicy')}
                </h4>
                <div dangerouslySetInnerHTML={{ __html: data }} className='terms-content text-white'></div>
            </div>

        </>
    )
}

export default withTranslation()(PrivacyPolicy)