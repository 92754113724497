import React from 'react'

const IconCopy = (props) => {
    return (
        <svg {...props} viewBox="0 0 20 20" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.0319 7.96816C17.5485 8.48476 17.8334 9.341 17.8334 10.75V14.25C17.8334 15.659 17.5485 16.5153 17.0319 17.0319C16.5153 17.5485 15.6591 17.8334 14.25 17.8334H10.75C9.34102 17.8334 8.48478 17.5485 7.96818 17.0319C7.45157 16.5153 7.16671 15.659 7.16671 14.25V10.75C7.16671 9.341 7.45157 8.48476 7.96818 7.96816C8.48478 7.45155 9.34102 7.16669 10.75 7.16669H14.25C15.6591 7.16669 16.5153 7.45155 17.0319 7.96816ZM17.739 7.26105C16.9431 6.46515 15.7577 6.16669 14.25 6.16669H10.75C9.24239 6.16669 8.05696 6.46515 7.26107 7.26105C6.46517 8.05695 6.16671 9.24237 6.16671 10.75V14.25C6.16671 15.7577 6.46517 16.9431 7.26107 17.739C8.05696 18.5349 9.24239 18.8334 10.75 18.8334H14.25C15.7577 18.8334 16.9431 18.5349 17.739 17.739C18.5349 16.9431 18.8334 15.7577 18.8334 14.25V10.75C18.8334 9.24237 18.5349 8.05695 17.739 7.26105Z" fill="currentcolor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.0319 2.96816C12.5485 3.48476 12.8334 4.341 12.8334 5.75002V6.16669H10.75C9.24239 6.16669 8.05697 6.46516 7.26107 7.26105C6.46517 8.05695 6.16671 9.24237 6.16671 10.75V12.8334H5.75004C4.34102 12.8334 3.48478 12.5485 2.96818 12.0319C2.45157 11.5153 2.16671 10.659 2.16671 9.25002V5.75002C2.16671 4.341 2.45157 3.48476 2.96818 2.96816C3.48478 2.45155 4.34102 2.16669 5.75004 2.16669H9.25004C10.6591 2.16669 11.5153 2.45155 12.0319 2.96816ZM12.739 2.26105C11.9431 1.46515 10.7577 1.16669 9.25004 1.16669H5.75004C4.24239 1.16669 3.05696 1.46515 2.26107 2.26105C1.46517 3.05695 1.16671 4.24237 1.16671 5.75002V9.25002C1.16671 10.7577 1.46517 11.9431 2.26107 12.739C3.05696 13.5349 4.24239 13.8334 5.75004 13.8334H6.66671C6.94285 13.8334 7.16671 13.6095 7.16671 13.3334V10.75C7.16671 9.341 7.45157 8.48476 7.96818 7.96816C8.48478 7.45155 9.34102 7.16669 10.75 7.16669H13.3334C13.6095 7.16669 13.8334 6.94283 13.8334 6.66669V5.75002C13.8334 4.24237 13.5349 3.05695 12.739 2.26105Z" fill="currentcolor" />
        </svg>
    )
}

export default IconCopy