import React from 'react'
import { Button } from '../../../../ui/Button'
import PendingGif from '../../../../../assets/images/Cancel.gif'
import { useNavigate } from 'react-router'
import { withTranslation } from 'react-i18next';

const ExpireLinkModel = ({t}) => {
    const navigate = useNavigate();

    const handleClose = () => {
        navigate('/dashboard')
    }
    return (
        <div className='success-content'>
            <div className='icon'>
                <img src={PendingGif} alt="" className='max-w-[200px] mx-auto' />
            </div>
            <h5 className='mb-4 after:text-20 font-600 text-dark-950 dark:text-white text-center '>
                {t('label.labelLinkExpired')}
            </h5>
            <p className='text-center text-14 font-400 text-dark-500 dark:text-dark-300 mb-7'>{t('error.errorYourPaymentLinkIsExpired')}</p>

            <Button className="w-full" onClick={handleClose}>
                Ok
            </Button>
        </div>
    )
}

export default withTranslation()(ExpireLinkModel)
