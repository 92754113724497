import React from 'react'

const IconXRP = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" >
            <path d="M18.7502 21.75H1.50021C1.3577 21.7499 1.21815 21.7093 1.09791 21.6328C0.97767 21.5563 0.881713 21.4471 0.821275 21.3181C0.760837 21.189 0.738421 21.0454 0.75665 20.9041C0.77488 20.7627 0.833001 20.6295 0.924207 20.52L4.67421 16.02C4.74456 15.9355 4.83263 15.8676 4.93216 15.8209C5.0317 15.7742 5.14028 15.75 5.25021 15.75H22.5002C22.6428 15.7499 22.7824 15.7905 22.9028 15.8669C23.0231 15.9433 23.1192 16.0525 23.1797 16.1816C23.2403 16.3107 23.2627 16.4543 23.2445 16.5958C23.2263 16.7372 23.1682 16.8704 23.077 16.98L19.327 21.48C19.2565 21.5646 19.1683 21.6326 19.0687 21.6793C18.969 21.7259 18.8603 21.7501 18.7502 21.75ZM22.5002 15H5.25021C5.14028 15 5.0317 14.9758 4.93216 14.9291C4.83263 14.8824 4.74456 14.8145 4.67421 14.73L0.924207 10.23C0.833001 10.1205 0.77488 9.98728 0.75665 9.84594C0.738421 9.7046 0.760837 9.56099 0.821275 9.43193C0.881713 9.30287 0.97767 9.1937 1.09791 9.1172C1.21815 9.04071 1.3577 9.00005 1.50021 9H18.7502C18.8603 8.99993 18.969 9.02408 19.0687 9.07075C19.1683 9.11741 19.2565 9.18544 19.327 9.27L23.077 13.77C23.1682 13.8796 23.2263 14.0128 23.2445 14.1542C23.2627 14.2957 23.2403 14.4393 23.1797 14.5684C23.1192 14.6975 23.0231 14.8067 22.9028 14.8831C22.7824 14.9595 22.6428 15.0001 22.5002 15ZM18.7502 8.25H1.50021C1.3577 8.24995 1.21815 8.20929 1.09791 8.1328C0.97767 8.0563 0.881713 7.94713 0.821275 7.81807C0.760837 7.68901 0.738421 7.5454 0.75665 7.40406C0.77488 7.26272 0.833001 7.1295 0.924207 7.02L4.67421 2.52C4.74456 2.43553 4.83263 2.36756 4.93216 2.32091C5.0317 2.27425 5.14028 2.25004 5.25021 2.25H22.5002C22.6428 2.24991 22.7824 2.29046 22.9028 2.3669C23.0231 2.44334 23.1192 2.5525 23.1797 2.68159C23.2403 2.81068 23.2627 2.95434 23.2445 3.09575C23.2263 3.23716 23.1682 3.37045 23.077 3.48L19.327 7.98C19.2565 8.06456 19.1683 8.13259 19.0687 8.17925C18.969 8.22592 18.8603 8.25007 18.7502 8.25Z" fill="currentcolor" />
        </svg>
    )
}

export default IconXRP
