import React, { useEffect, useState } from 'react'
import Http from '../../api/Http';
import { AUTH_API_URL, TRANSACTION_URL } from '../../api/Path';
import moment from 'moment';
import logo from "../../assets/images/logo2.png"
import DataLoader from '../../components/common/DataLoader';
import TransactionsHistoryModal from '../../components/dash/model/common/TransactionsHistoryModal';
import { useNavigate } from 'react-router-dom';
import ExchangeTransactionsHistoryModal from '../../components/dash/model/common/ExchangeTransactionModal';
import ReactPaginate from 'react-paginate';
import EmptyScreenCompo from '../../components/dash/EmptyScreenCompo';
import IconTranscation from '../../components/svg/IconTranscation';
import { withTranslation } from 'react-i18next';

const Notification = ({t}) => {
    const navigate = useNavigate();
    const [notification, setNotificationData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [transactionHistoryModalOpen, setTransactionHistoryModalOpen] = useState(false);
    const [exchangetransactionHistoryModalOpen, setTransactionExchangeHistoryModalOpen] = useState(false);
    const [Transactiondata, setTransactiondata] = useState("");
    const [ExchangeTransactiondata, setExchangeTransactiondata] = useState("");
    const [total, setTotal] = useState();
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
    const [page, setPage] = useState(1);
    const [prevnext, setPrevNext] = useState(0);

    const getTransactionDetail = async (data) => {
        setLoading(true)
        let api_type = '';
        if (data?.transaction_type) {
            api_type = 2;
        } else {
            api_type = 1
        }
        const response = await Http.get(TRANSACTION_URL.getTransactionDetail + `?id=${data?.id}&api_type=${api_type}`);
        if (api_type === 2) {
            setLoading(false)
            setTransactiondata(response?.data);
            setTransactionHistoryModalOpen(true)
        } else {
            setLoading(false)
            setExchangeTransactiondata(response?.data);
            setTransactionExchangeHistoryModalOpen(true)
        }
    }
    const getSupport = async (data) => {
        if (data?.type === '6') {
            navigate('/dashboard/setting/support/ticket-detail', { state: data });
        }
    }

    async function getNotification() {
        setLoading(true)
        const response = await Http.get(`${AUTH_API_URL.getnotification}?page=${page}`);
        setNotificationData(response?.data?.paginatedNotifications);
        setTotal(response?.data?.total);
        setLoading(false);
    }

    useEffect(() => {
        getNotification();
    }, [page])


    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
        setPage(selected + 1)
        setPrevNext(selected)
    };

    useEffect(() => {
        setCurrentPage(0);
    }, [notification]);

    const pageCount = Math.ceil(total / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedData = notification?.slice(startIndex, endIndex);
    const lastPage = (prevnext === pageCount-1);

    return (
        <section className='p-5 xsm:p-6 sm:p-10 h-full'>
            <div className="notification-content">
                <div className="flex items-center justify-between gap-4  bg-white dark:bg-dark-800 py-4 px-6 mb-4 3xl:mb-5">
                    <h6 className="text-16 font-500text-center dark:text-white text-dark-950 ">
                        {t('allNotifications')}
                    </h6>
                </div>
                <DataLoader isLoading={loading} />
                <div className="notifications-list">
                    <div className="mb-4">
                        {/* <h4 className='text-dark-950 dark:text-white text-14 mb-4'>Today</h4> */}
                        {displayedData?.length > 0 ? <ul>
                            {
                                displayedData?.map((data, index) => {
                                    const dateObject = moment(data?.createdAt);
                                    const currentDate = moment();
                                    const diffObject = currentDate.diff(dateObject, 'seconds', true);

                                    let formatted;

                                    if (diffObject >= 3600) {
                                        const hoursAgo = Math.floor(diffObject / 3600);
                                        formatted = hoursAgo < 24 ? `${hoursAgo} hr ago` : moment(data?.createdAt).format('DD MMM YYYY hh:mm A');

                                    } else if (diffObject >= 60) {
                                        const minutesAgo = Math.floor(diffObject / 60);
                                        formatted = `${minutesAgo} ${t('minutesago')}`;
                                    } else {
                                        const secondsAgo = Math.floor(diffObject);
                                        formatted = `${secondsAgo} ${t('secondsago')}`;
                                    }
                                    if (data?.type === 2) {
                                        // const regex = /([^\s\d,.]+)([\d,.]+)/;
                                        const hasHtmlContent = /<[a-z][\s\S]*>/i.test(data?.message);
                                        return <li key={index} className='dark:bg-dark-800 bg-white align-middle p-3 last:pr-5 mb-4 rounded-sm cursor-pointer' onClick={() => getTransactionDetail(data?.data)}>
                                            <div className='flex items-center gap-sm'>
                                                <div className="avatar w-[40px] h-[40px] rounded-full overflow-hidden flex-shrink-0">
                                                    {
                                                        data?.sender_image && data?.sender_image ?
                                                            <img src={process.env.REACT_APP_PROFILE_URL + 'user/' + data?.sender_image} alt="" className='w-[100%] h-[100%] object-cover object-center' />
                                                            : <img src={logo} alt="" className='w-[100%] h-[100%] object-cover object-center' />
                                                    }
                                                </div>
                                                <div className="flex items-center gap-[36px] flex-grow justify-between">
                                                    <h6 className='text-14 text-dark-950 dark:text-white font-400 leading-1.5'>
                                                        <div>
                                                            {hasHtmlContent ? (
                                                                <div dangerouslySetInnerHTML={{ __html: data?.message }} />
                                                            ) : (
                                                                <p>{data?.message}</p>
                                                            )}
                                                        </div>
                                                        {/* {renderAmountsInGreen(data?.message)} */}
                                                    </h6>
                                                    <span className='text-12 font-400 text-[#737175] whitespace-nowrap'>
                                                        {formatted}
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    } else {
                                        return (
                                            <li key={index}
                                                className={`dark:bg-dark-800 bg-white align-middle p-3 last:pr-5 mb-4 rounded-sm 
                                            ${data?.type === 6 ? 'cursor-pointer' : ''}`}
                                                onClick={() => data?.type === 6 ? getSupport(data?.data) : ''}>
                                                <div className='flex items-center gap-sm'>
                                                    <div className="avatar w-[40px] h-[40px] rounded-full overflow-hidden flex-shrink-0" >
                                                        <img src={logo} alt="" className='w-[100%] h-[100%] object-cover object-center' />
                                                    </div>
                                                    <div className="flex items-center gap-[36px] flex-grow justify-between">
                                                        <h6 className='text-14 text-dark-950 dark:text-white font-400 leading-1.5'>
                                                            <p>{data?.message}</p>
                                                        </h6>
                                                        <span className='text-12 font-400 text-[#737175] whitespace-nowrap'>
                                                            {formatted}
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>)
                                    }

                                })
                            }
                        </ul> : <EmptyScreenCompo title={t('noNotification')} contant={""} icon={<IconTranscation className={"h-10 w-10 text-white"} />} btncontant={""} btnlink={""} />}
                    </div>
                    {/* <div className="">
                        <h4 className='text-dark-950 dark:text-white text-14 mb-4'>Yesterday</h4>
                        <ul>
                            {
                                [...Array(4)].map((data, index) => {
                                    return <li key={index} className='dark:bg-dark-800 bg-white align-middle p-3 last:pr-5 mb-4 rounded-sm'>
                                        <div className='flex items-center gap-sm'>
                                            <div className="avatar w-[40px] h-[40px] rounded-full overflow-hidden flex-shrink-0">
                                                <img src={"https://images.pexels.com/photos/4473796/pexels-photo-4473796.jpeg?auto=compress&cs=tinysrgb&w=1600"} alt="" className='w-[100%] h-[100%] object-cover object-center' />
                                            </div>
                                            <div className="flex items-center gap-[36px] flex-grow justify-between">
                                                <h6 className='text-14 text-dark-950 dark:text-white font-400 leading-1.5'>
                                                    You received a payment of <span className='text-theme'>$659.00</span> from John Doe
                                                </h6>
                                                <span className='text-12 font-400 text-[#737175] whitespace-nowrap'>
                                                    15h ago
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                })
                            }
                        </ul>
                    </div> */}
                </div>
            </div>
            <div className='py-6 pt-2'>
                {
                    total > itemsPerPage ? <ReactPaginate
                        pageCount={pageCount}
                        onPageChange={handlePageChange}
                        previousLinkClassName='px-2 h-[24px] bg-white dark:text-white text-black dark:bg-dark-800 text-xs font-500 !w-auto'
                        nextLinkClassName='px-2 h-[24px] bg-white dark:text-white text-black dark:bg-dark-800 text-xs font-500 !w-auto'
                        previousLabel={t('previous')}
                        nextLabel={t('next')}
                        breakLabel={'...'}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        className='pagination'
                        previousClassName={prevnext === 0 ? 'none-prev' : 'previous'}
                        nextClassName={lastPage ? 'none-next' : 'next'}
                    /> : null
                }
            </div>
            <TransactionsHistoryModal setTransactionHistoryModalOpen={setTransactionHistoryModalOpen} Transactiondata={Transactiondata} transactionHistoryModalOpen={transactionHistoryModalOpen} />
            <ExchangeTransactionsHistoryModal setTransactionHistoryModalOpen={setTransactionExchangeHistoryModalOpen} Transactiondata={ExchangeTransactiondata} transactionHistoryModalOpen={exchangetransactionHistoryModalOpen} />

        </section>
    )
}

export default withTranslation()(Notification)