import React from 'react'
import { cn } from '../../lib/utils'

const TBody = ({ children, className, ...props }) => {
    return (
        <tbody {...props} className={cn("", className)}>
            {children}
        </tbody>
    )
}

export default TBody