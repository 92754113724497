import React from 'react'

const IconTransfer1 = (props) => {
    return (
        <svg {...props} viewBox="0 0 42 42" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.53212 5.79134C7.7662 3.55726 11.1323 2.67896 15.5555 2.67896H25.9259C30.3492 2.67896 33.7153 3.55726 35.9493 5.79134C38.1834 8.02542 39.0617 11.3915 39.0617 15.8148V26.1851C39.0617 30.6084 38.1834 33.9745 35.9493 36.2085C33.7153 38.4426 30.3492 39.3209 25.9259 39.3209H15.5555C11.1323 39.3209 7.7662 38.4426 5.53212 36.2085C3.29804 33.9745 2.41974 30.6084 2.41974 26.1851V15.8148C2.41974 11.3915 3.29804 8.02542 5.53212 5.79134ZM6.99871 7.25793C5.34391 8.91274 4.49381 11.5961 4.49381 15.8148V26.1851C4.49381 30.4038 5.34391 33.0871 6.99871 34.742C8.65352 36.3968 11.3368 37.2469 15.5555 37.2469H25.9259C30.1446 37.2469 32.8279 36.3968 34.4827 34.742C36.1376 33.0871 36.9876 30.4038 36.9876 26.1851V15.8148C36.9876 11.5961 36.1376 8.91274 34.4827 7.25793C32.8279 5.60312 30.1446 4.75303 25.9259 4.75303H15.5555C11.3368 4.75303 8.65352 5.60312 6.99871 7.25793Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M20.7407 12.5309C16.027 12.5309 12.2716 16.316 12.2716 21C12.2716 21.5727 11.8073 22.037 11.2345 22.037C10.6618 22.037 10.1975 21.5727 10.1975 21C10.1975 15.1754 14.8766 10.4568 20.7407 10.4568C24.2204 10.4568 26.824 11.9119 28.5372 13.3422C28.7784 13.5436 29.0026 13.745 29.2099 13.9425V12.0296C29.2099 11.4569 29.6742 10.9926 30.2469 10.9926C30.8196 10.9926 31.2839 11.4569 31.2839 12.0296V16.7827C31.2839 17.3555 30.8196 17.8197 30.2469 17.8197H26.0296C25.4569 17.8197 24.9926 17.3555 24.9926 16.7827C24.9926 16.21 25.4569 15.7457 26.0296 15.7457H28.0844C27.8297 15.4847 27.5374 15.2095 27.2079 14.9343C25.7537 13.7202 23.6043 12.5309 20.7407 12.5309ZM30.2469 19.963C30.8196 19.963 31.2839 20.4273 31.2839 21C31.2839 26.8271 26.5678 31.5432 20.7407 31.5432C17.7534 31.5432 15.4018 30.041 13.8473 28.6295C13.6636 28.4627 13.4897 28.296 13.3259 28.1317V29.9704C13.3259 30.5431 12.8616 31.0074 12.2889 31.0074C11.7161 31.0074 11.2518 30.5431 11.2518 29.9704V25.2173C11.2518 24.6445 11.7161 24.1802 12.2889 24.1802H16.5753C17.148 24.1802 17.6123 24.6445 17.6123 25.2173C17.6123 25.79 17.148 26.2543 16.5753 26.2543H14.4006C14.6471 26.5242 14.9281 26.8093 15.2416 27.094C16.5994 28.3269 18.4737 29.4691 20.7407 29.4691C25.4223 29.4691 29.2099 25.6816 29.2099 21C29.2099 20.4273 29.6742 19.963 30.2469 19.963Z" fill="#0DF69E" />
        </svg>
    )
}

export default IconTransfer1