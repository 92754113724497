import React from 'react'

const IconSend = (props) => {
    return (
        <svg {...props} viewBox="0 0 28 28" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.5049 1.83836C13.7783 1.56499 14.2215 1.56499 14.4949 1.83836L16.8282 4.17169C17.1016 4.44506 17.1016 4.88827 16.8282 5.16164C16.5548 5.43501 16.1116 5.43501 15.8382 5.16164L14.6999 4.02328V10.5C14.6999 10.8866 14.3865 11.2 13.9999 11.2C13.6133 11.2 13.2999 10.8866 13.2999 10.5V4.02328L12.1615 5.16164C11.8882 5.43501 11.4449 5.43501 11.1716 5.16164C10.8982 4.88827 10.8982 4.44506 11.1716 4.17169L13.5049 1.83836Z" fill="#0DF69E" />
            <path fillRule="evenodd" clipRule="evenodd" d="M20.5547 14.4667L20.5568 14.4666L25.6434 14.4666C26.03 14.4666 26.3434 14.78 26.3434 15.1666C26.3434 15.5532 26.03 15.8666 25.6434 15.8666H20.5582C20.4723 15.867 20.3881 15.8911 20.315 15.9363C20.2419 15.9815 20.1828 16.0459 20.144 16.1225L20.1436 16.1233L18.7803 18.8498C18.5292 19.3549 18.1418 19.7806 17.6625 20.0779C17.1829 20.3753 16.6298 20.533 16.0655 20.5333H11.9468C11.3824 20.533 10.829 20.3753 10.3494 20.0779C9.87012 19.7807 9.48317 19.3557 9.23203 18.8507L9.23157 18.8498L7.86734 16.1214C7.78764 15.962 7.63342 15.8666 7.45511 15.8666H2.31011C1.92351 15.8666 1.61011 15.5532 1.61011 15.1666C1.61011 14.78 1.92351 14.4666 2.31011 14.4666H7.45511C8.16346 14.4666 8.80258 14.8613 9.11954 15.4953L10.4853 18.2268C10.6205 18.4989 10.829 18.7279 11.0872 18.8881C11.3455 19.0482 11.6433 19.1332 11.9471 19.1333H16.0647C16.3686 19.1332 16.6664 19.0482 16.9246 18.8881C17.1829 18.7279 17.3913 18.4989 17.5266 18.2268L17.5273 18.2253L18.8923 15.4953L18.8933 15.4933C19.0484 15.1855 19.2858 14.9266 19.579 14.7454C19.8723 14.5642 20.2099 14.4677 20.5547 14.4667Z" fill="currentcolor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.8592 5.88327C8.91539 6.26577 8.65086 6.62139 8.26837 6.67757C6.32581 6.96292 5.06703 7.65566 4.26881 8.76156C3.45207 9.89311 3.0333 11.5708 3.0333 14V17.5C3.0333 20.3476 3.60711 22.1589 4.72411 23.2759C5.8411 24.3929 7.65234 24.9667 10.5 24.9667H17.5C20.3476 24.9667 22.1588 24.3929 23.2758 23.2759C24.3928 22.1589 24.9666 20.3476 24.9666 17.5V14C24.9666 11.5708 24.5479 9.89311 23.7311 8.76156C22.9329 7.65566 21.6741 6.96292 19.7316 6.67757C19.3491 6.62139 19.0845 6.26577 19.1407 5.88327C19.1969 5.50078 19.5525 5.23625 19.935 5.29244C22.1225 5.61376 23.7804 6.43768 24.8663 7.9422C25.9337 9.42107 26.3666 11.4476 26.3666 14V17.5C26.3666 20.4857 25.7738 22.7578 24.2658 24.2658C22.7578 25.7738 20.4857 26.3667 17.5 26.3667H10.5C7.51426 26.3667 5.24216 25.7738 3.73416 24.2658C2.22616 22.7578 1.6333 20.4857 1.6333 17.5V14C1.6333 11.4476 2.0662 9.42107 3.13363 7.9422C4.21957 6.43768 5.87746 5.61376 8.0649 5.29244C8.4474 5.23625 8.80302 5.50078 8.8592 5.88327Z" fill="currentcolor" />
        </svg>
    )
}

export default IconSend