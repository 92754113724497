import React, { useEffect, useState } from 'react'
import Table from './Table'
import TBody from './TBody'
import TR from './TR'
import TD from './TD'
import Input from '../ui/Input'
import useStore from '../../contexts/AuthProvider'
import { useFormik } from 'formik'
import * as Yup from "yup";
import { withTranslation } from 'react-i18next';

const DepositConfirmationTable = ({ data, noteData, setlfname, lfname,t }) => {
    const userData = useStore((state) => state.user_data)
    useEffect(() => {
        formik.setValues({
            ...formik.values,
            fName: userData?.fName || '',
            lName: userData?.lName || '',
        });
    }, [userData]);

    const bankHolderNameSchema = Yup.object().shape({
        fName: Yup.string()
            .min(3, t('error.firstNameShouldBeMinimum3Character'))
            .max(20, t('error.youCanNotEnterMoreThan20Character'))
            .required(t('error.errorPleaseEnterFirstName'))
            .matches(/^\S*$/g, t('error.errorBlackSpaceIsNotAllow'))
            .matches(/^\D*$/, t('error.errorFirstNameCannotContainDigit')),
        lName: Yup.string()
            .min(3, t('error.lastNameShouldBeMinimum3Character'))
            .max(20, t('error.youCanNotEnterMoreThan20Character'))
            .required(t('error.errorPleaseEnterLastName'))
            .matches(/^\S*$/g, t('error.errorBlackSpaceIsNotAllow'))
            .matches(/^\D*$/, t('error.errorLastNameCannotContainDigit')),
    });

    const formik = useFormik({
        validationSchema: bankHolderNameSchema
    });

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue, setFieldError } = formik;


    useEffect(() => {
        const first_name = getFieldProps("fName")
        const last_name = getFieldProps("lName")
        setlfname({ ...lfname, fname: first_name?.value, lname: last_name?.value })
    }, [getFieldProps])

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-md lg:gap-lg items-[unset]">
                <div className="">
                    <Input id="fName" lable={t('label.labelFirstName')} placeholder={t('placeholder.hintEnterYourFirstname')} {...getFieldProps("fName")} errorMsg={errors.fName && touched.fName && errors.fName} />
                </div>
                <div className="">
                    <Input id="lName" lable={t('label.labelLastName')} placeholder={t('placeholder.hintEnterYourLastName')} {...getFieldProps("lName")} errorMsg={errors.lName && touched.lName && errors.lName} />
                </div>
            </div>
            <p className='my-4 after:text-20 font-600 text-red-500 dark:text-red text-center'>{noteData}</p>
            <Table className={"border-spacing-y-[20px]"}>
                <TBody>
                    <TR>
                        <TD className={"p-0 last:pr-0 last:text-end"}>
                            <span className='text-14 font-400 text-dark-500 dark:text-dark-300'>{t('label.labelFullName')}</span>
                        </TD>
                        <TD className={"p-0 last:pr-0 last:text-end"}>
                            <span className='text-dark-950 dark:text-white text-14 font-500'>{data?.full_name}</span>
                        </TD>
                    </TR>
                    <TR>
                        <TD className={"p-0 last:pr-0 last:text-end"}>
                            <span className='text-14 font-400 text-dark-500 dark:text-dark-300'>{t('label.labelBankName')}</span>
                        </TD>
                        <TD className={"p-0 last:pr-0 last:text-end"}>
                            <span className='text-dark-950 dark:text-white text-14 font-500'>{data?.bank_name}</span>
                        </TD>
                    </TR>
                    <TR>
                        <TD className={"p-0 last:pr-0 last:text-end"}>
                            <span className='text-14 font-400 text-dark-500 dark:text-dark-300'>{t('label.labelIbanNumber')}</span>
                        </TD>
                        <TD className={"p-0 last:pr-0 last:text-end"}>
                            <span className='text-dark-950 dark:text-white text-14 font-500'>{data?.iban_number}</span>
                        </TD>
                    </TR>
                    <TR>
                        <TD className={"p-0 last:pr-0 last:text-end"}>
                            <span className='text-14 font-400 text-dark-500 dark:text-dark-300'>{t('label.labelDepositAmount')}</span>
                        </TD>
                        <TD className={"p-0 last:pr-0 last:text-end"}>
                            <span className='text-dark-950 dark:text-white text-14 font-500'>{data?.symbol}{data?.deposit_amount?.toFixed(2)}</span>
                        </TD>
                    </TR>
                    {data?.admin_fees !== 0 &&
                        <>
                            <TR>
                                <TD className={"p-0 last:pr-0 last:text-end"}>
                                    <span className='text-14 font-400 text-dark-500 dark:text-dark-300'>{t('label.labelAdminFees')}</span>
                                </TD>
                                <TD className={"p-0 last:pr-0 last:text-end"}>
                                    <span className='text-dark-950 dark:text-white text-14 font-500'>{data?.symbol}{data?.admin_fees?.toFixed(2)}</span>
                                </TD>
                            </TR>
                            <TR>
                                <TD className={"p-0 last:pr-0 last:text-end"}>
                                    <span className='text-14 font-400 text-dark-500 dark:text-dark-300'>{t('netAmount')}</span>
                                </TD>
                                <TD className={"p-0 last:pr-0 last:text-end"}>
                                    <span className='text-dark-950 dark:text-white text-14 font-500'>{data?.symbol}{data?.net_deposit_amount?.toFixed(2)}</span>
                                </TD>
                            </TR>
                        </>
                    }
                    <TR>
                        <TD className={"p-0 last:pr-0 last:text-end"}>
                            <span className='text-14 font-400 text-dark-500 dark:text-dark-300'>{t('balanceAfterDeposit')}</span>
                        </TD>
                        <TD className={"p-0 last:pr-0 last:text-end"}>
                            <span className='text-dark-950 dark:text-white text-14 font-500'>{data?.symbol}{data?.balance_after_tra?.toFixed(2)}</span>
                        </TD>
                    </TR>
                </TBody>
            </Table>
        </>
    )
}

export default withTranslation()(DepositConfirmationTable)