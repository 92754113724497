import React from "react";
import { Link } from "react-router-dom";


const EmptyScreenCompo = ({ title, contant, icon, btnlink, btncontant, classname }) => {
    return <>
        <div className="flex justify-center">
            <div className="">
                <div className={`mx-auto w-20 h-20 rounded-full border border-black dark:border-white flex items-center justify-center ${classname}`}>{icon}</div>
                {
                    title ? <h5 className="text-center my-2 dark:!text-white !text-black text-18">{title}</h5> :
                        <></>
                }
                {
                    contant ? <p className="max-w-[350px] text-center dark:!text-white !text-black">{contant}</p> : <></>
                }
                {
                    btncontant ? <div className="text-center py-5">
                        <Link to={btnlink} className="text-theme">{btncontant}</Link>
                    </div> : <></>
                }
            </div>
        </div >
    </>
}

export default EmptyScreenCompo;