import React from 'react'

const IconMail = (props) => {
    return (
        <svg {...props} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.8359 5.34375H3.16406C1.42298 5.34375 0 6.76048 0 8.50781V27.4922C0 29.2402 1.4239 30.6562 3.16406 30.6562H32.8359C34.577 30.6562 36 29.2395 36 27.4922V8.50781C36 6.76005 34.5763 5.34375 32.8359 5.34375ZM32.3501 7.45312L18.8173 21.03C18.405 21.4436 17.5952 21.4439 17.1827 21.03L3.64992 7.45312H32.3501ZM2.10938 27.1044V8.89559L11.1843 18L2.10938 27.1044ZM3.64992 28.5469L12.6734 19.494L15.6888 22.5192C16.9244 23.7589 19.0761 23.7584 20.3113 22.5192L23.3267 19.4941L32.3501 28.5469H3.64992ZM33.8906 27.1044L24.8157 18L33.8906 8.89559V27.1044Z" fill="currentcolor" />
        </svg>
    )
}

export default IconMail
