import IconBNB from "../components/svg/IconBNB";
import IconBitcoin from "../components/svg/IconBitcoin";
import IconETH from "../components/svg/IconETH";
import IconTransfer1 from "../components/svg/IconTransfer1";
import IconUSDT from "../components/svg/IconUSDT";
import IconWithdraw1 from "../components/svg/IconWithdraw1";
import IconXRP from "../components/svg/IconXRP";

const { default: IconDeposite } = require("../components/svg/IconDeposite");
const { default: IconExchange } = require("../components/svg/IconExchange");
const { default: IconPaymentLink } = require("../components/svg/IconPaymentLink");
const { default: IconSend } = require("../components/svg/IconSend");
const { default: IconTransfer } = require("../components/svg/IconTransfer");
const { default: IconWithdraw } = require("../components/svg/IconWithdraw");

export const modules = [
    {
        text: "Send",
        icon: <IconSend className="w-7 h-7 dark:text-white text-dark-950" />,
        value: "SendModal"
    },
    /* {
        text: "Exchange",
        icon: <IconExchange className="w-7 h-7 dark:text-white text-dark-950" />,
        value: "TransferModal"
    }, */
    {
        text: "Deposite",
        icon: <IconDeposite className="w-7 h-7 dark:text-white text-dark-950" />
    },
    {
        text: "Withdraw",
        icon: <IconWithdraw className="w-7 h-7 dark:text-white text-dark-950" />
    },
    /* {
        text: "Transfer",
        icon: <IconTransfer className="w-7 h-7 dark:text-white text-dark-950" />
    }, */
    {
        text: "Payment link",
        icon: <IconPaymentLink className="w-7 h-7 dark:text-white text-dark-950" />
    }
]

export const transactiondataLimited = [
    {
        name: "Alice Johnson",
        time: "10:15 AM",
        date: "12 February 2023",
        amount: "$75.50",
        type: 'credit'
    },
    {
        name: "Bob Smith",
        time: "03:45 PM",
        date: "18 February 2023",
        amount: "$120.75",
        type: 'debit'
    },
    {
        name: "Charlie Brown",
        time: "11:30 AM",
        date: "25 February 2023",
        amount: "$50.25",
        type: 'credit'
    },
    {
        name: "David Miller",
        time: "09:20 AM",
        date: "5 March 2023",
        amount: "$90.60",
        type: 'debit'
    },
    {
        name: "Ella Davis",
        time: "02:10 PM",
        date: "15 March 2023",
        amount: "$60.45",
        type: 'credit'
    },
    {
        name: "Franklin Wilson",
        time: "08:55 AM",
        date: "22 March 2023",
        amount: "$35.20",
        type: 'debit'
    },
    {
        name: "Grace Adams",
        time: "04:30 PM",
        date: "2 April 2023",
        amount: "$85.75",
        type: 'credit'
    },
    {
        name: "Hannah Brown",
        time: "01:25 PM",
        date: "9 April 2023",
        amount: "$40.30",
        type: 'debit'
    },
    {
        name: "Isaac Jones",
        time: "10:40 AM",
        date: "17 April 2023",
        amount: "$95.20",
        type: 'credit'
    },
    {
        name: "Jack White",
        time: "07:15 AM",
        date: "25 April 2023",
        amount: "$55.90",
        type: 'debit'
    }
];

export const transactiondata = [
    {
        name: "suyog bora",
        time: "02:25 PM",
        date: "26 March 2022",
        amount: "$63.00",
        id: "#15241525365",
        type: 'credit'
    },
    {
        name: "suyog bora",
        time: "02:25 PM",
        date: "26 March 2022",
        amount: "$63.00",
        id: "#15241525365",
        type: 'debit'
    },
    {
        name: "suyog bora",
        time: "02:25 PM",
        date: "26 March 2022",
        amount: "$63.00",
        id: "#15241525365",
        type: 'credit'
    },
    {
        name: "suyog bora",
        time: "02:25 PM",
        date: "26 March 2022",
        amount: "$63.00",
        id: "#15241525365",
        type: 'debit'
    },
    {
        name: "suyog bora",
        time: "02:25 PM",
        date: "26 March 2022",
        amount: "$63.00",
        id: "#15241525365",
        type: 'credit'
    },
    {
        name: "suyog bora",
        time: "02:25 PM",
        date: "26 March 2022",
        amount: "$63.00",
        id: "#15241525365",
        type: 'debit'
    },
    {
        name: "suyog bora",
        time: "02:25 PM",
        date: "26 March 2022",
        amount: "$63.00",
        id: "#15241525365",
        type: 'credit'
    },
    {
        name: "suyog bora",
        time: "02:25 PM",
        date: "26 March 2022",
        amount: "$63.00",
        id: "#15241525365",
        type: 'debit'
    },
    {
        name: "suyog bora",
        time: "02:25 PM",
        date: "26 March 2022",
        amount: "$63.00",
        id: "#15241525365",
        type: 'credit'
    },
    {
        name: "suyog bora",
        time: "02:25 PM",
        date: "26 March 2022",
        amount: "$63.00",
        id: "#15241525365",
        type: 'debit'
    },
    {
        name: "suyog bora",
        time: "02:25 PM",
        date: "26 March 2022",
        amount: "$63.00",
        id: "#15241525365",
        type: 'credit'
    },
    {
        name: "suyog bora",
        time: "02:25 PM",
        date: "26 March 2022",
        amount: "$63.00",
        id: "#15241525365",
        type: 'debit'
    }
]

// export const sortingdata = [
//     { name: "Weekly", id: 1 },
//     { name: "Monthly", id: 2 },
//     { name: "Yearly", id: 3 }]

// export const sortingdata = [ "Weekly","Monthly","Yearly"]
export const sortingdata = [
    { name: "label.labelweekly", value: "Weekly" },
    { name: "label.labelmonthly", value: "Monthly" },
    { name: "label.labelyearly", value: "Yearly" }]

export const tickets = [
    {
        issue: "Having issue in reset password",
        details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
        date: "5 Sep 23",
        status: "pending"
    },
    {
        issue: "Cannot access the dashboard",
        details: "I am unable to access the dashboard. When I try to log in, I get an error message.",
        date: "10 Sep 23",
        status: "open"
    },
    {
        issue: "Billing inquiry",
        details: "I have a question about my billing statement for this month. Can someone please assist me with this?",
        date: "12 Sep 23",
        status: "closed"
    },
    {
        issue: "Website performance is slow",
        details: "The website performance has been quite slow for the past few days. It takes a long time to load pages.",
        date: "15 Sep 23",
        status: "in progress"
    },
    {
        issue: "Mobile app crashes on startup",
        details: "Every time I try to open the mobile app, it crashes immediately. I need help to resolve this issue.",
        date: "18 Sep 23",
        status: "pending"
    }
];


export const cryptos = [
    {
        short: "BTC",
        full: "Bitcoin",
        icon: <IconBitcoin className="w-6 h-6 text-theme" />
    },
    {
        short: "BNB",
        full: "Binance",
        icon: <IconBNB className="w-6 h-6 text-theme" />
    },
    {
        short: "ETH",
        full: "Ethereum",
        icon: <IconETH className="w-6 h-6 text-theme" />
    },
    {
        short: "XRP",
        full: "XRP",
        icon: <IconXRP className="w-6 h-6 text-theme" />
    },
    {
        short: "USDT",
        full: "ERC 20",
        icon: <IconUSDT className="w-6 h-6 text-theme" />
    }
]


export const banks = [
    {
        bankname: 'Bank of America',
        accountnumber: '***  **** **** 5896',
    },
    {
        bankname: 'Swiss Bank',
        accountnumber: '***  **** **** 2456',
    },
    {
        bankname: 'Indian Bank',
        accountnumber: '***  **** **** 2456',
    }
]

export const banksnames = [
    "Bank of Baroda",
    "State Bank of india"
]

export const transactionType = [
    { id: "label.labelSend", type: 1 },
    { id: "label.labelReceive", type: 2 },
    // { id: "Exchange" },
    { id: "label.labelDeposit", type: 3 },
    { id: "label.labelWithdraw", type: 4 },
    // { id: "Transfer", type: 5 },
]

export const amount = ["$10", '20$', "$50", "$100"];

export const cards = [
    {
        cardname: 'Visa',
        cardid: ' ***  **** **** 5896',
    },
    {
        cardname: 'Master',
        cardid: '***  **** **** 2456',
    }
]

export const paymentmethod = [
    "My Wallet",
    "Credit Card",
    "Crypto"
]

export const IdentityType = ["ID Card", "Passport", "Driving License"]

export const plans = [
    {
        name: 'Startup',
        ram: '12GB',
        cpus: '6 CPUs',
        disk: '160 GB SSD disk',
    },
    {
        name: 'Business',
        ram: '16GB',
        cpus: '8 CPUs',
        disk: '512 GB SSD disk',
    },
    {
        name: 'Enterprise',
        ram: '32GB',
        cpus: '12 CPUs',
        disk: '1024 GB SSD disk',
    },
]

export const currencies = [
    'USD',
    'GBP',
    'EUR',
    'TRY'
]

export const timeFrame = [
    "10  Minutes",
    "15  Minutes",
    "20  Minutes",
    "20  Minutes",
    "30  Minutes"
]

export const keyfeatureoppi = [
    {
        icon: <IconSend className={"w-8 h-8"} />,
        heading: "keyFeature.sendMoney",
        msg: "keyFeature.sendMoneyDes"
    },
    {
        icon: <IconWithdraw1 className={"w-8 h-8"} />,
        heading: "keyFeature.receiveMoney",
        msg: "keyFeature.receiveMoneyDes"
    },
    {
        icon: <IconTransfer1 className={"w-8 h-8"} />,
        heading: "keyFeature.exchangeMoney",
        msg: "keyFeature.exchangeMoneyDes"
    },
    {
        icon: <IconDeposite className={"w-8 h-8"} />,
        heading: "keyFeature.depositMoney",
        msg: "keyFeature.depositMoneyDes"
    },
    {
        icon: <IconWithdraw className={"w-8 h-8"} />,
        heading: "keyFeature.withdrawMoney",
        msg: "keyFeature.withdrawMoneyDes"
    },
    /* {
        icon: <IconTransfer className={"w-8 h-8"} />,
        heading: "Transfer Money",
        msg: "Lorem Ipsum is simply dummy text of the printing and typesetting industry"
    }, */
]