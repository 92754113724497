import React from 'react'
import Table from './Table'
import TBody from './TBody'
import TR from './TR'
import TD from './TD'
import moment from "moment";
import { withTranslation } from 'react-i18next';

const TransactionDetailsTextTable = ({ transactionDetail, paymentStatus,t }) => {
    const total_payable = transactionDetail?.plan + transactionDetail?.admin_fees
    return (
        <Table className={"border-spacing-y-[20px]"}>
            <TBody>
                <TR>
                    <TD className={"p-0 last:pr-0 align-top last:text-end"}>
                        <span className='text-14 font-400 text-dark-500 dark:text-dark-300 whitespace-nowrap'>{t('label.labelSendAmount')}</span>
                    </TD>
                    <TD className={"p-0 last:pr-0 last:text-end"}>
                        <span className={`${paymentStatus === 1 ? "text-theme" : paymentStatus === 2 ? "text-red-600" : "text-yellow"} text-14 font-500`}>{transactionDetail?.symbol}{transactionDetail?.plan?.toFixed(2) ?? 0.00}</span>
                    </TD>
                </TR>
                {
                    transactionDetail?.admin_fees !== 0 &&
                    <>
                        <TR>
                            <TD className={"p-0 last:pr-0 align-top last:text-end"}>
                                <span className='text-14 font-400 text-dark-500 dark:text-dark-300 whitespace-nowrap'>{t('label.labelAdminFees')}</span>
                            </TD>
                            <TD className={"p-0 last:pr-0 last:text-end"}>
                                <span className='text-dark-950 dark:text-white text-14 font-500'>{transactionDetail?.symbol}{transactionDetail?.admin_fees?.toFixed(2) ?? 0.00}</span>
                            </TD>
                        </TR>
                        <TR>
                            <TD className={"p-0 last:pr-0 align-top last:text-end"}>
                                <span className='text-14 font-400 text-dark-500 dark:text-dark-300 whitespace-nowrap'>{t('label.labelTotalPayable')}</span>
                            </TD>
                            <TD className={"p-0 last:pr-0 last:text-end"}>
                                <span className=' text-theme text-14 font-500'>{transactionDetail?.symbol}{total_payable?.toFixed(2) ?? 0.00}</span>
                            </TD>
                        </TR>
                    </>
                }
                <TR>
                    <TD className={"p-0 last:pr-0 align-top last:text-end"}>
                        <span className='text-14 font-400 text-dark-500 dark:text-dark-300 whitespace-nowrap'>{t('dateTime')}</span>
                    </TD>
                    <TD className={"p-0 last:pr-0 last:text-end"}>
                        <span className='text-dark-950 dark:text-white text-14 font-500'>{moment(transactionDetail.created_at).format('DD MMM YYYY, h:mma')}</span>
                    </TD>
                </TR>
                <TR>
                    <TD className={"p-0 last:pr-0 align-top last:text-end"}>
                        <span className='text-14 font-400 text-dark-500 dark:text-dark-300 whitespace-nowrap'>{t('label.labelTransactionId')}</span>
                    </TD>
                    <TD className={"p-0 last:pr-0 last:text-end"}>
                        <span className='text-dark-950 dark:text-white text-14 font-500'>{transactionDetail?.transaction_id}</span>
                    </TD>
                </TR>
            </TBody>
        </Table>
    )
}

export default withTranslation()(TransactionDetailsTextTable)
