import React from 'react'

const IconClose = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.40988 4.40988C4.95639 3.86337 5.84245 3.86337 6.38895 4.40988L12 10.0209L17.611 4.40988C18.1575 3.86337 19.0436 3.86337 19.5901 4.40988C20.1366 4.95639 20.1366 5.84245 19.5901 6.38895L13.9791 12L19.5901 17.611C20.1366 18.1575 20.1366 19.0436 19.5901 19.5901C19.0436 20.1366 18.1575 20.1366 17.611 19.5901L12 13.9791L6.38895 19.5901C5.84245 20.1366 4.95639 20.1366 4.40988 19.5901C3.86337 19.0436 3.86337 18.1575 4.40988 17.611L10.0209 12L4.40988 6.38895C3.86337 5.84245 3.86337 4.95639 4.40988 4.40988Z" fill="currentcolor" />
        </svg>
    )
}

export default IconClose