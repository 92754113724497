import React, { useEffect, useState } from 'react'
import Input from "../../../../ui/Input"
import CurrencyAmountDetailBox from '../../../CurrencyAmountDetailsBox'
import TextArea from '../../../../ui/TextArea'
import CustomRadio from '../../../../ui/CustomRadio'
import { Button } from '../../../../ui/Button'
import {/*  CURRENCY, */ TRANSACTION_URL } from "../../../../../api/Path";
import Http from '../../../../../api/Http'
import useStore from '../../../../../contexts/AuthProvider'
import { Toaster } from '../../common/Toaster'
import { /* Form, FormikProvider, */ useFormik } from 'formik'
import * as Yup from "yup";
import ConfirmationModal from '../../common/ConfirmationModal'
import { useQueryClient } from 'react-query'
import { cn } from '../../../../../lib/utils'
import VerificationModal from '../../common/VerificationModal'
import { useDebouncedCallback } from 'use-debounce';
import IconMail from '../../../../svg/IconMail'
import IconCall from '../../../../svg/IconCall'
import IconUser from '../../../../svg/IconUser'
import { withTranslation } from 'react-i18next';

const SendModalStepFirst = ({/* amount , */linkdata, setStep, setLoading, setTransactionDetail, setPaymentStatus, t }) => {
    const default_currency = useStore((state) => state.user_data.default_currency);
    const verifyFlags = useStore((state) => state?.verify_flag);

    const [amount, setAmount] = useState();
    const [selected, setSelected] = useState();
    const [balance, setBalance] = useState();
    const [allWalletBalance, setAllWalletBalance] = useState();
    const [minMaxAmount, setMinMaxAmount] = useState({});
    const [plan, setPlan] = useState(0);
    const [balanceAfterTra, setBalanceAfterTra] = useState(0);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [tranDetail, setTranDetail] = useState({});
    const [cusErrorMsg, setCusErrorMsg] = useState();
    const [sendToMsg, setSendToMsg] = useState();
    const [disable, setDisable] = useState(true);
    const queryClient = useQueryClient();
    const [verificationModalOpen, setVerificationModalOpen] = useState(false);
    const [linkDisablebtn, setLinkDisableBtn] = useState(false);
    const [tranFeePer, setTranFeePer] = useState({});
    const [userInfo, setUserInfo] = useState({});
    // fetch currency name
    // async function fetchCurrencyList() {
    //     try {
    //         const response = await Http.get(CURRENCY.list);
    //         if (response) {
    //             setCurrencyList(response.data);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching currency lists:', error);
    //     }
    // }

    async function getWalletBalance() {
        const response = await Http.get(TRANSACTION_URL.getAllWalletBalance + `?type=${0}`);
        if (response) {
            setLoading(false);
            const index = response.data.findIndex(data => data.code === default_currency);
            if (index !== -1) {
                const val = response.data.splice(index, 1)[0];
                response.data.unshift(val);
            }
            setAllWalletBalance(response.data)
        }
    }

    useEffect(() => {
        setLoading(true);
        // fetchCurrencyList();
        getWalletBalance();
    }, []);

    useEffect(() => {
        tranDetail.balance_after_tra = balanceAfterTra
        // setTranDetail({ ...tranDetail, balance_after_tra: balanceAfterTra })
    }, [balanceAfterTra])

    useEffect(() => {
        if (allWalletBalance) {
            const filterSelectedCurrency = allWalletBalance.filter(data => data.code === selected?.code)
            setMinMaxAmount({ ...minMaxAmount, minimum_amount: filterSelectedCurrency[0]?.minimum_amount, maximum_amount: filterSelectedCurrency[0]?.maximum_amount })
            setBalance(filterSelectedCurrency[0]?.balance)
            setBalanceAfterTra(filterSelectedCurrency[0]?.balance)
            setTranFeePer({
                fees_percentage: filterSelectedCurrency[0]?.fees_percentage,
                minimum_fees: filterSelectedCurrency[0]?.minimum_fees,
            })
            setAmount(filterSelectedCurrency[0]?.amount)
        }
    }, [allWalletBalance, selected]);

    useEffect(() => {
        if (allWalletBalance) {
            if (linkdata?.currency?.code === undefined) {
                // setSelected(allWalletBalance?.[0]);
                allWalletBalance?.map((data) => {
                    (data?.code === default_currency) && setSelected(data)
                })
            } else if (linkdata?.currency?.code != null) {
                allWalletBalance?.map((data) => {
                    if (data?.code === linkdata?.currency?.code) {
                        setSelected(data);
                    }
                    /* else {
                        if (data?.code) {
                            setSelected(data);
                            selectedFound = true;
                        }
                    } */
                });
            }
        }
    }, [allWalletBalance, linkdata?.currency?.code])

    // get amount on change currency
    // const handleCurrencyChange = async (newCurrency) => {
    //     try {
    //         const response = await Http.get(`${CURRENCY.get_amount}?id=${newCurrency}`);
    //         if (response) {
    //             setAmount(response.data);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching currency lists:', error);
    //     }
    // };

    useEffect(() => {
        if (selected) {
            // setLoading(true);
            // handleCurrencyChange(selected._id);
            setTranDetail({ ...tranDetail, currency_code: selected?.symbol })
            setPlan(0);
            setCusErrorMsg('')
        }
    }, [selected])
    useEffect(() => {
        if (linkdata) {
            setLinkDisableBtn(true)
            setDisable(false)
            setPlan(linkdata?.amount)
            setFieldValue('receiver', linkdata?.reciver_username)
            setFieldValue('note', linkdata?.note)
            setTranDetail({ ...tranDetail, currency_code: linkdata?.currency?.symbol })

        }
    }, [linkdata])

    const handleOnChangeAmount = (inputVal) => {
        // console.log({total_amount});
        // const inputVal = parseFloat(e.target.value)
        if (inputVal) {
            if (balance === parseFloat(inputVal) && tranFeePer?.fees_percentage !== 0) {
                setDisable(true)
                let amount = balance - tranDetail?.fee
                setCusErrorMsg('You can send only ' + selected?.symbol + amount?.toFixed(2));
                setBalanceAfterTra(balance)
                setPlan(inputVal)
            } else if (balance >= inputVal) {
                if (minMaxAmount?.minimum_amount > inputVal) {
                    setDisable(true)
                    setCusErrorMsg(t('error.minimumAmountYouCanSend') + ' ' + minMaxAmount?.minimum_amount.toFixed(2));
                    setBalanceAfterTra(balance)
                } else if (minMaxAmount?.maximum_amount < inputVal) {
                    setDisable(true)
                    setCusErrorMsg(t('error.maximumAmountYouCanSend') + ' ' + minMaxAmount?.maximum_amount.toFixed(2));
                    setBalanceAfterTra(balance)
                } else {
                    setDisable(false)
                    setCusErrorMsg('')
                    // setBalanceAfterTra(balance - inputVal)
                }
                setPlan(inputVal)
                if (!inputVal) {
                    setBalanceAfterTra(balance)
                }
            } else {
                setBalanceAfterTra(balance)
                setCusErrorMsg(t('error.insufficientBalance'));
                setDisable(true)
            }
        } else {
            setDisable(true)
            setBalanceAfterTra(balance)
            setPlan(inputVal)
            setCusErrorMsg('')
        }
    }

    useEffect((e) => {
        if (plan !== 0) {
            let total_amount = plan + (tranDetail.fee ?? 0);
            if (balance === parseFloat(plan) && tranFeePer?.fees_percentage !== 0) {
                setDisable(true)
                setCusErrorMsg(t('amountSendNotAllowed') +' ' + selected?.symbol + tranDetail?.fee?.toFixed(2));
                setBalanceAfterTra(balance)
                setPlan(plan)
            } else if (balance >= total_amount) {
                setPlan(plan);
                if (minMaxAmount?.minimum_amount <= plan && minMaxAmount?.maximum_amount >= plan && balance !== parseFloat(plan)) {
                    // setBalanceAfterTra(balance - plan - tranDetail?.fee);
                    setCusErrorMsg('');
                    setDisable(false);
                }
            } else if (balance < plan) {
                setPlan(plan);
                setCusErrorMsg(t('error.insufficientBalance'));
                setDisable(true)
            } else if (balance < total_amount) {
                setDisable(true)
                setCusErrorMsg(t('amountSendNotAllowed')+ '' + selected?.symbol + tranDetail?.fee?.toFixed(2));
                setBalanceAfterTra(balance)
                setPlan(plan)
            }
            // setTranDetail({ ...tranDetail, send_amount: parseFloat(plan) })
        }

        // handleOnChangeAmount(tranDetail?.send_amount, tranDetail?.totalAmount);
    }, [plan, tranDetail])

    useEffect(() => {
        // if (cusErrorMsg && parseFloat(plan) !== balance) {
        //     setTranDetail({ ...tranDetail, fee: 0 })
        // } else
        if (tranFeePer) {
            const feePer = (tranFeePer.fees_percentage / 100) * plan;
            if (feePer) {
                if (plan <= tranFeePer.minimum_fees) {
                    setTranDetail({
                        ...tranDetail,
                        fee: feePer,
                        totalAmount: parseFloat(plan) + feePer,
                        send_amount: parseFloat(plan)
                    })
                    if (balance !== parseFloat(plan) && cusErrorMsg === '') {
                        const t = parseFloat(plan) + feePer;
                        let balanceAfterTran = balance - t;
                        if (balanceAfterTran >= 0) {
                            setBalanceAfterTra(balanceAfterTran)
                        } else {
                            setBalanceAfterTra(balance)
                        }
                    }
                } else {
                    const maxFee = Math.max(feePer, tranFeePer.minimum_fees);
                    setTranDetail({
                        ...tranDetail,
                        fee: maxFee,
                        totalAmount: parseFloat(plan) + maxFee,
                        send_amount: parseFloat(plan)
                    })
                    if (balance !== parseFloat(plan) && cusErrorMsg === '') {
                        const t = parseFloat(plan) + maxFee;
                        let balanceAfterTran = balance - t;
                        if (balanceAfterTran >= 0) {
                            setBalanceAfterTra(balanceAfterTran)
                        } else {
                            setBalanceAfterTra(balance)
                        }
                    }
                }
            } else if (feePer === 0) {
                let balanceAfterTran = balance - parseFloat(plan);
                setBalanceAfterTra(balanceAfterTran)
                setTranDetail({
                    ...tranDetail,
                    fee: 0,
                    totalAmount: parseFloat(plan),
                    send_amount: parseFloat(plan)
                })
            }
        }
    }, [tranFeePer, plan])

    const SignUpByMailSchema = Yup.object().shape({
        receiver: Yup.string()
            .required(t('error.errorNameOrPhoneNumberOrEmail'))
    });

    const formik = useFormik({
        initialValues: {
            receiver: "",
            note: ""
        },
        validationSchema: SignUpByMailSchema,
        onSubmit: async (values) => {
            setLoading2(true);
            const Obj = {
                currency: selected?.code,
                amount: parseFloat(plan),
                receiver: values.receiver,
                note: values.note
            }
            try {
                const response = await Http.post(TRANSACTION_URL.sendPayment, Obj);
                if (response) {
                    if (response.status === 200) {
                        setPaymentStatus(response.data.status)
                        const receiver_user = response?.data?.receiver_data;
                        const transaction_id = response?.data?.transaction_id;
                        setLoading2(false);
                        setTransactionDetail({ plan: parseFloat(plan), symbol: selected?.symbol, receiver_user: receiver_user, transaction_id: transaction_id, created_at: response?.data?.created_at, send_amount: response?.data?.send_amount, admin_fees: tranDetail?.fee })
                        setStep(2);
                        queryClient.invalidateQueries(["getTransactionHistory"])
                        queryClient.invalidateQueries(["getTransaction"])
                        queryClient.invalidateQueries(["getFetTotal"])
                    } else {
                        Toaster(response?.response?.data?.message, "error")
                        setLoading2(false);
                        setConfirmationModalOpen(false);
                        setVerificationModalOpen(false);
                    }
                } else {
                    Toaster(t('error.errorOopsSomethingWentWrong'), "error")
                }
            } catch (error) {
                console.log({ error })
                setLoading2(false);
                setConfirmationModalOpen(false);
                setVerificationModalOpen(false);
                const errorData = error.response.data.errors;
                Object.keys(errorData).forEach((key) => {
                    if (key === "receiver") {
                        setFieldError("receiver", errorData[key]);
                    } else if (key === "note") {
                        setFieldError(key, errorData[key]);
                    }
                });
            }
        },
    });

    const handleFinalSend = () => {
        if ((!getFieldProps('receiver').value)) {
            setSendToMsg(t('error.errorNameOrPhoneNumberOrEmail'))
        } else if (sendToMsg == '') {
            setConfirmationModalOpen(true)
        }
    }

    const { errors, touched, handleSubmit, getFieldProps, setFieldError, setFieldValue } = formik;

    const handleSend = async () => {
        try {
            const receiver = getFieldProps("receiver");
            if (receiver?.value) {
                setLoading(true);
                const response = await Http.post(TRANSACTION_URL.checkUsernamePhone, { receiver: receiver.value });
                if (response) {
                    if (response.status === 200) {
                        setUserInfo(response?.data?.user_info)
                        setSendToMsg('')
                        setLoading(false);
                    } else {
                        setSendToMsg(response?.response?.data?.message)
                        setLoading(false);
                        setLoading2(false);
                        // setConfirmationModalOpen(false);
                        setVerificationModalOpen(false);
                        setUserInfo({})
                    }
                }
            } else {
                setLoading(false)
                setSendToMsg('')
            }
        } catch (error) {
            console.log({ error })
            setSendToMsg('')
            setUserInfo({})
            setLoading(false);
            setLoading2(false);
        }
    }

    const handleModalCLick = () => {
        setVerificationModalOpen(true)
        setConfirmationModalOpen(false)
    }

    const receiver_value = getFieldProps("receiver");
    const [changeValue, setChangeValue] = useState();

    const debounced = useDebouncedCallback(
        // function
        (value) => {
            if (value.value != changeValue) {
                handleSend();
            } else if (!value.value) {
                setUserInfo({})
            }
            setChangeValue(value.value)
        },
        // delay in ms
        1000
    );

    useEffect(() => {
        debounced(receiver_value)
        return () => {
            debounced.cancel();
        };
    }, [receiver_value])

    return (
        <>
            <div className="">
                {/* <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}> */}
                <div className="form-item mb-6">
                    <label htmlFor="" className='text-dark-950 dark:text-dark-300  text-14 mb-4 block'>
                        {t('sendTo')}
                    </label>
                    <Input {...getFieldProps("receiver")}
                        disabled={linkDisablebtn} placeholder={t('placeholder.hintSearchNameOrPhoneNumberOrEmail')} className="dark:bg-dark-600 bg-light text-dark-950 dark:!text-white font-500 text-16" /* errorMsg={errors.receiver && touched.receiver && errors.receiver} */ />
                    {sendToMsg && <span className={cn('!text-12 text-red-500 font-normal mt-1')}>{sendToMsg}</span>}
                </div>
                {
                    Object.keys(userInfo)?.length !== 0 ?
                        <div className="form-item mb-6">
                            <label htmlFor="" className='text-dark-950 dark:text-dark-300  text-14 mb-4 block'>
                            {t('userInfo')}
                            </label>
                            <div className="dark:bg-dark-600 bg-light text-dark-950 dark:!text-white font-500 text-16 p-[14px] rounded-sm flex flex-col gap-4">
                                <div className='flex gap-4'>
                                    <IconMail className="w-5 dark:text-white text-black" /><p>{userInfo?.email ? userInfo?.email : '-'}</p>
                                </div>
                                <div className='flex gap-4'>
                                    <IconUser className="w-5 dark:text-white text-black" /><p>{userInfo?.username ? userInfo?.username : '-'}</p>
                                </div>
                                <div className='flex gap-4'>
                                    <IconCall className="w-5 dark:text-white text-black" /><p>{userInfo?.mobile ? userInfo?.mobile : '-'}</p>
                                </div>
                            </div>
                        </div> : ''
                }
                <div className="form-item mb-6">
                    <label htmlFor="" className='text-dark-950 dark:text-dark-300  text-14 mb-4 block'> {t('amount')} </label>
                    <div className="mb-6">
                        <CurrencyAmountDetailBox disabled={linkDisablebtn} onChange={(e) => handleOnChangeAmount(parseFloat(e.target.value))} className="mb-0" value={plan} currency={allWalletBalance} balance={balance} selected={selected} setSelected={setSelected} />
                        {cusErrorMsg && <span className={cn('!text-12 text-red-500 font-normal mt-1')}>{cusErrorMsg}</span>}
                    </div>

                    <CustomRadio data={amount} plan={plan} setPlan={setPlan} className={"py-2 px-4 text-14 dark:text-white text-dark-950 font-400"} classNamepera={"grid grid-cols-2    sm:grid-cols-4 text-center"} symbol={linkdata?.currency?.symbol ? linkdata?.currency?.symbol : selected?.symbol} disabled={linkDisablebtn} type="amount"/>
                    {
                        tranDetail?.fee === 0 || (cusErrorMsg !== '' || (!plan)) ? '' :
                            <p className='text-14 font-400 text-dark-950 dark:text-dark-300 mt-5'>{t('label.labelTransactionFee')}: <span className='text-dark-950 dark:text-white font-600'>{selected?.symbol}{tranDetail?.fee ? tranDetail?.fee?.toFixed(2) : 0.00}</span></p>
                    }
                    {cusErrorMsg == '' && <p className='text-14 font-400 text-dark-950 dark:text-dark-300 mt-5'>{t('label.labelBalanceAfterTransaction')}: <span className='text-dark-950 dark:text-white font-600'>{selected?.symbol}{balanceAfterTra && balanceAfterTra.toFixed(2)}</span></p>}
                </div>
                {
                    linkDisablebtn === true ? linkdata?.note == '' ? '' : <div className='form-item mb-6'>
                        <TextArea {...getFieldProps("note")}
                            disabled={linkDisablebtn} className="font-500 dark:!text-white text-14" placeholder={t('label.labelAddNote')} />
                    </div> : <div className='form-item mb-6'>
                        <TextArea {...getFieldProps("note")}
                            disabled={linkDisablebtn} className="font-500 dark:!text-white text-14" placeholder={t('label.labelAddNote')} />
                    </div>
                }
                <Button className="w-full" /* onClick={handleSubmit} */ onClick={handleFinalSend} disabled={disable} /* onClick={() => setStep(2)} */>
                    {t('label.labelSend')}
                </Button>
                {/* </Form>
                </FormikProvider> */}
            </div>
            <ConfirmationModal confirmationModalOpen={confirmationModalOpen} setConfirmationModalOpen={setConfirmationModalOpen} handelSubmitEvent={handleModalCLick} transactionDetail={tranDetail} userInfo={userInfo} type="SendCurrency" />
            <VerificationModal type="send" setLoading2={loading2} allFlag={verifyFlags} handelSubmitEvents={handleSubmit} verificationModalOpen={verificationModalOpen} setVerificationModalOpen={setVerificationModalOpen} />
        </>
    )
}

export default withTranslation()(SendModalStepFirst)
