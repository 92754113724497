import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "../../../ui/Button";
// import AdvanceFileUpload from "../../../ui/AdvanceFileUpload";
// import { IdentityType } from "../../../../data/staticData";
// import CustomBasicRadio from "../../../ui/CustomBasicRadio";
import Input from "../../../ui/Input";
import IconClose from "../../../svg/IconClose";
import Http from "../../../../api/Http";
import { AUTH_API_URL } from "../../../../api/Path";
import { Toaster } from "./Toaster";
import DataLoader from "../../../common/DataLoader";
import VerifyIcon from "../../../../assets/images/Group.png";
import useStore from "../../../../contexts/AuthProvider";
import { withTranslation } from 'react-i18next';

const VerificationModal = ({ verificationModalOpen, setVerificationModalOpen, allFlag, handelSubmitEvents, setLoading2, setVerifyFlagsss, type,t }) => {
    // console.log("allFlag", allFlag)
    const userData = useStore((state) => state?.user_data);

    const [emailToken, setEmailToken] = useState();
    const [mobileToken, setMobileToken] = useState();
    const [emailTimer, setEmailTimer] = useState(0);
    const [mobileTimer, setMobileTimer] = useState(0);
    const [verifyCount, setVerifyCount] = useState(0);
    const [verify, setVerify] = useState({});
    const [loading, setLoading] = useState(false);
    const [emailDisable, setEmailDisable] = useState(true);
    const [mobileDisable, setMobileDisable] = useState(true);
    const [googleAuthDisable, setGoogleAuthDisable] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(true);
    const [emailString, setEmailString] = useState(t('btn.btnSendCode'));
    const [mobileString, setMobileString] = useState(t('btn.btnSendCode'));
    const [allNewFlag, setAllNewFlag] = useState(allFlag);

    useEffect(() => {
        if (verificationModalOpen === false) {
            setVerifyCount(0)
            setEmailTimer(0)
            setMobileTimer(0)
            setVerify({})
            setEmailToken('')
            setMobileToken('')
            setEmailString(t('btn.btnSendCode'));
            setMobileString(t('btn.btnSendCode'));
            setEmailDisable(true)
            setMobileDisable(true)
            setGoogleAuthDisable(false)
            setButtonDisable(true)
        } else if (verificationModalOpen === true) {
            leaveVerification()
        }
    }, [verificationModalOpen])

    const leaveVerification = async () => {
        try {
            const response = await Http.get(AUTH_API_URL.leaveVerification);
            if (response?.status === 200) {
                setAllNewFlag(response?.data?.verify_flag);
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    useEffect(() => {
        if (emailTimer > 0) {
            const interval = setInterval(() => {
                setEmailTimer(emailTimer - 1);
            }, 1000);
            if (emailTimer === 1) {
                setEmailString(t('btn.btnResend'));
            }
            return () => {
                clearInterval(interval);
            };
        }
    }, [emailTimer]);

    useEffect(() => {
        if (mobileTimer > 0) {
            const interval = setInterval(() => {
                setMobileTimer(mobileTimer - 1);
            }, 1000);
            if (mobileTimer === 1) {
                setMobileString(t('btn.btnResend'));
            }
            return () => {
                clearInterval(interval);
            };
        }
    }, [mobileTimer]);

    const handleClose = () => {
        setVerificationModalOpen(false);
    };

    const handleSendCode = async (type) => {
        setLoading(true)
        let data = {};
        data.type = type
        // if (type === 'email') {
        //   data.token = allFlag.token
        //   data.email = allFlag.email
        // }
        // if (type === 'mobile') {
        //   data.token = allFlag.token
        //   data.mobile = allFlag.mobile
        //   data.country_code = allFlag.country_code
        //   data.type = type
        // }

        try {
            const response = await Http.post(AUTH_API_URL.sendOtpAuthentication, data);
            if (response?.status === 200) {
                if (type === 'email') {
                    setEmailTimer(60);
                    setEmailToken(response.data.token)
                    setEmailDisable(false)
                }
                if (type === 'mobile') {
                    setMobileTimer(60);
                    setMobileToken(response.data.token)
                    setMobileDisable(false)
                }
                Toaster(response.data.message, 'success')
                setLoading(false)
            } else {
                setLoading(false)
            }
        } catch (err) {
            Toaster(err.response.data.message, 'error')
            setLoading(false)
            console.log("err", err)
        }
    }

    const handleInputOnChange = async (e, type) => {
        let inpValue = e.target.value

        if (inpValue.length === 6) {
            setLoading(true)
            let data = {};
            // data.reason = 'login';
            data.type = type;
            data.otp = inpValue

            if (type === 'email') {
                data.token = emailToken
            }
            if (type === 'mobile') {
                data.token = mobileToken
            }
            // if (type === 'google_auth') {
            //     data.token = allFlag.token
            // }

            const response = await Http.post(AUTH_API_URL.verifyOtpAuthentication, data);
            // console.log("responseresponse", response)
            if (response?.status === 200) {
                setLoading(false)
                Toaster(t('success.successVerifySuccessfully'), 'success')
                if (type === 'email') {
                    setEmailDisable(true)
                    setVerify({ ...verify, verifyEmail: 1 })
                    setVerifyCount(verifyCount + 1)
                }
                if (type === 'mobile') {
                    setMobileDisable(true)
                    setVerify({ ...verify, verifyMobile: 1 })
                    setVerifyCount(verifyCount + 1)
                }
                if (type === 'google_auth') {
                    setVerify({ ...verify, verify2FA: 1 })
                    setGoogleAuthDisable(true)
                    setVerifyCount(verifyCount + 1)
                }
            } else {
                // console.log({ error })
                Toaster(t('error.errorInvalidOTP'), 'error');
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        if (verifyCount === allNewFlag?.enable_count) {
            setButtonDisable(false)
        }
    }, [verifyCount, allNewFlag])

    const handelClick = () => {
        if (type === "add_bank") {
            setVerifyFlagsss(true)
        }
        handelSubmitEvents();
    }

    // const handleSubmit = async () => {
    //     Toaster('You are login successfully!', 'success');
    //     await authStore.change2FA();
    //     // return navigate('/dashboard');
    // }

    return (
        <Transition appear show={verificationModalOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-50"
                onClose={() => { return; }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black  bg-opacity-25 backdrop-blur-sm" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-lg transform rounded-sm sm:rounded-md dark:bg-dark-800 bg-white shadow-xl transition-all overflow-hidden">
                                <div className="dialog-content">
                                    <DataLoader isLoading={loading} />
                                    <DataLoader isLoading={setLoading2} />
                                    <div className="dialog-header relative py-5 px-5 md:px-[30px] md:py-6 text-center border-b border-dark-300 dark:border-dark-600">
                                        <h5 className=" text-16 sm:text-18  dark:text-white">
                                            {t('titleVerification')}
                                        </h5>
                                        <span
                                            className="close absolute top-1/2 transform -translate-y-1/2 right-[30px] cursor-pointer"
                                            onClick={handleClose}
                                        >
                                            <IconClose className="w-5 sm:w-6 h-5 sm:h-6 dark:text-white" />
                                        </span>
                                    </div>
                                    <div className="dialog-body py-6 px-5 md:px-[30px] md:py-6">
                                        <div className="">
                                            <div className="form-group mb-6">
                                                {
                                                    allNewFlag?.is_email_enable &&
                                                    <>
                                                        <div className="form-group mb-6">
                                                            <label htmlFor="" className="mb-2 dark:text-white text-dark block text-sm" >
                                                            {t('label.labelEnterThe6DigitCodeSendToEmail')} {userData && userData.email}
                                                            </label>
                                                            <Input maxlength="6" disabled={emailDisable} onChange={(e) => handleInputOnChange(e, 'email')} placeholder={t('placeholder.hintEmailVerificationCode')} className="!pe-28 dark:bg-dark-600 dark:!text-white text-dark-950 text-sm"
                                                                suffix={
                                                                    verify?.verifyEmail === 1 ? <img src={VerifyIcon} />
                                                                        : emailTimer === 0 ? <span className="text-center cursor-pointer text-sm text-theme" onClick={() => handleSendCode('email')}>{emailString}</span>
                                                                            : <span className="text-center cursor-pointer text-sm text-theme">{emailTimer} {t('second')}</span>
                                                                } />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    allNewFlag?.is_mobile_enable &&
                                                    <>
                                                        <div className="form-group mb-6">
                                                            <label htmlFor="" className="mb-2 text-dark-950 dark:text-white text-dark block text-sm" >
                                                                {t('label.labelEnterThe6DigitCodeSendToEmail')} {userData && "+" + userData?.country_code + userData?.mobile}
                                                            </label>
                                                            <Input maxlength="6" disabled={mobileDisable} onChange={(e) => handleInputOnChange(e, 'mobile')} placeholder={t('placeholder.hintMobileVerificationCode')} className="!pe-28dark:bg-dark-600 dark:!text-white text-dark-950 text-sm"
                                                                suffix={
                                                                    verify?.verifyMobile === 1 ? <img src={VerifyIcon} />
                                                                        : mobileTimer === 0 ? <span className="text-center cursor-pointer text-sm text-theme" onClick={() => handleSendCode('mobile')}>{mobileString}</span>
                                                                            : <span className="text-center cursor-pointer text-sm text-theme">{mobileTimer} {t('second')}</span>
                                                                }
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    allNewFlag?.is_2fa_enable &&
                                                    <>
                                                        <div className="form-group mb-6">
                                                            <label htmlFor="" className="mb-2 text-dark-800 dark:text-white text-dark block text-sm" >
                                                                {t('label.labelEnterThe6DigitCodeFromAuthenticator')}
                                                            </label>
                                                            <Input maxlength="6" disabled={googleAuthDisable} onChange={(e) => handleInputOnChange(e, 'google_auth')} placeholder={t('placeholder.hintGoogleVerificationCode')} className="!pe-28dark:bg-dark-600 dark:!text-white text-dark-950 text-sm"
                                                                suffix={
                                                                    verify?.verify2FA === 1 && <img src={VerifyIcon} />
                                                                } />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <Button /* type="submit"  */ onClick={handelClick} disabled={buttonDisable} className="w-full mt-8" >
                                            {t('label.labelSubmit')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default withTranslation()(VerificationModal);