import React, { useState } from "react";
import { Button } from "../../../components/ui/Button";
import IconCopy from "../../../components/svg/IconCopy";
import EnableGoogleAuthModal from "../../../components/dash/model/EnableGoogleAuthModal";
import { withTranslation } from 'react-i18next';

const BackupKey = ({ setStep, secret, faAuthStatus,t }) => {
  const [enableGoogleAuth, setEnableGoogleAuth] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const handleOpenEnableGoogleAuthModal = () => {
    setEnableGoogleAuth(true);
  };
  const handleCloseEnableGoogleAuthModal = () => {
    setEnableGoogleAuth(false);
  };

  const handleCopyClick = (secret) => {
    navigator.clipboard.writeText(secret)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1000);
      })
      .catch(err => console.error('Failed to copy text: ', err));
  };
  return (
    <>
      <div className="flex flex-col justify-center items-center h-full sm:m-auto py-20 sm:mt-[100px] sm:mb-[60px] my-[40px] mb-0 w-full max-w-xl">
        <p className="dark:text-dark-100 text-dark-950 max-w-[440px] text-center mb-8">
          {t('subTitleBackUpDesc')}
        </p>
        <img
          src={require("../../../assets/images/BackupKey.png")}
          className="w-[150px]"
          alt=""
        />
        <p className="dark:text-dark-100 text-dark-950 max-w-[440px] text-center mt-8">
          {t('instructionResettingGoogleAuthDesc')}
        </p>
        <div className="mt-10 relative w-full">
            {isCopied ? <p className="absolute end-0 -top-8 bg-theme px-1 rounded-sm">{t('copiedToClipboard')}</p> : ''}
          <div className="flex justify-between items-center w-full bg-dark p-2 rounded-full border dark:border-white border-dark-100">
            <p className="dark:text-white text-14 font-400 ps-6 truncate">
              {secret}
            </p>
            <div className="p-4 bg-theme rounded-full cursor-pointer" onClick={()=>handleCopyClick(secret)}>
              <IconCopy className="w-[20px] h-[20px]" />
            </div>
          </div>
        </div>
        <div className="mt-10 flex justify-center gap-8 w-full">
          <Button
            className="xl:w-[44%] w-full border dark:border-white border-dark-100 dark:text-white text-14 bg-transparent"
            size="sm"
            onClick={() => setStep(2)}
          >
            {t('previous')}
          </Button>
          <Button className="xl:w-[44%] w-full text-14" size="sm" onClick={handleOpenEnableGoogleAuthModal}>
          {t('next')}
          </Button>
        </div>
      </div>
      <EnableGoogleAuthModal
        open={enableGoogleAuth}
        handleClose={handleCloseEnableGoogleAuthModal}
        setStep={setStep}
        secret={secret}
        faAuthStatus={faAuthStatus}
      />
    </>
  );
};

export default withTranslation()(BackupKey);
