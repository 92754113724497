import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import SendModalStepFirst from './steps/SendModalStepFirst';
import SendModalSuccessStep from './steps/SendModalSuccessStep';
import IconClose from "../../../svg/IconClose";
import { amount } from '../../../../data/staticData';
import DataLoader from '../../../common/DataLoader';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { TRANSACTION_URL } from '../../../../api/Path';
import Http from '../../../../api/Http';
import { Toaster } from '../common/Toaster';
import ExpireLinkModel from './steps/ExpireLinkModel';
import { withTranslation } from 'react-i18next';



const SendModal = ({ sendModalOpen, setSendModalOpen,t }) => {
    const state = useLocation();
    const navigate = useNavigate();

    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [transactionDetail, setTransactionDetail] = useState({});
    const [paymentStatus, setPaymentStatus] = useState();
    const [linkdata, setLinkData] = useState();

    const handleClose = () => {
        setSendModalOpen(false)
        navigate('/dashboard')
        setTimeout(() => {
            setStep(1)
        }, 500);
    }

    useEffect(() => {
        const fetchData = async () => {
            const targetPath = "/dashboard/forword-payment-links";
            if (state?.pathname.startsWith(targetPath)) {
                const slug = state?.pathname.split(`${targetPath}/`)[1];
                try {
                    const response = await Http.get(TRANSACTION_URL.getForwardTransactionDetail + `?id=${slug}`);
                    if (response?.status === 200) {
                        setLinkData(response?.data)
                    } else {
                        setStep(4);
                    }
                } catch (error) {
                    console.log(error);
                    if (error?.response?.status === 409) {
                        navigate('/dashboard')
                        setSendModalOpen(false)
                        Toaster(error?.response?.data?.message, 'error')
                    }
                }
            }
        };

        fetchData();
    }, [state?.pathname]);

    return (
        <Transition appear show={sendModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => { return; }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black  bg-opacity-25 backdrop-blur-sm" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-lg transform rounded-sm sm:rounded-md bg-white dark:bg-dark-800  shadow-xl transition-all overflow-hidden">
                                <div className="dialog-content">
                                    <DataLoader isLoading={loading} />
                                    {
                                        step !== 2 &&
                                        <div className='dialog-header relative py-5 px-5 md:px-[30px] md:py-6 text-center border-b border-dark-300 dark:border-dark-600'>
                                            {
                                                step === 4 ?
                                                    <h5 className=' text-16 sm:text-18 dark:text-white'>
                                                        {t('label.labelExpireLink')}
                                                    </h5> :
                                                    <h5 className=' text-16 sm:text-18 dark:text-white'>
                                                        {t('label.labelSend')}
                                                    </h5>
                                            }
                                            {
                                                step === 4 ? '' :
                                                    <span className='close absolute top-1/2 transform -translate-y-1/2 right-[30px] cursor-pointer' onClick={handleClose}>
                                                        <IconClose className="w-5 sm:w-6 h-5 sm:h-6 dark:text-white text-dark-950" />
                                                    </span>
                                            }
                                        </div>
                                    }
                                    <div className='dialog-body py-6 px-5 md:px-[30px] md:py-6'>
                                        {
                                            step === 1 && <SendModalStepFirst linkdata={linkdata} setStep={setStep} amount={amount} setLoading={setLoading} setTransactionDetail={setTransactionDetail} setPaymentStatus={setPaymentStatus} />
                                        }
                                        {
                                            step === 2 && <SendModalSuccessStep paymentStatus={paymentStatus} setStep={setStep} setSendModalOpen={setSendModalOpen} transactionDetail={transactionDetail} />
                                        }
                                        {
                                            step === 4 && <ExpireLinkModel setStep={setStep} />
                                        }
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default withTranslation()(SendModal)
