import axios from 'axios'
import Security from './Security'
import CryptoJS from "crypto-js";
import { Toaster } from '../components/dash/model/common/Toaster';

async function AxiosMiddleware(method, url, data, options) {

    if (data?.signature) { // delete old signature
        delete data.signature;
    }
    const urlObject = new URL(url);
    const params = Object.fromEntries(urlObject.searchParams.entries());

    const authStorage = JSON.parse(localStorage.getItem('auth-storage'));
    const language = localStorage.getItem('i18nextLng');
    let finalSig;

    if (authStorage.state.accessToken) {
        var joinData;
        if (params) {
            let values = Object.values(params).filter(value => value !== null && value !== '');

            joinData = values.join(':');
        }
        if (Array.isArray(data)) {
            if (data?.length > 0) {
                let values = Object.values(data).filter(value => value !== null && value !== '')

                joinData = values.join(':');
            }
        } else if (typeof data === 'object') {
            if (data) {
                let values = Object.values(data).filter(value => value !== null && value !== '')

                joinData = values.join(':');
            }
        }
        
        finalSig = CryptoJS.HmacSHA256(joinData, authStorage.state.user_data.secret).toString(CryptoJS.enc.Hex) // generate signature using sha256
        
        // let sig = sha512(data, authStorage.state.user_data.secret); // generate signature using sha512
        // await sig.then((result) => {
        //     return finalSig = result;
        // });
    }
    // console.log({data});
    if (data) {
        data.signature = finalSig;
    }
    if (finalSig && method === 'get') {
        const urlObject = new URL(url);
        urlObject.searchParams.append('signature', finalSig);
        url = urlObject.toString();
    }

    if ((data.env !== 'test' && url.search("env=test") === -1) && (options && options?.Accept !== 'multipart/form-data')) {
        data = (new Security()).encrypt(data);
    }
    const isAuthenticated = authStorage.state.isAuthenticated;
    if (isAuthenticated) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${authStorage.state.accessToken}`;
        axios.defaults.headers.common['x-api-key'] = authStorage.state.user_data.api_key;
    }
    axios.defaults.headers.common['device-type'] = 3;
    axios.defaults.headers.common['lang'] = language;

    axios.defaults.headers.common['site'] = `trucker`;
    axios.defaults.withCredentials = true;
    switch (method) {
        case 'get':
            return axios.get(url, data, options);
        case 'post':
            return axios.post(url, data, options);
        case 'head':
            return axios.head(url, data, options);
        case 'patch':
            return axios.patch(url, data, options);
        case 'put':
            return axios.put(url, data, options);
        case 'delete':
            return axios.delete(url, { data: data, headers: options });
        default:
            break;
    }

}
// let token = document.head.querySelector('meta[name="csrf-token"]');
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
axios.interceptors.response.use(
    (response) => {
        if (response.data.mac !== undefined) {
            response.data = (new Security()).decrypt(response.data);
        }
        return response
    },
    (error) => {
        console.log({ error })
        if (error?.response) {
            if (error.response.status === 503) {
                Toaster(error?.response?.data?.message, 'error')
                return false;
            }
            if (error.response.status === 423) {
                Toaster(error?.response?.data?.message, 'error')
            }
            if (error.response.status === 401) {
                localStorage.clear();
                window.location.reload();
            }
            if (error.response.status === 409) {
                // Toaster(error?.response?.data?.message, 'error')
                return Promise.reject(error);
            }
            if (error.response.status === 422) {
                if (error?.response?.data?.errors) {
                    return Promise.reject(error);
                } else {
                    // Toaster(error?.response?.data?.message, 'error')
                    return error;
                    // return Promise.reject(error);
                }
            }
            if (error.response.status === 403) {
                return error;
            }
            if (error.response.status === 404) {
                return false;
            }
            return Promise.reject(error);
        } else {
            Toaster('Internal Server Error!', 'error');
            return false;
        }
    }
)

export function get(url, data = [], options = {}) {
    return AxiosMiddleware('get', url, data, options)
}
export function post(url, data = [], options = {}) {
    return AxiosMiddleware('post', url, data, options)
}
export function head(url, data = [], options = {}) {
    return AxiosMiddleware('head', url, data, options)
}
export function patch(url, data = [], options = {}) {
    return AxiosMiddleware('patch', url, data, options)
}
export function put(url, data = [], options = {}) {
    return AxiosMiddleware('put', url, data, options)
}
export function del(url, data = [], options = {}) {
    return AxiosMiddleware('delete', url, data, options)
}
export function setBearerToken(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
export function setVerifyToken(token) {
    axios.defaults.headers.common['VerifyToken'] = `${token}`;
}
async function sha512(data, secret) {
    const dataString = JSON.stringify(data);
    // Generate the hash
    const hash = CryptoJS.HmacSHA512(dataString, secret);
    // Convert the hash to a hexadecimal string
    const hashString = hash.toString(CryptoJS.enc.Hex);

    return hashString;
}