import React from 'react'

const IconDeposite = (props) => {
  return (
    <svg {...props} viewBox="0 0 28 28" fill="none" >
    <path fillRule="evenodd" clipRule="evenodd" d="M1.6333 9.91667C1.6333 9.53008 1.9467 9.21667 2.3333 9.21667H16.9166C17.3032 9.21667 17.6166 9.53008 17.6166 9.91667C17.6166 10.3033 17.3032 10.6167 16.9166 10.6167H2.3333C1.9467 10.6167 1.6333 10.3033 1.6333 9.91667ZM6.29997 19.25C6.29997 18.8634 6.61337 18.55 6.99997 18.55H9.3333C9.7199 18.55 10.0333 18.8634 10.0333 19.25C10.0333 19.6366 9.7199 19.95 9.3333 19.95H6.99997C6.61337 19.95 6.29997 19.6366 6.29997 19.25ZM11.55 19.25C11.55 18.8634 11.8634 18.55 12.25 18.55H16.9166C17.3032 18.55 17.6166 18.8634 17.6166 19.25C17.6166 19.6366 17.3032 19.95 16.9166 19.95H12.25C11.8634 19.95 11.55 19.6366 11.55 19.25Z" fill="currentcolor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.86234 5.60628C3.31016 6.15225 3.0333 7.1539 3.0333 9.20497V18.795C3.0333 20.846 3.31016 21.8477 3.86234 22.3937C4.41728 22.9424 5.43687 23.2166 7.5133 23.2166H20.4866C22.5631 23.2166 23.5827 22.9424 24.1376 22.3937C24.6898 21.8477 24.9666 20.846 24.9666 18.795V16.3683C24.9666 15.9817 25.28 15.6683 25.6666 15.6683C26.0532 15.6683 26.3666 15.9817 26.3666 16.3683V18.795C26.3666 20.8389 26.1243 22.3981 25.1219 23.3892C24.1223 24.3776 22.5519 24.6166 20.4866 24.6166H7.5133C5.44806 24.6166 3.87765 24.3776 2.87801 23.3892C1.87561 22.3981 1.6333 20.8389 1.6333 18.795V9.20497C1.6333 7.16104 1.87561 5.60186 2.87801 4.61074C3.87765 3.62235 5.44806 3.3833 7.5133 3.3833H16.9166C17.3032 3.3833 17.6166 3.6967 17.6166 4.0833C17.6166 4.4699 17.3032 4.7833 16.9166 4.7833H7.5133C5.43687 4.7833 4.41728 5.05758 3.86234 5.60628Z" fill="currentcolor"/>
    <path d="M23.3334 3.38367C22.9468 3.38367 22.6334 3.69707 22.6334 4.08367V9.39372L21.495 8.25536C21.2216 7.98199 20.7784 7.98199 20.5051 8.25536C20.2317 8.52873 20.2317 8.97194 20.5051 9.24531L22.8384 11.5786C22.9697 11.7099 23.1477 11.7837 23.3334 11.7837C23.519 11.7837 23.6971 11.7099 23.8283 11.5786L26.1617 9.24531C26.435 8.97194 26.435 8.52873 26.1617 8.25536C25.8883 7.98199 25.4451 7.98199 25.1717 8.25536L24.0334 9.39372V4.08367C24.0334 3.69707 23.72 3.38367 23.3334 3.38367Z" fill="#0DF69E"/>
    </svg>
    
  )
}

export default IconDeposite