import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import SuccessMessageModal from "./SuccessMessageModal";
import Input from "../../ui/Input";
import { Button } from "../../ui/Button";
import IconClose from "../../svg/IconClose";
import { AUTH_API_URL } from "../../../api/Path";
import Http from "../../../api/Http";
import { Toaster } from "./common/Toaster";
import DataLoader from "../../common/DataLoader";
import VerifyIcon from "../../../assets/images/Group.png";
import useStore from "../../../contexts/AuthProvider";
import authStores from "../../../contexts/AuthProvider";
import { withTranslation } from 'react-i18next';

const EnableGoogleAuthModal = ({ open, handleClose, secret, faAuthStatus, set2FAEnabled,t }) => {
  const authStore = authStores();

  const userData = useStore((state) => state?.user_data);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [emailToken, setEmailToken] = useState(false);
  const [mobileToken, setMobileToken] = useState();
  const [loading, setLoading] = useState(false);
  const [googleDisable, setGoogleDisabled] = useState(false);
  const [emailDisable, setEmailDisabled] = useState(true);
  const [mobileDisable, setMobileDisabled] = useState(true);
  const [emailTimer, setEmailTimer] = useState(0);
  const [mobileTimer, setMobileTimer] = useState(0);
  const [emailString, setEmailString] = useState(t('btn.btnSendCode'));
  const [mobileString, setMobileString] = useState(t('btn.btnSendCode'));
  const [verify, setVerify] = useState({});
  const [buttonDisable, setButtonDisable] = useState(true);
  const [verifyCount, setVerifyCount] = useState(0);
  const [flag, setFlag] = useState(0);
  const [flagForCount, setFlagForCount] = useState();

  useEffect(() => {
    if (open === false) {
      setEmailDisabled(true)
      setMobileDisabled(true)
      setGoogleDisabled(false)
      setVerify({})
      setEmailTimer(0)
      setMobileTimer(0)
    } else if (open === true) {
      leaveVerification() //#commented this because 2 not working => ishani
      //# uncomment this because after google verify model reopen then it showing error otp already verified 
      // so instead of delete whole token record we can just make flag false
    }
  }, [open])

  const leaveVerification = async () => {
    try {
      const response = await Http.get(AUTH_API_URL.leaveVerification);
      if (response?.status === 200) {
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  useEffect(() => {
    if (flag === 1) {
      set2FAEnabled(faAuthStatus)
    }
  }, [flag])

  useEffect(() => {
    if (verifyCount === userData?.enable_count && flagForCount === true) {
      setButtonDisable(false)
    }
  }, [userData, verifyCount, flagForCount])

  const handleOpenSuccessModal = () => {
    setOpenSuccessModal(true);
    handleClose();
  };
  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
    handleClose();
  };

  useEffect(() => {
    if (emailTimer > 0) {
      const interval = setInterval(() => {
        setEmailTimer(emailTimer - 1);
      }, 1000);
      if (emailTimer === 1) {
        setEmailString(t('btn.btnResend'));
      }
      return () => {
        clearInterval(interval);
      };
    }
  }, [emailTimer]);

  useEffect(() => {
    if (mobileTimer > 0) {
      const interval = setInterval(() => {
        setMobileTimer(mobileTimer - 1);
      }, 1000);
      if (mobileTimer === 1) {
        setMobileString(t('btn.btnResend'));
      }
      return () => {
        clearInterval(interval);
      };
    }
  }, [mobileTimer]);

  const handleInputOnChange = async (e, type) => {
    let inpValue = e.target.value
    const flag = faAuthStatus ? 1 : 0

    let data = {};
    data.type = type
    data.otp = inpValue

    if (flag === 1) { data.secret = secret }

    if (type === 'email') {
      data.token = emailToken
      if (flag === 0) { data.onOffFlagfor2fa = 1 }
    }
    if (type === 'mobile') {
      data.token = mobileToken
      if (flag === 0) { data.onOffFlagfor2fa = 1 }
    }

    if (type === 'google_auth') { data.onOffFlag = flag }

    if (inpValue.length === 6) {
      setLoading(true)
      try {
        const response = await Http.post(AUTH_API_URL.verifyOtpAuthentication, data);

        if (response?.status === 200) {
          if (response?.data?.verify_flag) {
            await authStore.updateVerifyFlag(response?.data?.verify_flag)
          }
          if (type === 'email') {
            setVerify({ ...verify, verifyEmail: 1 })
            setVerifyCount(verifyCount + 1);
            setEmailDisabled(true);
          }
          if (type === 'mobile') {
            setVerify({ ...verify, verifyMobile: 1 })
            setVerifyCount(verifyCount + 1);
            setMobileDisabled(true);
          }
          if (type === 'google_auth') {
            setVerify({ ...verify, verify2FA: 1 })
            setFlagForCount(true)
            if (faAuthStatus === 0) {
              setVerifyCount(verifyCount + 1);
            }
            setGoogleDisabled(true)
          }
          Toaster(response?.data?.message, 'success')
          setLoading(false)
        }
        if (response?.response?.status === 422 || response?.response?.status === 409) {
          Toaster(response?.response?.data.message, 'error')
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        // Toaster("First enter a mobile number", 'error')
      }
    }
  }

  const handleSendCode = async (type) => {
    setLoading(true);

    let data = {};
    data.type = type

    try {
      const response = await Http.post(AUTH_API_URL.sendOtpAuthentication, data);
      if (response?.status === 200) {
        if (type === 'email') {
          setEmailTimer(60);
          setEmailToken(response?.data?.token)
          setEmailDisabled(false);
        }
        if (type === 'mobile') {
          setMobileTimer(60);
          setMobileToken(response?.data?.token)
          setMobileDisabled(false);
        }
        Toaster(response.data.message, 'success')
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
      Toaster(err?.response?.data?.message, 'error')
      console.log("err", err)
    }
  }

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => { return }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black  bg-opacity-60 backdrop-blur-sm" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform rounded-sm sm:rounded-md bg-white dark:bg-dark-800 shadow-xl transition-all overflow-hidden">
                  <DataLoader isLoading={loading} />
                  <div className="dialog-content">
                    <div className="relative py-5 px-5 text-center md:px-[30px] md:py-6 border-b border-dark-300 dark:border-dark-600">
                      <p className="text-16 sm:text-18 dark:text-white ms-auto">
                        {t('enableGoogleAuthenticator')}
                      </p>
                      <span
                        className="close absolute top-1/2 transform -translate-y-1/2 right-[30px] cursor-pointer"
                        onClick={handleClose}
                      >
                        <IconClose className={`w-5 sm:w-6 h-5 sm:h-6 dark:text-white text-dark-950 ${buttonDisable == false ? 'hidden' : ''}`} />
                      </span>
                    </div>
                    <div className="sm:p-12 p-8">
                      <p className="text-theme text-18 mb-8">
                        {faAuthStatus ? `${t('stepTitle')} 4: ${t('enabledGoogleAuthenticator')}` : t('disableGoogleAuthenticator')}
                      </p>
                      {/* <div className="relative"> */}
                      {/* </div> */}
                      {
                        userData?.is_email_enable &&
                        <Input
                          className="mb-8"
                          lable={t('label.labelEnterThe6DigitCodeSendToEmail') + userData?.email}
                          placeholder={t('placeholder.hintEmailVerificationCode')}
                          maxlength="6" disabled={emailDisable} onChange={(e) => handleInputOnChange(e, 'email')}
                          suffix={
                            verify?.verifyEmail === 1 ?
                              <img src={VerifyIcon} />
                              : emailTimer === 0 ? <span className="text-center cursor-pointer sm:text-sm text-12 text-theme" onClick={() => handleSendCode('email')}>{emailString}</span>
                                : <span className="text-center cursor-pointer sm:text-sm text-12 text-theme">{emailTimer} {t('second')}</span>
                          }
                        />
                      }
                      {
                        userData?.is_mobile_enable &&
                        <Input
                          className="mb-8"
                          lable={t('label.labelEnterThe6DigitCodeSendToEmail') + "+" + userData?.country_code + userData?.mobile}
                          placeholder={t('placeholder.hintMobileVerificationCode')}
                          maxlength="6" disabled={mobileDisable} onChange={(e) => handleInputOnChange(e, 'mobile')}
                          suffix={
                            verify?.verifyMobile === 1 ?
                              <img src={VerifyIcon} />
                              : mobileTimer === 0 ? <span className="text-center cursor-pointer sm:text-sm text-12 text-theme" onClick={() => handleSendCode('mobile')}>{mobileString}</span>
                                : <span className="text-center cursor-pointer sm:text-sm text-12 text-theme">{mobileTimer} {t('second')}</span>
                          }
                        />
                      }
                      <Input
                        lable={t('label.labelEnterThe6DigitCodeFromAuthenticator')}
                        placeholder={t('placeholder.hintGoogleVerificationCode')}
                        maxlength="6" disabled={googleDisable} onChange={(e) => handleInputOnChange(e, 'google_auth')}
                        suffix={
                          verify?.verify2FA === 1 && <img src={VerifyIcon} />
                        }
                      />
                      <div className="flex gap-8 mt-16">
                        {/* <Button
                          className="w-full !text-14 bg-transparent border border-white text-white"
                          onClick={handleClose}
                        >
                          previous
                        </Button> */}
                        <Button
                          className="w-full text-14"
                          onClick={handleOpenSuccessModal}
                          disabled={buttonDisable}
                        >
                          {t('label.labelSubmit')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <SuccessMessageModal
        open={openSuccessModal}
        handleClose={handleCloseSuccessModal}
        message={faAuthStatus ? t('success.googleAuthSuccessMsg') : t('success.googleAuthRemoveSuccessMsg')}
        message2={
          faAuthStatus ? t('success.googleAuthSuccessDes') : t('success.googleAuthDisableSuccessDes')
        }
        messageClass="w-[70%] mb-8"
        setFlag={setFlag}
      />
    </>
  );
};

export default withTranslation()(EnableGoogleAuthModal);
