import React from 'react'

const IconEye = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none">
            <path d="M12 4C16.517 4 19.994 6.902 22.426 10.753L22.545 10.944L22.623 11.082L22.686 11.214C22.734 11.3232 22.7715 11.4367 22.798 11.553C22.8602 11.8474 22.8602 12.1516 22.798 12.446C22.7715 12.5623 22.734 12.6758 22.686 12.785L22.623 12.917L22.545 13.055L22.426 13.247C19.994 17.098 16.517 20 12 20C7.48299 20 4.00599 17.098 1.57399 13.247L1.41299 12.983L1.34599 12.854L1.31499 12.786C1.26699 12.6768 1.22949 12.5633 1.20299 12.447C1.14065 12.1523 1.14065 11.8477 1.20299 11.553C1.22949 11.4367 1.26699 11.3232 1.31499 11.214C1.33447 11.1693 1.35548 11.1253 1.37799 11.082L1.45599 10.944L1.75899 10.465C4.18099 6.759 7.59699 4 12 4ZM12 6C8.35399 6 5.36699 8.494 3.26499 11.821L3.15399 11.999L3.44699 12.462C5.53799 15.647 8.45899 18 12 18C15.646 18 18.634 15.506 20.735 12.179L20.844 11.998L20.553 11.538C18.463 8.353 15.542 6 12 6ZM12 8C13.0609 8 14.0783 8.42143 14.8284 9.17157C15.5786 9.92172 16 10.9391 16 12C16 13.0609 15.5786 14.0783 14.8284 14.8284C14.0783 15.5786 13.0609 16 12 16C10.9391 16 9.92171 15.5786 9.17157 14.8284C8.42142 14.0783 7.99999 13.0609 7.99999 12C7.99999 10.9391 8.42142 9.92172 9.17157 9.17157C9.92171 8.42143 10.9391 8 12 8ZM12 10C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 9.99999 11.4696 9.99999 12C9.99999 12.5304 10.2107 13.0391 10.5858 13.4142C10.9609 13.7893 11.4696 14 12 14C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10Z" fill="currentcolor" />
        </svg>
    )
}

export default IconEye