import React from 'react'

const IconPaymentLink = (props) => {
    return (
        <svg {...props}  viewBox="0 0 29 28" fill="none" >
            <path d="M11.9321 20.7296L8.90763 23.754C7.65377 25.0079 5.62323 25.0079 4.37042 23.7543C3.11736 22.5012 3.11736 20.4705 4.37017 19.2177L10.4201 13.1678C11.6729 11.9149 13.7036 11.9149 14.9564 13.1678C15.3741 13.5854 16.0512 13.5854 16.4689 13.1678C16.8865 12.7501 16.8865 12.073 16.4689 11.6553C14.3807 9.5672 10.9958 9.5672 8.90763 11.6553L2.85779 17.7052C0.769653 19.7933 0.769653 23.1783 2.85779 25.2664C4.94567 27.3556 8.33088 27.3556 10.4201 25.2664L13.4445 22.242C13.8622 21.8243 13.8622 21.1472 13.4445 20.7295C13.0269 20.3119 12.3497 20.312 11.9321 20.7296Z" fill="currentcolor" />
            <path d="M25.3917 2.73276C23.3035 0.644623 19.9175 0.644623 17.8293 2.73276L14.2007 6.3614C13.7831 6.77904 13.7831 7.4562 14.2007 7.87384C14.6183 8.29147 15.2955 8.29147 15.7131 7.87384L19.3418 4.24519C20.5946 2.99233 22.6264 2.99233 23.8792 4.24519C25.132 5.498 25.132 7.52874 23.8792 8.78155L17.2251 15.4357C15.9723 16.6886 13.9416 16.6886 12.6888 15.4357C12.2711 15.0181 11.594 15.0181 11.1763 15.4357C10.7587 15.8533 10.7587 16.5305 11.1763 16.9481C13.2645 19.0363 16.6494 19.0363 18.7376 16.9481L25.3917 10.294C27.4798 8.2059 27.4798 4.82089 25.3917 2.73276Z" fill="#08C57D" />
        </svg>
    )
}

export default IconPaymentLink