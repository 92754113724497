import React from 'react'
import { cn } from '../../lib/utils'

const TR = ({children,className,...props}) => {
  return (
    <tr {...props} className={cn("",className)}>
         {children}
    </tr>
  )
}

export default TR