function path(root, sublink) {
    return `${root}${sublink}`;
}
const BASE_PATH = process.env.REACT_APP_BASE_URL;
const USER_PATH = BASE_PATH + "/user";
const USER_BANK = BASE_PATH + "/user-bank";
const TICKET_PATH = BASE_PATH + "/ticket";
const TRANSACTION_PATH = BASE_PATH + "/transaction";
const UPLOAD_FILE_PATH = BASE_PATH + "";
const KYC_PATH = BASE_PATH + "/kyc";
const CURRENCY_PATH = BASE_PATH + "/currency";
const USER_CARD = BASE_PATH + "/user-card";
const CONTACT_PATH = BASE_PATH + "/contact-us";
const FAQ = BASE_PATH + "/faq";

// const ROOTS_PATH = "/admin";
// const VENDOR_PATH = "vendor";

const CONTACTUS_URL = {
    sendContactMessage: path(CONTACT_PATH, "/store"),
}

const AUTH_API_URL = {
    login: path(USER_PATH, "/login"),
    socialLogin: path(USER_PATH, "/web-google-login"),
    register: path(USER_PATH, "/register"),
    verifyOtp: path(USER_PATH, "/signup-otp-verify"),
    logout: path(USER_PATH, "/logout"),
    getprofile: path(USER_PATH, "/get-profile"),
    updateProfile: path(USER_PATH, "/update-profile"),
    addPhone: path(USER_PATH, "/add-phone"),
    uploadUserImage: path(BASE_PATH, '/uploadImage'),
    resendCode: path(USER_PATH, "/phone-resend-otp"),
    changepassword: path(USER_PATH, "/change-password"),
    getnotification: path(USER_PATH, "/notification-list"),
    resendOTP: path(USER_PATH, "/resend-otp"),
    getOTPForgot: path(USER_PATH, "/forget-passoword"),
    verifyOTPForgot: path(USER_PATH, "/forgot-otp-verify"),
    resetPassword: path(USER_PATH, "/reset-password"),
    loginSendOtp: path(USER_PATH, "/login-send-otp"),
    loginOtpverify: path(USER_PATH, "/otp-verify"),
    pdfdownload: path(USER_PATH, "/pdfDownload"),
    addEmail: path(USER_PATH, "/update-email"),
    emailphoneverifyOtp: path(USER_PATH, "/phone-email-otp-verify"),
    getScanQr: path(USER_PATH, "/generate-2fa-qr"),
    sendOtpAuthentication: path(USER_PATH, "/send-otp-authentication"),
    verifyOtpAuthentication: path(USER_PATH, "/verify-otp-authentication"),
    getAuthQRCode: path(USER_PATH, "/gen-qrcode-payment"),
    verifyRecaptcha: path(USER_PATH, "/verify-recaptcha"),
    leaveVerification: path(USER_PATH, "/leave-verification"),
    checkUsername: path(USER_PATH, '/check-username'),
    checkEmail: path(USER_PATH, '/check-email')
};

const TICKET_API_URL = {
    supportTicket: path(TICKET_PATH, ""),
    createsupportTicket: path(TICKET_PATH, "/store"),
    uploadfile: path(UPLOAD_FILE_PATH, "/upload-document"),
    supportTicketChat: path(TICKET_PATH, "/chatsweb"),
    sendMessage: path(TICKET_PATH, "/send-message"),
}

const TRANSACTION_URL = {
    getTransaction: path(TRANSACTION_PATH, "/get-wallet-balance"),
    getAllWalletBalance: path(TRANSACTION_PATH, "/all-wallet-balance"),
    getCurrencyExchangeList: path(TRANSACTION_PATH, "/currency-exchange-list"),
    exchangeCurrency: path(TRANSACTION_PATH, "/exchange"),
    sendPayment: path(TRANSACTION_PATH, "/send-payment"),
    getTransactionHistory: path(TRANSACTION_PATH, "/get-transaction-history-web"),
    withdraw: path(TRANSACTION_PATH, "/withdraw"),
    checkUsernamePhone: path(TRANSACTION_PATH, "/check-username-phone"),
    getExchangeTransactions: path(TRANSACTION_PATH, "/exchange-transaction-list"),
    deposit: path(TRANSACTION_PATH, "/deposit"),
    sendEmail: path(TRANSACTION_PATH, "/transaction-detail-email"),
    createPaymentLink: path(TRANSACTION_PATH, "/create-payment-link"),
    getPaymentLinkTime: path(TRANSACTION_PATH, "/payment-link-timer"),
    getPaymentLink: path(TRANSACTION_PATH, "/payment-links"),
    getTransactionDetail: path(TRANSACTION_PATH, "/get-transaction-detail"),
    getForwardTransactionDetail: path(TRANSACTION_PATH, "/forword-payment-detail"),
    client_bank_list: path(TRANSACTION_PATH, "/account-list"),
    deposit_payment_methods: path(TRANSACTION_PATH, "/deposit-payment-methods"),
    get_payment_status: path(TRANSACTION_PATH, "/get-payment-status"),
    getTotal: path(TRANSACTION_PATH, '/get-total')
}

const KYC_API_URL = {
    createSession: path(KYC_PATH, "/create-session"),
    checkSessionStatus: path(KYC_PATH, "/check-status")
}

// Bank
const USER_BANK_API_URL = {
    admin_banks: path(USER_PATH, "/bank-list"),
    user_bank_store: path(USER_BANK, "/store"),
    user_banks: path(USER_BANK, ''),
    user_bank_set_default: path(USER_BANK, "/is-default"),
    user_bank_delete: path(USER_BANK, "/delete"),
}

// Setting
const SETTING = {
    web_theme: path(BASE_PATH + "/update-setting", '/'),
}

// currency
const CURRENCY = {
    list: path(CURRENCY_PATH, "/list"),
    get_amount: path(CURRENCY_PATH, "/get-amount")
}

const CMS_API_URL = {
    getTerms: path(BASE_PATH, '/term-and-condition'),
    getPrivacyPolicy: path(BASE_PATH, "/privacy-policy")
}
const USER_CARD_API_URL = {
    user_card_store: path(USER_CARD, "/store"),
    user_cards: path(USER_CARD, ""),
    destroy_card: path(USER_CARD, "/delete"),
}

const FAQ_URL = {
    faqs: path(FAQ, "/"),
}

export {
    AUTH_API_URL,
    USER_BANK_API_URL,
    TICKET_API_URL,
    SETTING,
    TRANSACTION_URL,
    KYC_API_URL,
    CURRENCY,
    CMS_API_URL,
    USER_CARD_API_URL,
    CONTACTUS_URL,
    FAQ_URL
}