import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import IconClose from '../../../svg/IconClose'
import { Button } from '../../../ui/Button'
import moment from 'moment'
import { AUTH_API_URL, TRANSACTION_URL } from '../../../../api/Path'
import { Toaster } from './Toaster'
import axios from 'axios'
import { withTranslation } from 'react-i18next';

const ExchangeTransactionsHistoryModal = ({ setTransactionHistoryModalOpen, transactionHistoryModalOpen, Transactiondata,t }) => {
    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setTransactionHistoryModalOpen(false)
    }

    const pdfDownload = async () => {
        setLoading(true)
        Transactiondata.amount = Transactiondata?.payment_amount ? Transactiondata?.payment_amount : Transactiondata?.symbol + Transactiondata?.amount
        Transactiondata.card_name = Transactiondata?.userCard
        Transactiondata.fees = Transactiondata?.exchange_fees ? Transactiondata?.exchange_fees : Transactiondata?.admin_fees
        Transactiondata.final_amount = Transactiondata?.exchanged_amount ? Transactiondata?.exchanged_amount : Transactiondata?.to_symbol + Transactiondata?.exchange_amount
        Transactiondata.bank_name = Transactiondata?.userBank?.bank_name
        try {
            const response = await axios.post(`${AUTH_API_URL.pdfdownload}`, Transactiondata, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'env': 'test'
                },
            });
            // console.log(response);
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'report.pdf';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setLoading(false)

        } catch (error) {
            setLoading(false)
            console.error("Error fetching PDF:", error);
            Toaster(error.message, "error");
        }
    };
    const sendEmail = async () => {
        try {
            const response = await axios.post(`${TRANSACTION_URL.sendEmail}`, Transactiondata, {
                headers: {
                    'Content-Type': 'application/json',
                    'env': 'test'
                },
            });
            if (response.status === 200) {
                Toaster(response?.data?.message, "success")
            } else {
                console.log({ response })
            }

        } catch (error) {
            console.error("Error fetching PDF:", error);
            Toaster(error.message, "error");
        }
    };

    return (
        <Transition appear show={transactionHistoryModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => { return }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-lg transform rounded-sm sm:rounded-md bg-white dark:bg-dark-800  shadow-xl transition-all overflow-hidden">
                                <div className="dialog-content">
                                    <div className='dialog-header relative py-5 px-5 md:px-[30px] md:py-6 text-center border-b border-dark-300 dark:border-dark-600'>
                                        <h5 className=' text-16 sm:text-18 dark:text-white'>
                                            Exchange Details
                                        </h5>
                                        <span className='close absolute top-1/2 transform -translate-y-1/2 right-[30px] cursor-pointer' onClick={handleClose}>
                                            <IconClose className="w-5 sm:w-6 h-5 sm:h-6 dark:text-white text-dark-950" />
                                        </span>
                                    </div>
                                    <div className='dialog-body py-6 px-5 md:px-[30px] md:py-6'>
                                        <div className="mb-6">
                                            <ul>
                                                <li className='border-b border-dark-100/20 py-3.5 last:border-0'>
                                                    <div className="">
                                                        <h6 className='text-14 font-400 dark:text-dark-300 text-dark-500 mb-2'>
                                                        {t('label.labelTransactionId')}
                                                        </h6>
                                                        <h5 className='text-16 font-500 text-dark-950 dark:text-white'>
                                                            {Transactiondata?.transaction_id}
                                                        </h5>
                                                    </div>
                                                </li>
                                                <li className='border-b border-dark-100/20 py-3.5 last:border-0'>
                                                    <div className="">
                                                        <h6 className='text-14 font-400 dark:text-dark-300 text-dark-500 mb-2'>
                                                            Payment Amount
                                                        </h6>
                                                        <h5 className='text-theme text-24 font-600'>
                                                            {Transactiondata?.payment_amount ? Transactiondata?.payment_amount : Transactiondata?.symbol + Transactiondata?.amount}
                                                        </h5>
                                                    </div>
                                                </li>
                                                <li className='border-b border-dark-100/20 py-3.5 last:border-0'>
                                                    <div className="">
                                                        <h6 className='text-14 font-400 dark:text-dark-300 text-dark-500 mb-2'>
                                                            Exchange Fees
                                                        </h6>
                                                        <h5 className='text-16 font-500 text-dark-950 dark:text-white'>
                                                            {Transactiondata?.exchange_fees ? Transactiondata?.exchange_fees : Transactiondata?.admin_fees}
                                                        </h5>
                                                    </div>
                                                </li>
                                                <li className='border-b border-dark-100/20 py-3.5 last:border-0'>
                                                    <div className="">
                                                        <h6 className='text-14 font-400 dark:text-dark-300 text-dark-500 mb-2'>
                                                            Exchanged Amount
                                                        </h6>
                                                        {
                                                            Transactiondata?.status === 0 ?
                                                                <h5 className='text-24 font-600' style={{ color: '#FB8B24' }}>
                                                                    {Transactiondata?.exchanged_amount ? Transactiondata?.exchanged_amount : Transactiondata?.to_symbol + Transactiondata?.exchange_amount}
                                                                </h5> : Transactiondata?.status === 1 ?
                                                                    <h5 className='text-theme text-24 font-600'>
                                                                        {Transactiondata?.exchanged_amount ? Transactiondata?.exchanged_amount : Transactiondata?.to_symbol + Transactiondata?.exchange_amount}
                                                                    </h5> : Transactiondata?.status === 2 ?
                                                                        <h5 className='text-24 font-600' style={{ color: '#B2A59B' }}>
                                                                            {Transactiondata?.exchanged_amount ? Transactiondata?.exchanged_amount : Transactiondata?.to_symbol + Transactiondata?.exchange_amount}
                                                                        </h5> : Transactiondata?.status === 3 ?
                                                                            <h5 className='text-24 font-600' style={{ color: '#FF8C00' }}>
                                                                                {Transactiondata?.exchanged_amount ? Transactiondata?.exchanged_amount : Transactiondata?.to_symbol + Transactiondata?.exchange_amount}
                                                                            </h5> : <h5 className='text-24 font-600 text-red-600'>
                                                                                {Transactiondata?.exchanged_amount ? Transactiondata?.exchanged_amount : Transactiondata?.to_symbol + Transactiondata?.exchange_amount}
                                                                            </h5>
                                                        }
                                                        {/* <h5 className='text-theme text-24 font-600'>
                                                            {Transactiondata?.exchanged_amount}
                                                        </h5> */}
                                                    </div>
                                                </li>
                                                <li className='border-b border-dark-100/20 py-3.5 last:border-0'>
                                                    <div className="">
                                                        <h6 className='text-14 font-400 dark:text-dark-300 text-dark-500 mb-2'>
                                                        {t('dateTime')}
                                                        </h6>
                                                        <h5 className='text-16 font-500 text-dark-950 dark:text-white'>
                                                            {moment(Transactiondata?.createdAt).format("DD MMM hh:mm:A")}
                                                        </h5>
                                                    </div>
                                                </li>
                                                <li className='border-b border-dark-100/20 py-3.5 last:border-0'>
                                                    <div className="">
                                                        <h6 className='text-14 font-400 dark:text-dark-300 text-dark-500 mb-2'>
                                                            {t('transactionType')}
                                                        </h6>
                                                        <h5 className='text-16 font-500 text-dark-950 dark:text-white'>
                                                        {t('label.labelExchange')}
                                                        </h5>
                                                    </div>
                                                </li>
                                                <li className='border-b border-dark-100/20 py-3.5 last:border-0'>
                                                    <div className="">
                                                        <h6 className='text-14 font-400 dark:text-dark-300 text-dark-500 mb-2'>
                                                        {t('label.labelStatus')}
                                                        </h6>
                                                        {/* <h5 className='text-16 font-50 dark:text-theme'>
                                                            {Transactiondata?.status === 0 ?
                                                                <StatusBadge text={Transactiondata?.status_name} status={"pending"} /> :
                                                                Transactiondata?.status === 1 ?
                                                                    <StatusBadge text={Transactiondata?.status_name} status={"success"} /> :
                                                                    Transactiondata?.status === 2 ?
                                                                        <StatusBadge text={Transactiondata?.status_name} status={"fail"} /> :
                                                                        Transactiondata?.status === 3 ?
                                                                            <StatusBadge text={Transactiondata?.status_name} status={"admin-pending"} /> :
                                                                            <StatusBadge text={Transactiondata?.status_name} status={"reject"} />}
                                                        </h5> */}
                                                        {
                                                            Transactiondata?.status === 0 ?
                                                                <h5 className='text-16 font-50' style={{ color: '#FB8B24' }}>
                                                                    {Transactiondata?.status_name}
                                                                </h5> : Transactiondata?.status === 1 ?
                                                                    <h5 className='text-theme text-16 font-50'>
                                                                        {Transactiondata?.status_name}
                                                                    </h5> : Transactiondata?.status === 2 ?
                                                                        <h5 className='text-16 font-50' style={{ color: '#B2A59B' }}>
                                                                            {Transactiondata?.status_name}
                                                                        </h5> : Transactiondata?.status === 3 ?
                                                                            <h5 className='text-16 font-50' style={{ color: '#FF8C00' }}>
                                                                                {Transactiondata?.status_name}
                                                                            </h5> : <h5 className='text-16 font-50 text-red-600'>
                                                                                {Transactiondata?.status_name}
                                                                            </h5>
                                                        }
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="">
                                            {/* <Button onClick={sendEmail} className="md:min-w-[150px] md:ms-auto" variant="outline" >
                                                Send Email
                                            </Button> */}
                                            <Button onClick={pdfDownload} disabled={loading} className="mx-auto" >
                                            {t('btn.btnDownload')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default withTranslation()(ExchangeTransactionsHistoryModal)
