import { cva } from 'class-variance-authority'
import React, { forwardRef } from 'react'
import { cn } from '../../lib/utils'

const buttonVariance = cva(
    "rounded-sm text-center flex items-center justify-center gap-sm disabled:opacity-50 disabled:cursor-not-allowed",
    {
        variants: {
            variant: {
                fill: 'bg-theme text-16 text-dark-950 font-600 border border-[transparent]',
                outline: 'bg-transparent text-16  text-dark-950 dark:text-white font-600 border border-[#d2d2d2]',
                copy: 'bg-transparent text-dark-950 dark:text-white border border-dark-300 dark:border-dark-800 rounded-lg',
                danger: 'bg-red-500 text-16 text-white font-600 border border-[transparent]',
            },
            size: {
                default: 'py-[14px] px-[28px]',
                sm: 'py-[12px] px-[24px]',
                xs: 'py-[8px] px-[12px]',
            }
        },
        defaultVariants: {
            size: "default",
            variant: 'fill'
        }
    },
)

const Button = forwardRef(({ children, variant, size, className, disabled, ...props }, ref) => {
    return (
        <button className={cn(buttonVariance({ variant, size, className }))} {...props} ref={ref} disabled={disabled}>
            {children}
        </button>
    )
})

export { Button, buttonVariance }