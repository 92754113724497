import React from 'react'

const IconWithdraw1 = (props) => {
    return (
        <svg {...props} viewBox="0 0 42 42" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M20.2667 16.5481C20.6717 16.953 21.3283 16.953 21.7333 16.5481L25.1901 13.0913C25.5951 12.6863 25.5951 12.0297 25.1901 11.6247C24.7851 11.2197 24.1285 11.2197 23.7235 11.6247L22.037 13.3111V3.71599C22.037 3.14325 21.5727 2.67896 21 2.67896C20.4273 2.67896 19.963 3.14325 19.963 3.71599V13.3111L18.2765 11.6247C17.8715 11.2197 17.2149 11.2197 16.8099 11.6247C16.4049 12.0297 16.4049 12.6863 16.8099 13.0913L20.2667 16.5481Z" fill="#0DF69E" />
            <path fillRule="evenodd" clipRule="evenodd" d="M30.7104 21.6914L30.7136 21.6914L38.2494 21.6914C38.8221 21.6914 39.2864 22.1557 39.2864 22.7284C39.2864 23.3012 38.8221 23.7655 38.2494 23.7655H30.7157C30.5884 23.7661 30.4637 23.8018 30.3554 23.8687C30.2471 23.9356 30.1595 24.0311 30.102 24.1446L30.1014 24.1457L28.0818 28.185C27.7097 28.9332 27.1359 29.564 26.4257 30.0044C25.7152 30.445 24.8958 30.6786 24.0598 30.6791H17.958C17.122 30.6786 16.3021 30.445 15.5916 30.0044C14.8815 29.564 14.3082 28.9345 13.9362 28.1864L13.9355 28.185L11.9144 24.1428C11.7963 23.9067 11.5679 23.7655 11.3037 23.7655H3.68148C3.10874 23.7655 2.64444 23.3012 2.64444 22.7284C2.64444 22.1557 3.10874 21.6914 3.68148 21.6914H11.3037C12.3531 21.6914 13.3 22.2761 13.7695 23.2153L15.7929 27.262C15.9932 27.6652 16.302 28.0044 16.6846 28.2417C17.0672 28.4789 17.5084 28.6048 17.9586 28.605H24.0587C24.5089 28.6048 24.9501 28.4789 25.3326 28.2417C25.7152 28.0044 26.0241 27.6652 26.2244 27.262L26.2255 27.2597L28.2478 23.2153L28.2492 23.2124C28.479 22.7563 28.8306 22.3728 29.265 22.1044C29.6995 21.8359 30.1998 21.693 30.7104 21.6914Z" fill="currentcolor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.3841 8.97533C13.4673 9.54198 13.0754 10.0688 12.5087 10.1521C9.63088 10.5748 7.76603 11.6011 6.58347 13.2395C5.37349 14.9158 4.75309 17.4013 4.75309 21.0001V26.1853C4.75309 30.404 5.60318 33.0873 7.25799 34.7421C8.9128 36.3969 11.5961 37.247 15.8148 37.247H26.1852C30.4039 37.247 33.0872 36.3969 34.742 34.7421C36.3968 33.0873 37.2469 30.404 37.2469 26.1853V21.0001C37.2469 17.4013 36.6265 14.9158 35.4165 13.2395C34.234 11.6011 32.3691 10.5748 29.4913 10.1521C28.9246 10.0688 28.5327 9.54198 28.616 8.97533C28.6992 8.40867 29.226 8.01678 29.7927 8.10001C33.0334 8.57604 35.4895 9.79667 37.0983 12.0256C38.6797 14.2165 39.321 17.2187 39.321 21.0001V26.1853C39.321 30.6086 38.4427 33.9746 36.2086 36.2087C33.9745 38.4428 30.6085 39.3211 26.1852 39.3211H15.8148C11.3915 39.3211 8.02548 38.4428 5.7914 36.2087C3.55732 33.9746 2.67902 30.6086 2.67902 26.1853V21.0001C2.67902 17.2187 3.32034 14.2165 4.90172 12.0256C6.51052 9.79667 8.96665 8.57604 12.2073 8.10001C12.774 8.01678 13.3008 8.40867 13.3841 8.97533Z" fill="currentcolor" />
        </svg>
    )
}

export default IconWithdraw1