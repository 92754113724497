import React from 'react'
import Table from './Table'
import TBody from './TBody'
import TR from './TR'
import TD from './TD'
import { withTranslation } from 'react-i18next';

const ExchangeDetailsConfirmationTextTable = ({ transactionFeeForShow,t }) => {
    return (
        <Table className={"border-spacing-y-[20px]"}>
            <TBody>
                <TR>
                    <TD className={"p-0 last:pr-0 last:text-end"}>
                        <span className='text-14 font-400 text-dark-500 dark:text-dark-300'>Payment Amount</span>
                    </TD>
                    <TD className={"p-0 last:pr-0 last:text-end"}>
                        <span className='text-dark-950 dark:text-white text-14 font-500'>{transactionFeeForShow?.pay_amount_currency}{transactionFeeForShow?.payment_amount?.toFixed(2) ?? 0.00}</span>
                    </TD>
                </TR>
                <TR>
                    <TD className={"p-0 last:pr-0 last:text-end"}>
                        <span className='text-14 font-400 text-dark-500 dark:text-dark-300'>{t('label.labelTransactionFee')}</span>
                    </TD>
                    <TD className={"p-0 last:pr-0 last:text-end"}>
                        <span className='text-dark-950 dark:text-white text-14 font-500'>{transactionFeeForShow?.currency_code}{transactionFeeForShow?.fee?.toFixed(2) ?? 0.00}</span>
                    </TD>
                </TR>
                <TR>
                    <TD className={"p-0 last:pr-0 last:text-end"}>
                        <span className='text-14 font-400 text-dark-500 dark:text-dark-300'>Currency Exchange Amount</span>
                    </TD>
                    <TD className={"p-0 last:pr-0 last:text-end"}>
                        <span className=' text-theme text-14 font-500'>{transactionFeeForShow?.currency_code}{transactionFeeForShow?.totalAmount?.toFixed(2) ?? 0.00}</span>
                    </TD>
                </TR>
            </TBody>
        </Table>
    )
}

export default withTranslation()(ExchangeDetailsConfirmationTextTable)
