import React, { lazy, Suspense, useEffect } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
// import Login from "../pages/dash/authentication/Login";
// import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// import SendOtpPage from "../pages/dash/authentication/SendOtpPage";
// import Register from "../pages/dash/authentication/Register";
// import VerifyRegisterOtp from "../pages/dash/authentication/VerifyRegisterOtp";
// import useStore from "../contexts/AuthProvider";
import Loader from "../components/common/Loader";
import AuthMaster from "../layouts/auth/AuthMaster";
import Notification from "../pages/dash/Notification";
import GoogleAuth from "../pages/dash/Security/GoogleAuth";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import SendModal from "../components/dash/model/sendModalflow/SendModal";
import { useState } from "react";

const DashMaster = lazy(() => import("../layouts/dashboard/DashMaster"));
const DashBoard = lazy(() => import("../pages/dash/DashBoard"));
const Transaction = lazy(() => import("../pages/dash/Transaction"));
const ExchangeTransaction = lazy(() => import("../pages/dash/ExchangeTransaction"));
const Card = lazy(() => import("../pages/dash/Card"));
const PaymentLink = lazy(() => import("../pages/dash/PaymentLink"));
const SettingMaster = lazy(() =>
  import("../pages/dash/settinglayout/SettingMaster")
);
const MyProfile = lazy(() => import("../pages/dash/MyProfile"));
const KycVerification = lazy(() => import("../pages/dash/KycVerification"));
const ChangePassword = lazy(() => import("../pages/dash/ChangePassword"));
const Support = lazy(() => import("../pages/dash/Support"));
const TicketDetails = lazy(() => import("../pages/dash/TicketDetails"));
const Login = lazy(() => import("../pages/auth/Login"));
const Register = lazy(() => import('../pages/auth/Register'));
const TermsAndCondition = lazy(() => import('../pages/TermsAndCondition'));
const ForgotPassword = lazy(() => import('../pages/auth/ForgotPassword'));
const ForgotPassVerification = lazy(() => import('../pages/auth/ForgotPassVerification'));
const NewPassword = lazy(() => import('../pages/auth/NewPassword'));
const FrontMaster = lazy(() => import("../layouts/front/FrontMaster"));
const LandingPage = lazy(() => import("../components/front/LandingPage"));
const AboutUs = lazy(() => import("../components/front/AboutUs"));
const HelpandSupport = lazy(() => import("../components/front/HelpandSupport"));
const OppipayforBusiness = lazy(() =>
  import("../components/front/OppipayforBusiness")
);
const Oppipayforchatpay = lazy(() =>
  import("../components/front/Oppipayforchatpay")
);
const OppipayformoneyTransfer = lazy(() =>
  import("../components/front/OppipayformoneyTransfer")
);

export default function Router() {
  // const userData = useStore((state) => console.log({ state }));
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [sendModalOpen, setSendModalOpen] = useState(false);
  useEffect(() => {
    if (pathname === "/") {
      navigate("/");
    }
  }, [pathname]);
  useEffect(() => {
    const targetPath = "/dashboard/forword-payment-links";
    if (pathname.startsWith(targetPath)) {
      setSendModalOpen(true);
    }
  }, [pathname]);

  const element = useRoutes([
    {
      path: "/",
      element: <AuthMaster />,
      children: [
        // { index: true, element: <DashBoard /> },
        { path: "login", element: <Login />, index: true },
        { path: "register", element: <Register /> },
        { path: "verify-otp", element: <ForgotPassVerification /> },
        { path: "get-otp-forgot", element: <ForgotPassword /> },
        { path: "reset-password", element: <NewPassword /> },
        
      ],
    },
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <DashMaster />
        </AuthGuard>
      ),
      children: [
        { index: true, element: <DashBoard /> },
        { path: "transaction", element: <Transaction /> },
        { path: "exchange", element: <ExchangeTransaction /> },
        { path: "card", element: <Card /> },
        { path: "payment-link", element: <PaymentLink /> },
        { path: "notification", element: <Notification /> },
        { path: "forword-payment-links/:slug", element: <SendModal sendModalOpen={sendModalOpen} setSendModalOpen={setSendModalOpen}/> },
        {
          path: "setting",
          element: <SettingMaster />,
          children: [
            { index: true, element: <MyProfile /> },
            { path: "kyc-verification", element: <KycVerification /> },
            { path: "change-password", element: <ChangePassword /> },
            { path: "2f-authentication", element: <GoogleAuth /> },
            { path: "support", element: <Support /> },
            { path: "support/ticket-detail", element: <TicketDetails /> },
          ],
        },
      ],
    },
    {
      path: "/",
      element: <FrontMaster />,
      children: [
        { index: true, element: <LandingPage /> },
        { path: "about", element: <AboutUs /> },
        { path: "help", element: <HelpandSupport /> },
        { path: "forbusiness", element: <OppipayforBusiness /> },
        { path: "chatpay", element: <Oppipayforchatpay /> },
        { path: "moneytransfer", element: <OppipayformoneyTransfer /> },
      ],
    },
    { path: "terms-and-conditions", element: <TermsAndCondition /> },
    { path: "privacy-policy", element: <PrivacyPolicy /> }
  ]);

  return <Suspense fallback={<Loader />}>{element}</Suspense>;
}
