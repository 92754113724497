import './App.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'react-phone-input-2/lib/style.css'
import Router from './routes';
import { SnackbarProvider } from "notistack";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <I18nextProvider i18n={i18n}>
        <Router />
      </I18nextProvider>
      <ToastContainer />
    </SnackbarProvider>
  );
}

export default App;
