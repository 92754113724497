import React from 'react'
import Table from './Table'
import TBody from './TBody'
import TR from './TR'
import TD from './TD'
import { withTranslation } from 'react-i18next';

const WithdrawConfirmationTable = ({ data,t }) => {
    return (
        <Table className={"border-spacing-y-[20px]"}>
            <TBody>
                <TR>
                    <TD className={"p-0 last:pr-0 last:text-end"}>
                        <span className='text-14 font-400 text-dark-500 dark:text-dark-300'>{t('label.labelBankName')}</span>
                    </TD>
                    <TD className={"p-0 last:pr-0 last:text-end"}>
                        <span className='text-dark-950 dark:text-white text-14 font-500'>{data?.bankDetail?.bank_name}</span>
                    </TD>
                </TR>
                <TR>
                    <TD className={"p-0 last:pr-0 last:text-end"}>
                        <span className='text-14 font-400 text-dark-500 dark:text-dark-300'>{t('bankAccountName')}</span>
                    </TD>
                    <TD className={"p-0 last:pr-0 last:text-end"}>
                        <span className='text-dark-950 dark:text-white text-14 font-500'>{data?.bankDetail?.bank_account}</span>
                    </TD>
                </TR>
                <TR>
                    <TD className={"p-0 last:pr-0 last:text-end"}>
                        <span className='text-14 font-400 text-dark-500 dark:text-dark-300'>{t('label.labelIbanNumber')}</span>
                    </TD>
                    <TD className={"p-0 last:pr-0 last:text-end"}>
                        <span className='text-dark-950 dark:text-white text-14 font-500'>{data?.bankDetail?.iban}</span>
                    </TD>
                </TR>
                <TR>
                    <TD className={"p-0 last:pr-0 last:text-end"}>
                        <span className='text-14 font-400 text-dark-500 dark:text-dark-300'>{t('label.labelWithdrawAmount')}</span>
                    </TD>
                    <TD className={"p-0 last:pr-0 last:text-end"}>
                        <span className='text-dark-950 dark:text-white text-14 font-500'>{data?.currency}{data?.receive_amount?.toFixed(2) ?? 0.00}</span>
                    </TD>
                </TR>
                {
                    data?.fee !== 0 &&
                    <>
                        <TR>
                            <TD className={"p-0 last:pr-0 last:text-end"}>
                                <span className='text-14 font-400 text-dark-500 dark:text-dark-300'>{t('label.labelAdminFees')}</span>
                            </TD>
                            <TD className={"p-0 last:pr-0 last:text-end"}>
                                <span className='text-dark-950 dark:text-white text-14 font-500'>{data?.currency}{data?.fee?.toFixed(2) ?? 0.00}</span>
                            </TD>
                        </TR>
                        <TR>
                            <TD className={"p-0 last:pr-0 last:text-end"}>
                                <span className='text-14 font-400 text-dark-500 dark:text-dark-300'>{t('label.labelTotalPayable')}</span>
                            </TD>
                            <TD className={"p-0 last:pr-0 last:text-end"}>
                                <span className='text-dark-950 dark:text-white text-14 font-500'>{data?.currency}{data?.totalAmount?.toFixed(2) ?? 0.00}</span>
                            </TD>
                        </TR>
                    </>
                }
            </TBody>
        </Table>
    )
}

export default withTranslation()(WithdrawConfirmationTable)