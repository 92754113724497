import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "../../../ui/Button";
import ExchangeDetailsConfirmationTextTable from "../../ExchangeDetailsConfirmationTextTable";
import DataLoader from "../../../common/DataLoader";
import Confirm from "../../../../assets/images/icn_confirmation.svg";
import SendAmountConfirmationTable from "../../SendAmountConfirmationTable";
import WithdrawConfirmationTable from "../../WithdrawConfirmationTable";
import DepositConfirmationTable from "../../DepositConfirmationTable";
import { withTranslation } from 'react-i18next';


const ConfirmationModal = ({ confirmationModalOpen, setConfirmationModalOpen, handelSubmitEvent, transactionDetail, setLoading2, type, noteData, setlfname, lfname, userInfo,t }) => {

  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (lfname?.fname && lfname?.lname) {
      setDisableButton(false)
    } else {
      if (type === "Deposit") {
        setDisableButton(true)
      }
    }
  }, [lfname])

  return (
    <Transition appear show={confirmationModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => { return; }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform rounded-sm sm:rounded-md bg-white dark:bg-dark-800  shadow-xl transition-all overflow-hidden">
                <div className="dialog-content">
                  <DataLoader isLoading={setLoading2} />
                  <div className="dialog-body py-6 px-5 md:px-[30px] md:py-6">
                    <div className="logout-content">
                      <div className="icon mb-4">
                        <div className='icon'>
                          {/* <img src={SuccessGif} alt="" className='max-w-[200px] mx-auto' /> */}
                          <img src={Confirm} alt="" className="max-w-[140px] md:max-w-[200px] mx-auto" />
                        </div>
                      </div>
                      <h5 className='mb-4 after:text-20 font-600 text-dark-950 dark:text-white text-center'>
                        {
                          type === "CurrencyExchange" && "Are you sure want to exchange currency ?"
                        }
                        {
                          type === "SendCurrency" && t('confirm.labelAreYouSureWantToSendAmount')
                        }
                        {
                          type === "withdraw" && t('confirm.labelAreYouSureWantToWithdrawAmount')
                        }
                        {
                          type === "Deposit" && t('confirm.labelAreYouSureWantToDepositAmount')
                        }
                        {
                          type === "Deposit-Delete" && "Are you sure want to delete card ?"
                        }
                        {
                          type === "CreateLink" && "Are you sure want to create payment link ?"
                        }
                        {
                          type === "deleteBank" && t('confirm.areYouSureWantToDeleteBank')
                        }
                      </h5>
                      {
                        type === "CurrencyExchange" && <ExchangeDetailsConfirmationTextTable transactionFeeForShow={transactionDetail} />
                      }
                      {
                        type === "SendCurrency" && <SendAmountConfirmationTable transactionFeeForShow={transactionDetail} userInfo={userInfo} />
                      }
                      {
                        type === "withdraw" && <WithdrawConfirmationTable data={transactionDetail} />
                      }
                      {
                        type === "Deposit" && <DepositConfirmationTable data={transactionDetail} noteData={noteData} setlfname={setlfname} lfname={lfname} />
                      }
                      {/* <p className='text-center text-14 font-400 text-dark-500 dark:text-dark-300 mb-7'>Your Password has been Changed successfully.</p> */}
                      <div className="grid grid-cols-1 xsm:grid-cols-2 gap-sm">
                        <Button className="md:min-w-[150px] md:ms-auto text-sm" disabled={disableButton} onClick={() => handelSubmitEvent()}>
                          {t('label.labelConfirm')}
                        </Button>
                        <Button
                          onClick={() => setConfirmationModalOpen(false)}
                          className="md:min-w-[150px] md:me-auto bg-red-500 text-white text-sm"
                        >
                          {t('label.labelCancel')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default withTranslation()(ConfirmationModal);
