import React, { useEffect, useState } from "react";
import DownloadApp from "./DownloadApp";
import ScanQr from "./ScanQr";
import BackupKey from "./BackupKey";
import { AUTH_API_URL } from "../../../api/Path";
import Http from "../../../api/Http";
import { useLocation } from "react-router";
import DataLoader from "../../../components/common/DataLoader";
import { withTranslation } from 'react-i18next';

const GoogleAuth = ({t}) => {

  const location = useLocation();
  const faAuthStatus = location?.state?.faAuths;
  const [step, setStep] = useState(1);
  const [secret, setSecret] = useState();
  const [imageData, setImgQrData] = useState();
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false)
  // const searchParams = new URLSearchParams(location.search);
  // const faAuth = searchParams.get('faAuth');

  async function getScanQr() {
    try {
      const response = await Http.get(AUTH_API_URL.getScanQr);
      const base64Data = response?.data?.data_url?.split(",")[1];
      setImgQrData(base64Data);
      setSecret(response?.data?.secret)
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log({ err })
    }
  }

  useEffect(() => {
    if (step === 2 && flag === true) {
      setLoading(true);
      getScanQr();
    }
    if (step === 1 || step === 2) {
      setFlag(true)
    } else {
      setFlag(false)
    }
  }, [step])

  return (
    <div className="bg-card min-h-full mx-auto flex flex-col p-12 relative">
      <DataLoader isLoading={loading} />
      <div className="step flex sm:w-[80%] w-[100%] max-w-[500px] flex-col mx-auto relative">
        <div className="flex justify-between relative z-[4]">
          <div className="number bg-theme w-[34px] aspect-square flex justify-center items-center rounded-full text-14">
            1
          </div>
          <div
            className={`number ${step >= 2
              ? "bg-theme"
              : "bg-dark-500 text-white"
              } w-[34px] aspect-square flex justify-center items-center rounded-full text-14`}
          >
            2
          </div>
          <div
            className={`number ${step >= 3
              ? "bg-theme"
              : "bg-dark-500 text-white"
              } w-[34px] aspect-square flex justify-center items-center rounded-full text-14`}
          >
            3
          </div>
          <div
            className={`number ${step >= 4
              ? "bg-theme"
              : "bg-dark-500 text-white"
              } w-[34px] aspect-square flex justify-center items-center rounded-full text-14`}
          >
            4
          </div>
          <div
            className={`step-line absolute w-full h-[2px] border border-dashed border-dark-200 -z-[2] top-[50%] bg-black`}
          ></div>
          <div
            className={`step-line absolute ${step == 2
              ? "w-[36%]"
              : step == 3
                ? "w-[65%]"
                : step == 3
                  ? "w-80%"
                  : "w-0"
              } h-[2px] border border-dashed border-theme -z-[1] top-[50%] bg-black`}
          ></div>
        </div>
        <div className="sm:flex justify-between absolute w-[calc(100%+50px)] -left-[28px] top-[30px] z-[4] mt-6 hidden">
          <div className="text-14 dark:font-400 font-500 dark:text-white text-dark-950 text-center">
            {t('downloadApp')}
          </div>
          <div className="text-14 dark:font-400 font-500 dark:text-white text-dark-950 text-center"
          /*   className={`text-14 font-400 ${step >= 2 ? "dark:text-white text-dark-950" : "text-dark-200"
              } text-center`} */
          >
            {t('scanQrCode')}
          </div>
          <div className="text-14 dark:font-400 font-500 dark:text-white text-dark-950 text-center"
          /*  className={`text-14 font-400 ${step >= 3 ? "dark:text-white text-dark-950" : "text-dark-200"
             } text-center`} */
          >
            {t('backupKey')}
          </div>
          <div className="text-14 dark:font-400 font-500 dark:text-white text-dark-950 text-center"
          /*  className={`text-14 font-400 ${step >= 4 ? "dark:text-white text-dark-950" : "text-dark-200"
             } text-center`} */
          >
            {t('enabledGoogleAuthenticator')}
          </div>
        </div>
      </div>
      {step == 1 ? (
        <DownloadApp setStep={setStep} />
      ) : step == 2 ? (
        <ScanQr setStep={setStep} secret={secret} imageData={imageData} />
      ) : (
        <BackupKey setStep={setStep} secret={secret} faAuthStatus={faAuthStatus} />
      )}
    </div>
  );
};

export default withTranslation()(GoogleAuth);
