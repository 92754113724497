import React from 'react'
import { cn } from '../../lib/utils'

const TextArea = ({ className, lable, errorMsg, errorClass, lableClass, placeholder, ...props }) => {
  return (
    <div className="form-group flex flex-col">
      {lable && <label className={cn('text-dark-950 dark:text-dark-300 text-14 mb-2', lableClass)}>{lable}</label>}
      <textarea {...props} className={cn("dark:bg-dark-600 bg-transparent border border-dark-300 dark:border-0 rounded-sm   dark:text-white  outline-none p-4 text-dark-500  w-full dark:placeholder-dark-500 text-14 font-500 bg-transparent" , className)} placeholder={placeholder} rows={"3"}>
      </textarea>
      {errorMsg && <p className={cn('text-12 text-red-500 font-normal', errorClass)}>{errorMsg}</p>}
    </div>
  )
}

export default TextArea