import React from 'react'

const IconETH = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_436_12542)">
                <path d="M19.6767 13.6548C19.6448 13.6528 12.4294 17.9288 12.3348 17.9653C12.3135 17.953 5.03464 13.6451 5 13.6494C5.01768 13.6753 12.3299 23.9996 12.3348 23.9996C12.3398 23.9996 19.6767 13.6548 19.6767 13.6548Z" fill="currentcolor" />
                <path d="M12.3385 0L5.02393 12.2334L12.3385 16.5661L19.653 12.2334L12.3385 0Z" fill="currentcolor" />
            </g>
            <defs>
                <clipPath id="clip0_436_12542">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IconETH
