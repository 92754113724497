import React from 'react'
import { cn } from '../../lib/utils'
import Img from "../../assets/images/avatar.png";

const UserAvatar = ({ className, img, text, IconClass, TextClass, type }) => {
    // console.log({img}) // image show remaining
    return (
        <div className={cn('flex items-center justify-start gap-sm', className)}>
            <div className={cn("avatar-img w-[40px] h-[40px] rounded-full overflow-hidden", IconClass)}>
                <img src={type === 'logo' ? img : process.env.REACT_APP_PROFILE_URL + 'user/' + img} alt="" className='w-full h-full object-cover object-center' />
            </div>
            {
                text ? <h6 className={cn('text-dark-950 dark:text-white text-16 font-600', TextClass)}>{text}</h6> : null
            }
        </div>
    )
}

export default UserAvatar