import React, { forwardRef, useState } from 'react'
import { cn } from '../../lib/utils'
import IconEye from '../svg/IconEye';
import IconEyeClose from '../svg/IconEyeClose';

const Input = forwardRef(({ name, className, lableClass, errorClass, lable, icon, errorMsg, placeholder, suffix, prefix, type, suffixClass, maxlength, disabled, ...props }, ref) => {
    const [togglePassword, setTogglePassword] = useState(false);

    return (
        <div className="form-group flex flex-col">
            {lable && <label className={cn('text-dark-950 dark:text-dark-300 text-14 mb-2', lableClass)}>{lable}</label>}
            <div className="relative">
                {suffix && <span className={cn('absolute right-[18px] top-[13px]', suffixClass)}>{suffix}</span> || type === "password" && <span className='toogle-pass-btn absolute right-[18px] top-[15px] cursor-pointer' onClick={() => setTogglePassword(!togglePassword)}>{togglePassword ? <IconEye className="w-5 h-5 text-dark-500 dark:text-white" /> : <IconEyeClose className="w-5 h-5 text-dark-500 dark:text-white" />}</span>}
                <input maxLength={maxlength} disabled={disabled} autoComplete='autoComplete' ref={ref} name={name} type={`${type === "password" ? !togglePassword ? "password" : "text" : type ? type : "text"}`} className={cn(`text-dark-500 dark:text-white  w-full dark:placeholder-dark-500 text-14 font-500 bg-light dark:bg-dark-600 ${suffix ? "!pe-[48px] ps-[14px]" : "pe-[14px] ps-[14px]"} ${prefix ? "!ps-[48px] pe-[14px]" : "ps-[14px] pe-[14px]"} py-[14px]  rounded-sm outline-0`, className)} placeholder={placeholder} {...props} />
                {prefix && <span className='absolute left-[18px] top-[13px]'>{prefix}</span>}
            </div>
            {errorMsg && <p className={cn('!text-12 text-red-500 font-normal mt-1', errorClass)}>{errorMsg}</p>}
        </div>
    )
})

export default Input