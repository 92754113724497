import { useEffect } from "react";
import { create } from "zustand";
import createContext from "zustand/context";
import { devtools, persist } from "zustand/middleware";
import { AUTH_API_URL } from "../api/Path";
import Http from "../api/Http";

const { Provider, useStore } = createContext();

const middleware_ = (f) => create(devtools(persist(f, { name: "auth-storage" })));
// const middleware_ = (f) => create(devtools(f, { name: "auth-storage" }));

const authStore = middleware_((set, get) => ({
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    email_auth: false,
    name: null,
    method: "jwt",
    accessToken: "",
    role: "",
    locationData: [],
    permissions: "",
    isRefreshing: false,
    setInitialized: () => set({ isInitialized: true }),
    login: async (loginObj) => {
        try {
            const response = await Http.post(AUTH_API_URL.login, loginObj);
            const res = response?.data;

            if (res !== undefined && (res.is_2fa_enable === false && res.is_email_enable === false && res.is_mobile_enable === false)) {
                set({
                    isAuthenticated: true,
                    accessToken: response?.data?.accessToken,
                    user_data: response?.data?.user_data,
                });
                return [response?.data, null];
            } else {
                if (response.status === 200) {
                    return [res, null];
                }
                if (response.response.status === 422) {
                    return [null, response.response.data.message];
                }
            }
        } catch (err) {
            if (err.response?.data?.message) {
                return [null, err.response?.data?.message];
            }
            if (!err?.response) {
                return [null, "No Server Response"];
            } else if (err.response?.status === 400) {
                return [null, "Missing user or Password"];
            } else if (err.response?.status === 409) {
                return [null, "Unauthorized"];
            } else {
                return [null, "Login Failed"];
            }
        }
    },
    loginOtpverify: async (loginOtpObj) => {
        try {
            const response = await Http.post(AUTH_API_URL.loginOtpverify, loginOtpObj);

            if (response) {
                if (response.data.accessToken) {
                    set({
                        isAuthenticated: true,
                        accessToken: response?.data?.accessToken,
                        user_data: response?.data?.user_data,
                        verify_flag: response?.data?.verify_flag,
                        set2FA: true
                    });
                    return [response?.data, null];
                } else {
                    return [response?.data, null];
                }
            }
        } catch (err) {
            if (err.response?.data?.message) {
                return [null, err.response?.data?.message];
            }
            if (!err?.response) {
                return [null, "No Server Response"];
            } else if (err.response?.status === 400) {
                return [null, "Missing user or Password"];
            } else if (err.response?.status === 409) {
                return [null, "Unauthorized"];
            } else if (err.response?.status === 422) {
                return [null, err.response];
            } else {
                return [null, "Login Failed"];
            }
        }
    },
    updateVerifyFlag: (data) => {
        const newdata = {
            is_2fa_enable: data?.is_2fa_enable,
            is_email_enable: data?.is_email_enable,
            is_mobile_enable: data?.is_mobile_enable,
            enable_count: data?.enable_count
        }
        set({
            verify_flag: newdata,
        })
    },
    change2FA: async () => {
        try {
            set({
                set2FA: false
            });
        } catch (err) {
            console.log({ err })
        }
    },
    updateEmailAuth: (enable) => set(() => ({ email_auth: enable })),
    register: async (signUpObj) => {
        try {
            const response = await Http.post(AUTH_API_URL.register, signUpObj
                // {
                //     headers: { "Content-Type": "application/json", "env": "test", "device-type": 3 },
                //     // withCredentials: true,
                // }
            );
            // console.log({ response })
            return [response.data, null];
        } catch (err) {
            return [null, err.response.data.errors];
        }
    },
    signupVerifyOTP: async (otpObj) => {
        try {
            const response = await Http.post(AUTH_API_URL.verifyOtp, otpObj,
                {
                    headers: { "authorization": `Bearer ${otpObj.token}` }
                });

            if (response) {
                set({
                    isAuthenticated: true,
                    accessToken: response?.data?.accessToken,
                    user_data: response?.data?.user_data,
                    verify_flag: response?.data?.verify_flag,
                });
            }
            return [response.data, null];
        } catch (err) {
            return [null, err.response.data.message];
        }
    },
    twoFactorAuthentication: async (otpObject) => {
        try {
            const response = await Http.post(AUTH_API_URL.twoFactorVerification,
                JSON.stringify(otpObject), {
                headers: { "Content-Type": "application/json", "env": "test", "device-type": 3 },
                withCredentials: true,
            });
            // console.log(response);
            set({
                user: response?.data?.user,
                name: response?.data?.name,
                isVerify: true,
                email_auth: response?.data?.user?.email_auth,
                accessToken: response?.data?.accessToken,
                role: response?.data?.role,
                isAuthenticated: true,
                permissions: response?.data?.permission
            });
            return [response.data, null];
        } catch (err) {
            return [null, err.response.data.message];
        }
    },
    resendOTP: async (resendOtpObj) => {
        try {
            const response = await Http.post(AUTH_API_URL.resendOTP, resendOtpObj,
                {
                    headers: { "authorization": `Bearer ${resendOtpObj.token}` },
                });
            return response;
        } catch (err) {
            return [null, err.response];
        }
    },
    resendTwoFactorOtp: async (resendOtpObject) => {
        try {
            const response = await Http.post(AUTH_API_URL.resendTwoFactorOtp,
                JSON.stringify(resendOtpObject), {
                headers: { "Content-Type": "application/json", "env": "test", "device-type": 3 },
                withCredentials: true,
            });
            console.log(response, 111)
            return [response.data.message, null];
        } catch (err) {
            console.log(err, 222)
            return [null, err.response];
        }
    },
    getOtp: async (getOtpObj) => {
        try {
            const response = await Http.post(AUTH_API_URL.getOtp,
                JSON.stringify(getOtpObj), {
                headers: { "Content-Type": "application/json", "env": "test", "device-type": 3 },
                withCredentials: true,
            });
            return [response.data.message, null];
        } catch (err) {
            return [null, err.response.data];
        }
    },
    verifyOtp: async (otpObj) => {
        try {
            const response = await Http.post(AUTH_API_URL.forgotVerifyOtp,
                JSON.stringify(otpObj), {
                headers: { "Content-Type": "application/json", "env": "test", "device-type": 3 },
                withCredentials: true,
            });
            return [response.data, null];
        } catch (err) {
            return [null, err.response.data];
        }
    },
    forgotPassword: async (forgotPasswordObj) => {
        try {
            const response = await Http.post(AUTH_API_URL.forgotPassword,
                JSON.stringify(forgotPasswordObj), {
                headers: { "Content-Type": "application/json", "env": "test", "device-type": 3 },
                withCredentials: true,
            });
            return [response.data, null];
        } catch (err) {
            return [null, err.response.data];
        }
    },
    verifyOTPForgot: async (otpObj) => {
        try {
            otpObj.type = "forget_password"
            const response = await Http.post(AUTH_API_URL.verifyOTPForgot, otpObj);
            return [response.data, null];
            // return response;
        } catch (err) {
            return [null, err.response.data];
        }
    },
    logout: () => {
        set({
            user_data: "",
            verify_flag: "",
            accessToken: "",
            role: "",
            locationData: "",
            isAuthenticated: false,
            permissions: ""
        });
    },
    refreshToken: async () => {
        try {
            const response = await Http.get(AUTH_API_URL.refreshToken, { withCredentials: true })
            set({ accessToken: response?.data?.accessToken });
            return response?.data?.accessToken;
        } catch (error) {
            await get().logout()
        }
    },
    updateUserProfile: (data) => {
        const newdata = {
            _id: data?._id,
            api_key: data?.api_key,
            country_code: data?.country_code,
            default_currency: data?.default_currency,
            email: data?.email,
            mobile: data?.mobile,
            fName: data?.fName,
            lName: data?.lName,
            profile: data?.profile,
            secret: data?.secret,
            username: data?.username,
            is_2fa_enable: data?.is_2fa_enable,
            is_email_enable: data?.is_email_enable,
            is_mobile_enable: data?.is_mobile_enable,
            enable_count: data?.enable_count,
            is_kyc_verify: data?.is_kyc_verify
        }
        set({
            user_data: newdata,
        })
    },
    businessId: (data) => {
        set({
            businessId: data,
        })
    },
    locationStoreData: async (data) => {
        set({
            locationData: data,
        })
    },
    socialLogin: async (data) => {
        try {
            const response = await Http.post(AUTH_API_URL.socialLogin, data);
            const res = response?.data;
            if (res !== undefined && (res.is_2fa_enable === true || res.is_email_enable === true || res.is_mobile_enable === true)) {
                set({
                    isAuthenticated: true,
                    accessToken: response?.data?.accessToken,
                    user_data: response?.data?.user_data,
                    verify_flag: response?.data?.verify_flag,
                });
                return [response?.data, null];
            } else {
                if (response?.response?.status === 422) {
                    return [null, response.response.data.message];
                } else {
                    set({
                        isAuthenticated: true,
                        accessToken: response?.data?.accessToken,
                        user_data: response?.data?.user_data,
                        verify_flag: response?.data?.verify_flag,
                    });
                }
                return [response?.data, null];
            }
        } catch (err) {
            return [null, err.response.data];
        }
    }
}));


const AuthProvider = ({ children }) => {
    const setInitialized = authStore(state => state.setInitialized)
    useEffect(() => {
        setInitialized()
    }, []);
    return <Provider createStore={() => authStore}>{children}</Provider>;
};

const refreshStore = create((set, get) => ({
    isRefreshing: false,
}))

export { AuthProvider, authStore, refreshStore };
export default useStore;
