import React from 'react'

const IconExchange = (props) => {
    return (
        <svg {...props} viewBox="0 0 29 28" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.23416 3.73416C5.74216 2.22616 8.01426 1.6333 11 1.6333H18C20.9857 1.6333 23.2578 2.22616 24.7658 3.73416C26.2738 5.24216 26.8666 7.51426 26.8666 10.5V17.5C26.8666 20.4857 26.2738 22.7578 24.7658 24.2658C23.2578 25.7738 20.9857 26.3666 18 26.3666H11C8.01426 26.3666 5.74216 25.7738 4.23416 24.2658C2.72616 22.7578 2.1333 20.4857 2.1333 17.5V10.5C2.1333 7.51426 2.72616 5.24216 4.23416 3.73416ZM5.22411 4.72411C4.10711 5.8411 3.5333 7.65234 3.5333 10.5V17.5C3.5333 20.3476 4.10711 22.1588 5.22411 23.2758C6.3411 24.3928 8.15234 24.9666 11 24.9666H18C20.8476 24.9666 22.6588 24.3928 23.7758 23.2758C24.8928 22.1588 25.4666 20.3476 25.4666 17.5V10.5C25.4666 7.65234 24.8928 5.8411 23.7758 4.72411C22.6588 3.60711 20.8476 3.0333 18 3.0333H11C8.15234 3.0333 6.3411 3.60711 5.22411 4.72411Z" fill="currentcolor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M14.5 8.2833C11.3182 8.2833 8.7833 10.8382 8.7833 14C8.7833 14.3866 8.4699 14.7 8.0833 14.7C7.6967 14.7 7.3833 14.3866 7.3833 14C7.3833 10.0684 10.5417 6.8833 14.5 6.8833C16.8487 6.8833 18.6062 7.86548 19.7626 8.83097C19.9254 8.96693 20.0768 9.10287 20.2166 9.23614V7.94497C20.2166 7.55837 20.53 7.24497 20.9166 7.24497C21.3032 7.24497 21.6166 7.55837 21.6166 7.94497V11.1533C21.6166 11.5399 21.3032 11.8533 20.9166 11.8533H18.07C17.6834 11.8533 17.37 11.5399 17.37 11.1533C17.37 10.7667 17.6834 10.4533 18.07 10.4533H19.457C19.285 10.2772 19.0878 10.0914 18.8653 9.90563C17.8837 9.08612 16.4329 8.2833 14.5 8.2833ZM20.9166 13.3C21.3032 13.3 21.6166 13.6134 21.6166 14C21.6166 17.9332 18.4332 21.1166 14.5 21.1166C12.4835 21.1166 10.8962 20.1026 9.8469 19.1499C9.72291 19.0373 9.60555 18.9248 9.49497 18.8139V20.055C9.49497 20.4416 9.18157 20.755 8.79497 20.755C8.40837 20.755 8.09497 20.4416 8.09497 20.055V16.8466C8.09497 16.46 8.40837 16.1466 8.79497 16.1466H11.6883C12.0749 16.1466 12.3883 16.46 12.3883 16.8466C12.3883 17.2332 12.0749 17.5466 11.6883 17.5466H10.2204C10.3868 17.7288 10.5764 17.9213 10.788 18.1134C11.7046 18.9457 12.9697 19.7166 14.5 19.7166C17.66 19.7166 20.2166 17.16 20.2166 14C20.2166 13.6134 20.53 13.3 20.9166 13.3Z" fill="#0DF69E" />
        </svg>
    )
}

export default IconExchange