import React from 'react'

const Loader = () => {
  return (
    <div className='w-screen h-screen fixed top-0 left-0 bg-light dark:bg-dark-950/20 flex items-center justify-center z-50'>
         <span className='block w-[48px] h-[48px] animate-spin rounded-full border-4 border-dark-950 dark:border-theme border-l-0 border-b-0'>
               
         </span>
    </div>
  )
}

export default Loader