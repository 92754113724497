import React, { Fragment, useState } from "react";
import { cn } from "../../lib/utils";
import { Listbox, Transition } from "@headlessui/react";
import IconDown from "../svg/IconDown";
import AllCurrancyIcon from "../svg/AllCurrancyIcon";
import AutosizeInput from "react-input-autosize";
import { withTranslation } from 'react-i18next';

const CurrencyAmountDetailBox = ({
  className,
  currency,
  selected,
  setSelected,
  balance,
  disabled,
  flag,
  value,
  onChange,
  t,
  ...props
}) => {
  // const [value, setValue] = useState(0);

  return (
    <div
      className={cn(
        "py-3 ps-3 pr-6 md:py-5 md:ps-5 md:pr-10 bg-light dark:bg-dark-600 rounded-sm flex items-center justify-between",
        className
      )}
    >
      <div className="balance-details flex-grow w-full">
        <Listbox disabled={disabled} onChange={(e) => setSelected(e)}>
          <div className="relative">
            <Listbox.Button className="relative flex items-center gap-2 justify-between">
              <span className="block truncate text-14 md:text-16  font-500 text-dark-850 dark:text-white">
                {selected?.code}
              </span>
              {currency?.length > 1 && (
                <span className="pointer-events-none">
                  <IconDown className="w-5 h-5 text-dark-850 dark:text-white" />
                </span>
              )}
            </Listbox.Button>
            {currency?.length > 1 && (
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute mt-2 z-20  px-4 py-[10px] bg-white dark:bg-dark-800 rounded-sm cursor-pointer">
                  {currency?.map((currency, index) => (
                    <Listbox.Option
                      key={index}
                      value={currency}
                      className={"py-[6px] cursor-pointer"}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate text-14 font-400 md:text-16  ${
                              selected?.code
                                ? "text-dark-950 dark:text-white font-700"
                                : "dark:text-dark-300 text-dark-600"
                            }`}
                          >
                            {currency.code}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            )}
          </div>
        </Listbox>
        {flag !== "createLink" && (
          <p className="text-14 text-dark-500 dark:text-dark-500 mt-2 md:mt-4 font-600">
            {" "}
            {t('label.labelBalance')}: {selected?.symbol}
            {balance && balance.toFixed(2)}
          </p>
        )}
      </div>
      <div className="flex items-center justify-end">
        <span>
          {
            <AllCurrancyIcon
              className="w-7 h-7 text-theme"
              code={selected?.code}
            />
          }
        </span>
        {/* <span><IconDollar className="w-7 h-7 text-theme " /></span> */}
        <AutosizeInput
          className="bg-transparent leading-[0.8] border rounded-[4px] [&>input]:outline-none border-none text-22 text-start font-500 text-theme"
          // disabled={disabled}
          type="number"
          inputStyle={{
            background: "transparent",
            outline: "none",
            overflow: "hidden",
            minWidth: "20px",
            maxWidth: "200px",
            textOverflow: "ellipsis",
          }}
          name="form-field-name"
          value={value}
          onChange={onChange}
        />
      </div>
      {/* <h4 className='transaction-value text-18 md:text-24 font-600 text-theme'>$ 100</h4> */}
    </div>
  );
};

export default withTranslation()(CurrencyAmountDetailBox);
