import React from 'react'

const DataLoader = ({ isLoading }) => {
    return (
        <>
            {
                isLoading ? (
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-black/20">
                        <span className='block w-[28px] h-[28px] animate-spin rounded-full border-[3px] border-dark-950 dark:border-theme border-l-0 border-b-0' />
                    </div>
                    )
               : null
            }
        </>
    )
}

export default DataLoader
